import React, { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";

import { getRemainingLifeSpan, getRidOfAliases } from "../../services/littleHelpers"
import ModalDialog from '../modals/ModalDialog';
import AddDepositForm from './AddDepositForm';

import useCachedDataWithUpdates from "../../services/useCachedDataWithUpdates"

const initialArrayDataObject = [];
const showOnlyActive = true;
const onViewSwitchFilterCondition = {
  "status": {
    "_eq": "Действителен"
  }
}

const DepositsShort = ( { siteSettings } ) => {
    const [addDepositVisible, setAddDepositVisible] = useState(false);
    const [activeDepositsData, setActiveDepositsData] = useState([]);
    const forceReloadRef = useRef(false); 
    
    useEffect(() => {
      forceReloadRef.current = !forceReloadRef.current;
    }, [addDepositVisible]);
    
    let conditionalStyleForAddDepositButton;
    
    if (activeDepositsData?.length < 1) {
        conditionalStyleForAddDepositButton = {"marginLeft": "auto"}
    }

    const [
        areDepositsLoading,
        depositsDataRef,
        justUsedDepositsRef, 
    ] = useCachedDataWithUpdates({
        location: "/accounting",
        dataSource: "deposits",
        updateFrequency: 8000,
        cachedDataLifeSpan: 180000,
        shallowQuery: true,
        initialShowOnlyActiveState: showOnlyActive,
        showOnlyActive: showOnlyActive,
        onViewSwitchFilterCondition,
        offViewSwitchFilterCondition: undefined,
        localFieldToCompareBy: "updated_at_full",
        remoteFieldToCompareBy: "updated_at",
        sortString: "-updated_at",
        altSortString: "-updated_at",
        setTableData: setActiveDepositsData,
        initialStateObject: initialArrayDataObject,
        loggingEnabled: !!siteSettings?.deposits_loggingEnabled,
        editedEntryID: addDepositVisible,
        forceReloadRef: forceReloadRef,
    });

    function getDepositLink(id) {
        const linkDest = {pathname: "/accounting/deposits"}
        linkDest.search = `?origin_ID=${id}`

        return linkDest;
    }

    const closeModal = () => {
        setAddDepositVisible(false);
    }
   
    return(
        <>
            {
                activeDepositsData?.length > 0 &&
                <div id="deposits-list-container">
                    <ul>
                    {
                        activeDepositsData.map((dep) => (
                            <Link 
                                key={dep.origin_ID} 
                                to={getDepositLink(dep.origin_ID)} 
                                className={"link-to-deposit"}
                                title={siteSettings.tooltips ? `Срок действия депозита истекает ${dep?.expiration_date}.` : ""}
                            >
                                <li>
                                    <p>
                                        {getRidOfAliases(dep.object?.area_name)}<br/>
                                        {dep.category}
                                    </p>
                                    <span>
                                        <strong>{dep.available}</strong>
                                        /{dep.limit?.toString()}
                                    </span>
                                        {
                                            !dep.live_long &&
                                            <div className='deposit-life-span-container' title={``}>
                                                <div className='deposit-life-span' style={{width: `${getRemainingLifeSpan(dep)}%`}}></div>
                                            </div>
                                        }
                                </li>
                            </Link>
                        ))
                    }

                    </ul>
                </div>
            }
            <div className='add-deposit-container' style={conditionalStyleForAddDepositButton}>
                <button 
                    className='button add-deposit' 
                    onClick={() => setAddDepositVisible(true)}
                >
                    <i>+</i>
                    <p>
                        Добавить депозит
                    </p>
                </button>
            </div>
            <div id="link-to-deposits-container">
                <Link to="/accounting/deposits" id={"link-to-deposits"} title={siteSettings?.tooltips ? `Смотреть историю депозитов` : ""}>
                    Все депозиты
                </Link>
            </div>
            <ModalDialog isOpen={addDepositVisible} exitFunction={closeModal}>
                <AddDepositForm />
            </ModalDialog>
        </>
    );
}

export default DepositsShort;