
export interface ErrorData {
  status?: number;
  statusText?: string;
  name?: string;
  message?: string;
}

export type ServiceErrorType = {
  message: string;
  code?: string | number;
  extensions?: {
    code: string;
    collection?: string;
    field?: string;
  };
};
export interface DirectusRequestError {
  errors: ServiceErrorType[];
  response: {
    ok: boolean;
    status: number;
    statusText: string;
    url: string;
    [key: string]: any;
  };
}

export function isDirectusError(error: Error | DirectusRequestError) {
  // @ts-expect-error Currently there is no type definition for Directus' SDK request error
  const isDirectusError = !!error?.errors?.[0]?.extensions?.code && !!error?.errors?.[0]?.message;
  
  // console.log("isDirectusError", isDirectusError, error);
  
  return isDirectusError;
}

export function handleDirectusError({ error, shouldThrow = false }: {
  shouldThrow: boolean;
  error: DirectusRequestError | Error;
}) {
  
  if (shouldThrow) {
    throw error;
  }
  
  if (error instanceof Error) {
    return [
      {
        code: error.name,
        message: error.message
      }
    ];
  }
  
  const serviceErrorCodes: ServiceErrorType[] = [];
  const errCode = error?.response?.status;
 
  
  error.errors.forEach(e => {
    let errorMessage = '';
    switch (e.extensions.code) {
      // Authentication
      case 'INVALID_CREDENTIALS':
        errorMessage = 'Invalid user credentials';
        break;
      case 'INVALID_TOKEN':
        errorMessage = 'Provided token is invalid';
        break;
      case 'INVALID_OTP':
        errorMessage = 'Provided one-time-password is invalid';
        break;
      case 'AUTH_FAILED':
        errorMessage = 'Authentication failed';
        break;
      case 'PASSWORD_RESET_FAILED':
        errorMessage = 'Password reset failed';
        break;

      // Items / Collections
      case 'FORBIDDEN':
        errorMessage = 'Access to this resource is forbidden';
        break;
      case 'INVALID_PAYLOAD':
        errorMessage = 'Invalid payload in request';
        break;
      case 'INVALID_QUERY':
        errorMessage = 'Invalid query parameters';
        break;
      case 'ITEM_NOT_FOUND':
        errorMessage = 'Requested item not found';
        break;
      case 'ITEM_LIMIT_REACHED':
        errorMessage = 'Item limit reached';
        break;
      case 'ROUTE_NOT_FOUND':
        errorMessage = 'API endpoint not found';
        break;

      // Files
      case 'CONTAINER_NOT_EMPTY':
        errorMessage = 'Folder must be empty to delete';
        break;
      case 'FILE_NOT_FOUND':
        errorMessage = 'File not found';
        break;
      case 'ILLEGAL_ASSET_TRANSFORMATION':
        errorMessage = 'Invalid asset transformation';
        break;
      case 'INVALID_FILE':
        errorMessage = 'Invalid file';
        break;
      case 'STORAGE_EXCEPTION':
        errorMessage = 'Storage exception occurred';
        break;
      case 'UPLOAD_FAILED':
        errorMessage = 'File upload failed';
        break;

      // Database/System
      case 'FIELD_NOT_FOUND':
        errorMessage = 'Field not found';
        break;
      case 'INVALID_FILTER':
        errorMessage = 'Invalid filter parameters';
        break;
      case 'CONTAINS_NULL_VALUES':
        errorMessage = 'Field contains null values';
        break;
      case 'RECORD_NOT_UNIQUE':
        errorMessage = 'Record must be unique';
        break;
      case 'INTERNAL_SERVER_ERROR':
        errorMessage = 'Internal server error occurred';
        break;
      case 'SERVICE_UNAVAILABLE':
        errorMessage = 'Service is currently unavailable';
        break;
      case 'INVALID_CONFIG':
        errorMessage = 'Invalid configuration';
        break;
      case 'FAILED_VALIDATION':
        errorMessage = 'Validation failed';
        break;
      case 'UNKNOWN':
        errorMessage = 'An unknown error occurred';
        break;

      // Rate Limiting
      case 'REQUESTS_EXCEEDED':
        errorMessage = 'Too many requests';
        break;

      // Hub
      case 'HUB_TOKEN_INVALID':
        errorMessage = 'Invalid Hub token';
        break;

      default:
        errorMessage = e.message || 'An unexpected error occurred';
    }

    // Add additional context from extensions if available
    if (e.message) {
      errorMessage += `: ${e.message}`;
    }
    if (e.extensions?.collection) {
      errorMessage += ` (Collection: ${e.extensions.collection})`;
    }
    if (e.extensions?.field) {
      errorMessage += ` (Field: ${e.extensions.field})`;
    }

    serviceErrorCodes.push(
      {
        message: errorMessage,
        code: errCode || e?.code || 500,
        extensions: e.extensions
      }
    )
  })


  return serviceErrorCodes;

}
