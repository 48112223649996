import React, { useRef, useEffect, useState, useMemo, useContext } from "react";
import { provideKeywords, Keyword } from "../../services/DataProviders/provideKeywords";
import { Destination } from "./AddToJournalForm";
import { Collections } from "../../models/DirectusSchema";
import { MainContext } from "../MainContext";

interface KeyWordContainerProps {
  destinations: Destination[];
}

const KeywordTypeIcon = ({ type, areaName }: { type: Collections.Keywords["type"]; areaName: string }) => {

  switch (type) {
    case "event":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={"1em"}
          height={"1em"}
          fill="currentColor"
          className="keyword-type-icon"
          viewBox="0 -960 960 960"
        >
          <title>Для участников событий [{areaName}]</title>
          <path d="m368-320 112-84 110 84-42-136 112-88H524l-44-136-44 136H300l110 88-42 136ZM160-160q-33 0-56.5-23.5T80-240v-135q0-11 7-19t18-10q24-8 39.5-29t15.5-47q0-26-15.5-47T105-556q-11-2-18-10t-7-19v-135q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v135q0 11-7 19t-18 10q-24 8-39.5 29T800-480q0 26 15.5 47t39.5 29q11 2 18 10t7 19v135q0 33-23.5 56.5T800-160H160Zm0-80h640v-102q-37-22-58.5-58.5T720-480q0-43 21.5-79.5T800-618v-102H160v102q37 22 58.5 58.5T240-480q0 43-21.5 79.5T160-342v102Zm320-240Z" />
        </svg>
      );
    case "unrestricted":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={"1em"}
          height={"1em"}
          fill="currentColor"
          className="keyword-type-icon"
          viewBox="0 -960 960 960"
        >
          <title>Для жителей и их окружения [{areaName}]</title>
          <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z" />
        </svg>
      );
    case "construction":
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={"1em"}
          height={"1em"}
          fill="currentColor"
          className="keyword-type-icon"
          viewBox="0 -960 960 960"
        >
          <title>Для участников стройки или реставрации [{areaName}]</title>
          <path d="M754-81q-8 0-15-2.5T726-92L522-296q-6-6-8.5-13t-2.5-15q0-8 2.5-15t8.5-13l85-85q6-6 13-8.5t15-2.5q8 0 15 2.5t13 8.5l204 204q6 6 8.5 13t2.5 15q0 8-2.5 15t-8.5 13l-85 85q-6 6-13 8.5T754-81Zm0-95 29-29-147-147-29 29 147 147ZM205-80q-8 0-15.5-3T176-92l-84-84q-6-6-9-13.5T80-205q0-8 3-15t9-13l212-212h85l34-34-165-165h-57L80-765l113-113 121 121v57l165 165 116-116-43-43 56-56H495l-28-28 142-142 28 28v113l56-56 142 142q17 17 26 38.5t9 45.5q0 24-9 46t-26 39l-85-85-56 56-42-42-207 207v84L233-92q-6 6-13 9t-15 3Zm0-96 170-170v-29h-29L176-205l29 29Zm0 0-29-29 15 14 14 15Zm549 0 29-29-29 29Z" />
        </svg>
      )
    default:
      return null;
  }
}

const KeyWordContainer: React.FC<KeyWordContainerProps> = ({ destinations }) => {
  const [relevantKeywords, setRelevantKeywords] = useState<Keyword[]>([]);
  const { sourceURL } = useContext(MainContext);
  const chosenDestinations = useRef<Destination[]>([]);
  const lastCalled = useRef<number>(0);
  const keywordsTimeout = useRef<NodeJS.Timeout | null>(null);
  const queuedDestinationsToQueryKeywords = useRef<Destination[]>([]);

  const keywordsHandler = useMemo(() => (destinations: Destination[], delay: number) => {
    const now = new Date().getTime();

    const rearrange = (allKeywords: Keyword[]): Keyword[] => {
      // console.log("Rearranging keywords:", allKeywords);
      // Remove duplicates
      const uniqueKeywords = allKeywords.filter((keyword, index, self) =>
        index === self.findIndex((k) => k.word === keyword.word && k.area_id === keyword.area_id)
      );

      // Sort keywords based on the order of chosen destinations
      uniqueKeywords.sort((a, b) => {
        const indexA = chosenDestinations.current.findIndex(dest => dest.value === a.area_id);
        const indexB = chosenDestinations.current.findIndex(dest => dest.value === b.area_id);
        return indexA - indexB;
      });

      // console.log("Rearranged keywords:", uniqueKeywords);
      return uniqueKeywords;
    }

    const queryAndUpdate = async (des: Destination[]) => {
      const fetchKeywordsData = async (des: Destination[]) => {
        // console.log("fetchKeywordsData runs to query destination:", des)
        const keywords = await provideKeywords({
          destinations: des,
          sourceURL,
        })
        // console.log("fetchKeywordsData got:", keywords)

        if (chosenDestinations.current !== undefined) {
          setRelevantKeywords(prevKeywords => {
            const newKeywords = [...prevKeywords, ...keywords];
            // console.log("New keywords state:", newKeywords);
            return rearrange(newKeywords);
          });
        }
      };

      if (des !== undefined) {
        fetchKeywordsData(des).catch(err => { console.log(err) })
      }

    }

    if (now - lastCalled.current < delay) {
      keywordsTimeout.current !== null && clearTimeout(keywordsTimeout.current);
      queuedDestinationsToQueryKeywords.current = queuedDestinationsToQueryKeywords.current.concat(destinations);
      keywordsTimeout.current = setTimeout(() => {
        keywordsTimeout.current = null;
        queryAndUpdate(queuedDestinationsToQueryKeywords.current)
        queuedDestinationsToQueryKeywords.current = [];
      }, delay);
      return;
    }

    lastCalled.current = now;
    clearTimeout(keywordsTimeout.current);
    queryAndUpdate(destinations);
  }, [])
  
  useEffect(() => {
    if (destinations?.length > 0 &&
      destinations?.length !== chosenDestinations.current?.length) {
      let newDestinations: Destination[] | undefined;

      if (chosenDestinations.current !== undefined) {

        if (destinations.length < chosenDestinations.current.length) {
          // in case of user deleting previously
          // chosen destinations we have to remove them from
          // @relevantKeywords state
          chosenDestinations.current = destinations;
          // console.log("relevantKeywords before checking KeywordsOnDesctinations", relevantKeywords)
          const reducedKeywords = checkKeywordsOnDestinations(relevantKeywords)
          setRelevantKeywords(reducedKeywords);
        } if (destinations.length > chosenDestinations.current.length) {
          // in case of user adding new desinations
          // to already chosen we have to filtrer out
          // destinations with known keywords
          newDestinations = destinations.filter(dest => !chosenDestinations.current.includes(dest))
        }
      } else {
        // in case of first added destination
        newDestinations = destinations
      }

      chosenDestinations.current = destinations;
      /**
       * Set destinations stored in @chosenDestinations reference array
       * to stay always relevant, not depending on react render cycle.
       * Last but not least, fetching keywords for new destinations
       * from backend, process them and set new @relevantKeywords state
       * inside @keywordsHandler function.
       */
      newDestinations !== undefined && keywordsHandler(newDestinations, 1000)

    } if (destinations?.length < 1) {
      /**
       * if all destinations removed, @relevantKeywords state and
       * @chosenDestinations ref set to initial values.
       */
      setRelevantKeywords([])
      chosenDestinations.current = undefined;
    }
  }, [destinations, keywordsHandler])

  const checkKeywordsOnDestinations = (keywords: Keyword[]): Keyword[] => {
    // function returns keywords only in case thier "area" property,
    // containing areas ID, has corresponding value in
    // chosenDestinations reference array.
    // console.log("checkKeywordsOnDestinations called with keywords: ", keywords)
    if (keywords.length === 0) return [];
    if (chosenDestinations.current.length === 0) return [];
    if (keywords.length === 1) return keywords;

    // console.log("&&& checkKeywordsOnDestinations returns:", reducedKeywords )

    // console.log("&&& inside checkKeywordsOnDestinations chosenDestinations.current:", chosenDestinations.current)
    const filteredKeywords = keywords.filter(word =>
      chosenDestinations.current.some(dest => dest.value === word.area_id)
    );
    // console.log("&& checkKeywordsOnDestinations returns: \n", filteredKeywords)
    return filteredKeywords;
  }



  return (
    <div id="keyWordContainer" className={`${relevantKeywords.length < 1 ? "" : "visible"}`} style={{ opacity: `${relevantKeywords.length < 1 ? 0.3 : 1}` }}>
      <span>Кодовые слова</span>
      <ul>
        {
          relevantKeywords.map((item, index) => (
            <li key={`${item.word}-${item.area_id}-${index}`}>
              <KeywordTypeIcon type={item.type} areaName={item.area_name} />
              {item.word}
            </li>
          ))
        }
      </ul>
    </div>
  );
}

export default KeyWordContainer;
