import { deleteItem } from "@directus/sdk";
import { MutableRefObject } from "react";
import { getDirectusClient } from "../DirectusSDKClient";
import { CollectionKeys } from "../DirectusServices/directusHelpers";

type deletingRowsRef = MutableRefObject<any>;
type justDeletedRowsRef = MutableRefObject<any>;
type tableDataRef = MutableRefObject<any>;

type DeleteRowWithTimeoutProps = {
  target: CollectionKeys;
  cell: any;
  deletingRowsRef: deletingRowsRef;
  justDeletedRowsRef: justDeletedRowsRef;
  tableDataRef: tableDataRef;
  setRowExists: (arg: boolean) => void;
  sourceURL: string;
}

export const deleteRowWithTimeout = async ({
  target = "deposits",
  cell,
  deletingRowsRef,
  justDeletedRowsRef,
  tableDataRef,
  setRowExists,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL,
}: DeleteRowWithTimeoutProps) => {

  const client = getDirectusClient(sourceURL);
  const buttonElement = document.getElementById(`${target}-delete-row-${cell.row.id}`);
  const row = cell.row;
  const origin_ID = row.values.origin_ID;

  const deleteRowForReal = async () => {
    await client.request(deleteItem(target, origin_ID)).catch((err) => {
      console.log("deleteItem call to targetEndpoint: " + target + ", targetRowID: " + origin_ID + " failed with error", err)
      window.alert("Удалить запись не удалось, сообщите администратору")
      throw err;
    });

    tableDataRef.current = tableDataRef.current.filter(el => {

      if (deletingRowsRef.current.includes(el.origin_ID)) {
        deletingRowsRef.current = deletingRowsRef.current.filter(e => e !== el.origin_ID)
        return false
      } else {
        return true
      }

    })

    justDeletedRowsRef.current.push(origin_ID);
    setRowExists(false)


  }

  if (!buttonElement.classList.contains("fallback")) {
    buttonElement.classList.add('fallback');
    deletingRowsRef.current.push(origin_ID);


    const timeout = window.setTimeout(() => {

      if (!buttonElement.classList.contains("fallback")) {
        clearTimeout(timeout);
        deletingRowsRef.current = deletingRowsRef.current.filter(e => {
          if (e !== origin_ID) {
            return true;
          } else {
            return false;
          }
        });

      } else {
        deleteRowForReal();
      }
    }, 3000)

    return null;

  } if (buttonElement.classList.contains("fallback")) {
    buttonElement.classList.remove('fallback');

    deletingRowsRef.current = deletingRowsRef.current.filter(e => {
      if (e !== origin_ID) {
        return true;
      } else {
        return false;
      }
    });

  }

}
