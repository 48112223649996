import React, { useState, useRef, useContext, useMemo, useEffect, useCallback } from 'react';
import { useSearchParams } from "react-router-dom";

import {
  useTable,
  usePagination,
  useRowSelect,
  useFilters,
  Column,
  TableInstance,
  TableState,
  Row,
  UseFiltersColumnProps,
  UseFiltersInstanceProps,
  UsePaginationInstanceProps,
  UseRowSelectInstanceProps,
  HeaderGroup,
  UseTableOptions,
} from 'react-table';

import { MainContext } from "../MainContext";
import usePersistentTableSettings from "../../services/usePersistentTableSettings"
import useCachedDataWithUpdates from "../../services/useCachedDataWithUpdates"
import TableRowErrorBoundary from './TableRowErrorBoundary';
import ColumnSettings from './ColumnSettings';
import RevealAllColumns from './RevealAllColumns';
import RefreshTable from './RefreshTable';
import Toggle from './Toggle';
import ColumnFilter from "./ColumnFilter";
import TableFakeFooter from './TableFakeFooter';

const PAGE_NO = 0;

const returnInitialHiddenColumns = ({ initialShowOnlyActiveState, initialHiddenColumns, idQuery }) => {

  if (!initialHiddenColumns["onlyActiveHidden"] || !initialHiddenColumns["allHidden"]) {
    return initialHiddenColumns;
  }

  if (initialShowOnlyActiveState) {
    return initialHiddenColumns["onlyActiveHidden"]
  } else if (idQuery) {
    return initialHiddenColumns["allHidden"].filter(c => c !== "origin_ID")
  } else {
    return initialHiddenColumns["allHidden"]
  }
}

const returnPageSize = ({ dataSettings, tablePrefix, showOnlyActive }) => {

  const meantInitialValue = showOnlyActive ? dataSettings.initialPageSize : dataSettings.altInitialPageSize;

  if (!dataSettings.syncRecordsPerPage) {
    return meantInitialValue
  }

  const rawStoredValue = window.localStorage.getItem(`${tablePrefix}pageSize-${showOnlyActive ? "showOnlyActive" : ""}`)

  if (rawStoredValue && rawStoredValue !== 'undefined') {
    const storedValue = JSON.parse(rawStoredValue);
    return storedValue
  } else {
    return meantInitialValue
  }
}

interface TableData {
  [key: string]: any;
}

export type ColumnWithFilter<T extends TableData> = Column<T> & UseFiltersColumnProps<T> & {
  canFilter?: boolean;
};

type HeaderGroupWithFilter<T extends object> = Omit<HeaderGroup<T>, 'headers'> & {
  headers: (HeaderGroup<T> & UseFiltersColumnProps<T> & {
    canFilter?: boolean;
    render: (type: string) => React.ReactNode;
  })[];
};

interface TableInstanceWithAllFeatures<T extends TableData> extends
  TableInstance<T>,
  UseFiltersInstanceProps<T>,
  UsePaginationInstanceProps<T>,
  UseRowSelectInstanceProps<T> {
  state: {
    filters: any[];
    pageIndex: number;
    pageSize: number;
    hiddenColumns: string[];
    selectedRowIds: Record<string, boolean>;
  };
  headerGroups: HeaderGroupWithFilter<T>[];
}

interface ExtendedTableState<T extends object> extends TableState<T> {
  filters: any[];
}

interface ExtendedUseTableOptions<T extends object> extends UseTableOptions<T> {
  initialState?: Partial<ExtendedTableState<T>>;
}

interface TableProps<T extends TableData> {
  initialHiddenColumns: {
    onlyActiveHidden: string[];
    allHidden: string[];
  };
  initialArrayDataObject: T[];
  initialShowOnlyActiveState: boolean;
  tablePrefix?: string,
  location?: string,
  columns: ColumnWithFilter<T>[];
  altColumns?: ColumnWithFilter<T>[];
  hatComponents?: React.ReactNode,
  returnMemoizedTableRowComponent: (props: {
    row: Row<TableData>;
    hiddenColumnsLength: number;
    tableDataRef: React.MutableRefObject<TableData[]>;
    deletingRowsRef: React.MutableRefObject<any>;
    justAlteredRowsRef: React.MutableRefObject<any>;
    setEditedEntryID: React.Dispatch<React.SetStateAction<boolean>>;
    showOnlyActive: boolean;
    selectedFlatRows: Row<TableData>[];
    previouslySelectedRowRef: React.MutableRefObject<any>;
  }) => JSX.Element;
  toggle: {
    enabled: boolean;
    showOnlyActiveLabel?: string;
    showAllLabel?: string;
    addClassName?: string;
    toggleContainerAddClassName?: string;
    mutateId?: boolean;
    isToggleDisabled?: boolean;
    onlyHat?: boolean;
    setId?: string;
    insertBefore?: React.ReactNode;
  },
  freezeRef?: React.MutableRefObject<any>;
  dataSettings: {
    dependency?: any;
    blockFetching?: boolean;
    initialPageSize?: number;
    altInitialPageSize?: number;
    updateFrequency: number;
    cachedDataLifeSpan: number;
    shallowQuery?: boolean;
    onViewSwitchFilterCondition: string | any;
    offViewSwitchFilterCondition: string | any;
    sortString: string;
    altSortString: string;
    localFieldToCompareBy: string;
    remoteFieldToCompareBy: string;
    keepTableSettings: boolean;
    syncRecordsPerPage: boolean;
  };
  tableFooter?: {
    allowedRezultsPerPageArray: Array<number>;
    minimalControls: boolean;
  },
  insertAfterTable?: (arg: any) => JSX.Element;
  noEntriesMessage?: string;
  idQuery?: string | number;
  forceReloadRef?: React.MutableRefObject<any>;
}

const Table = <T extends TableData>({
  initialHiddenColumns = { onlyActiveHidden: [], allHidden: [] },
  initialArrayDataObject = [],
  initialShowOnlyActiveState = true,
  tablePrefix = "journal",
  location = "/",
  columns = undefined,
  altColumns = columns,
  hatComponents = undefined,
  returnMemoizedTableRowComponent = undefined,
  toggle = {
    enabled: true,
    showOnlyActiveLabel: "Только активные",
    showAllLabel: "Все",
    addClassName: undefined,
    toggleContainerAddClassName: undefined,
    mutateId: false,
    isToggleDisabled: false,
    onlyHat: false,
    setId: "setShowOnlyActiveSwitch",
    insertBefore: undefined,
  },
  freezeRef = undefined,
  dataSettings = {
    dependency: undefined,
    blockFetching: false,
    initialPageSize: 10,
    altInitialPageSize: 10,
    updateFrequency: 5000,
    cachedDataLifeSpan: 60000,
    shallowQuery: false,
    onViewSwitchFilterCondition: "(is_active,eq,true)",
    offViewSwitchFilterCondition: undefined,
    sortString: "-updated_at",
    altSortString: "-updated_at",
    localFieldToCompareBy: "updated_at_full",
    remoteFieldToCompareBy: "updated_at",
    keepTableSettings: true,
    syncRecordsPerPage: true,
  },
  tableFooter = {
    allowedRezultsPerPageArray: [10, 20, 30, 50],
    minimalControls: false,
  },
  insertAfterTable = undefined,
  noEntriesMessage = "Нет подходящих записей",
  idQuery = undefined,
  forceReloadRef = undefined,
  // searchParams,
}: TableProps<T>) => {
  const { siteSettings, userData } = useContext(MainContext);
  const [showOnlyActive, setShowOnlyActive] = useState(initialShowOnlyActiveState);
  const [tableData, setTableData] = useState<T[]>(initialArrayDataObject as T[]);
  const [editedEntryID, setEditedEntryID] = useState(false);
  const [totalPage, setTotalPages] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(returnPageSize({ dataSettings, tablePrefix, showOnlyActive }));
  const tbodyRef = useRef<HTMLTableSectionElement>(null);
  const previousViewRef = useRef<boolean>(initialShowOnlyActiveState);
  const previouslySelectedRowRef = useRef<any>(null);
  const [searchParams] = useSearchParams();

  const defaultColumn = useMemo(
    () => ({
      Filter: ({ column }: { column: UseFiltersColumnProps<T> }) =>
        <ColumnFilter column={column} />,
    }),
    []
  ) as Partial<Column<T>>;

  const defaultFilters = useMemo(() => {
    const filters = [];

    for (const [key, value] of searchParams.entries()) {

      if (!!altColumns.find(el => el?.accessor === key)) {

        filters.push({
          id: `${key}`,
          value: value,
        })

      }

    }

    return filters;
  }, [searchParams]);

  const thisInitialHiddenColumns = returnInitialHiddenColumns({ initialShowOnlyActiveState, initialHiddenColumns, idQuery })
  
  const tableInstance = useTable<T>({
    columns: (showOnlyActive ? columns : altColumns) as readonly ColumnWithFilter<T>[],
    data: tableData as readonly T[],
    autoResetHiddenColumns: false,
    autoResetSelectedRows: true,
    defaultColumn,
    manualFilters: true,
    initialState: {
      filters: defaultFilters,
      hiddenColumns: thisInitialHiddenColumns,
      pageIndex: PAGE_NO,
      pageSize: recordsPerPage,
    } as Partial<ExtendedTableState<T>>,
    manualPagination: true,
    pageCount: totalPage,
  } as ExtendedUseTableOptions<T>,
    useFilters,
    usePagination,
    useRowSelect,
  ) as TableInstanceWithAllFeatures<T>

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    toggleHideColumn,
    setHiddenColumns,
    setFilter,
    setAllFilters,
    selectedFlatRows,
    toggleAllRowsSelected,
    state: { pageIndex, filters, hiddenColumns },
  } = tableInstance;

  const gotoPageCallback = useCallback((e) => {
    gotoPage(e)
  }, [])

  type UseCachedDataWithUpdatesReturn = [
    boolean, // isDataLoading
    React.MutableRefObject<TableData[]>, // tableDataRef
    React.MutableRefObject<any>, // justAlteredRowsRef
    React.MutableRefObject<number>, // totalEntries
    React.MutableRefObject<any>, // deletingRowsRef
    React.MutableRefObject<boolean>, // forceQueryRef
    boolean // isToggleDisabled
  ];

  const [
    isDataLoading,
    tableDataRef,
    justAlteredRowsRef,
    totalEntries,
    deletingRowsRef,
    forceQueryRef,
    isToggleDisabled,
  ] = useCachedDataWithUpdates({
    location,
    disabled: dataSettings.blockFetching,
    // freeze: selectedFlatRows?.length > 0 ? true : false,
    freeze: false,
    freezeRef,
    dataSource: tablePrefix,
    updateFrequency: dataSettings.updateFrequency,
    cachedDataLifeSpan: dataSettings.cachedDataLifeSpan,
    shallowQuery: dataSettings.shallowQuery,
    initialShowOnlyActiveState: initialShowOnlyActiveState,
    showOnlyActive,
    onViewSwitchFilterCondition: dataSettings.onViewSwitchFilterCondition,
    offViewSwitchFilterCondition: dataSettings.offViewSwitchFilterCondition,
    sortString: dataSettings.sortString,
    altSortString: dataSettings.altSortString,
    localFieldToCompareBy: dataSettings.localFieldToCompareBy,
    remoteFieldToCompareBy: dataSettings.remoteFieldToCompareBy,
    // nocodb_auth: userData.nocodb_auth,
    pageIndex,
    setTotalPages,
    gotoPage: gotoPageCallback,
    recordsPerPage,
    filters,
    setTableData,
    editedEntryID,
    forceReloadRef,
    hiddenColumns,
    loggingEnabled: !!siteSettings?.[`${tablePrefix}_loggingEnabled`],
    dependency: undefined // Add missing required property
  } as any) as UseCachedDataWithUpdatesReturn;

  // console.log('siteSettings?.[`${tablePrefix}_loggingEnabled`]', tablePrefix, siteSettings?.[`${tablePrefix}_loggingEnabled`])

  usePersistentTableSettings({
    keepTableSettings: dataSettings.keepTableSettings,
    syncRecordsPerPage: dataSettings.syncRecordsPerPage,
    tablePrefix: `${tablePrefix}`,
    previousViewRef,
    showOnlyActive,
    hiddenColumns,
    setHiddenColumns,
    recordsPerPage,
    setRecordsPerPage,
    initialHiddenColumns,
    // doLogging: true,
  })


  useEffect(() => {

    toggleAllRowsSelected(false)

    if (!dataSettings.syncRecordsPerPage) {
      setRecordsPerPage(
        returnPageSize({ dataSettings, tablePrefix, showOnlyActive })
      )
    }

    // dataSettings is recreated on every render, so thats why it's omitted from the dependency array
  }, [showOnlyActive]);

  useEffect(() => {

    for (const [key, value] of searchParams.entries()) {

      if (!!altColumns.find(el => el?.accessor === key)) {
        setFilter(key, value)
        toggleHideColumn(key, false)
      }

    }
  }, [searchParams]);

  const handleToggleActiveView = () => {
    previousViewRef.current = showOnlyActive;
    setShowOnlyActive(!showOnlyActive)
  }

  const rerenderTable = () => {
    setEditedEntryID(editedEntryID => !editedEntryID)
  }

  const handleProblems = () => {
    console.log("Table called %chandleProblems%c, something wrong happened on JounalRows rendering", "color:initital", "color:red")
    setHiddenColumns(thisInitialHiddenColumns);
    setTableData([])
    forceQueryRef.current = true;
    rerenderTable();
  }


  return (
    <div id={`${tablePrefix}-table-container`}>
      {hatComponents && hatComponents}
      {
        toggle.enabled &&
        <div
          id={`${toggle.mutateId ? tablePrefix + "-" : ""}toggle-container`}
          className={`${toggle.toggleContainerAddClassName ? " boxProperty" : ""}`}
        >
          {toggle.insertBefore}
          <Toggle
            addClassName={`${!hatComponents || toggle.onlyHat ? "only-hat" : ""}${toggle?.addClassName ? " " + toggle?.addClassName : ""}`}
            disabled={Boolean(isToggleDisabled)}
            checked={showOnlyActive}
            id={toggle.setId}
            changeFn={handleToggleActiveView}
            label={showOnlyActive ? toggle.showOnlyActiveLabel : toggle.showAllLabel}
          />
        </div>
      }
      <table id={`${tablePrefix}-table-entity`} key={`${tablePrefix}-table`} {...getTableProps()}>
        <thead key={`${tablePrefix}-thead`}>
          {
            headerGroups.map((headerGroup, i) => {
              const { key, ...headerGroupsProps } = headerGroup.getHeaderGroupProps();
              return (
                <tr key={`head-row-${i}-${key}`} {...headerGroupsProps}>
                  {
                    headerGroup.headers.map((column) => {
                      const { key, ...headerProps } = column.getHeaderProps();
                      return (
                        <th key={`header-${column.id}-${key}`} {...headerProps}>
                          <div className={`header-container-${column.id}`} key={`header-container-${column.id}`}>
                            {
                              column.canFilter ?
                                column.render("Filter")
                                :
                                column.id !== "selection" ?
                                  <div className='column-header'>{column.render('Header')}</div>
                                  :
                                  column.render('Header')
                            }
                          </div>
                          {
                            column.id !== "selection" &&
                            <ColumnSettings
                              column={column}
                              columns={columns}
                              hiddenColumns={hiddenColumns}
                              showTooltips={siteSettings.tooltips}
                              thisInitialHiddenColumns={thisInitialHiddenColumns}
                              setHiddenColumns={setHiddenColumns}
                            />
                          }
                        </th>
                      );
                    })
                  }
                  <th id="column-settings">
                    <RevealAllColumns
                      hiddenColumns={hiddenColumns}
                      thisInitialHiddenColumns={thisInitialHiddenColumns}
                      setHiddenColumns={setHiddenColumns}
                      showTooltips={siteSettings.tooltips}
                    />
                  </th>
                  {
                    filters.length > 0 &&
                    <th id="column-refresh">
                      <RefreshTable
                        filters={filters}
                        setAllFilters={setAllFilters}
                      />
                    </th>
                  }
                </tr>
              );
            })
          }
        </thead>
        <TableRowErrorBoundary
          hiddenColumns={hiddenColumns}
          columns={columns}
          debugData={rows}
          handleProblems={handleProblems}
          dataSource={tablePrefix}
        >
          {
            isDataLoading ?
              <tbody key={"tbody-loads"} {...getTableBodyProps()}>
                <tr key={"tbody-loads-tr"}>
                  <td
                    key={"tbody-loads-tr-td"}
                    className='not-cool-status-container'
                    colSpan={hiddenColumns?.length !== 0 ? columns.length - 1 : columns.length}
                  >
                    <div className='not-cool-status'>...загрузка</div>
                  </td>
                </tr>
              </tbody>
              :
              <tbody ref={tbodyRef} id={`${tablePrefix}-table-body`} key={`tbody---here`} {...getTableBodyProps()}>
                {
                  rows?.length === 0 ?
                    <tr>
                      <td className='not-cool-status-container' colSpan={hiddenColumns?.length !== 0 ? columns.length - 1 : columns.length}>
                        <div className='not-cool-status'>{noEntriesMessage}</div>
                      </td>
                    </tr>
                    :
                    rows.map(row => {

                      prepareRow(row);

                      return returnMemoizedTableRowComponent({
                        row: row as unknown as Row<TableData>,
                        hiddenColumnsLength: hiddenColumns?.length,
                        tableDataRef: tableDataRef as React.MutableRefObject<TableData[]>,
                        deletingRowsRef: deletingRowsRef as React.MutableRefObject<any>,
                        justAlteredRowsRef: justAlteredRowsRef as React.MutableRefObject<any>,
                        setEditedEntryID,
                        showOnlyActive: showOnlyActive,
                        selectedFlatRows: selectedFlatRows as unknown as Row<TableData>[],
                        previouslySelectedRowRef,
                      });

                    })
                }
              </tbody>
          }
        </TableRowErrorBoundary>
      </table>
      {
        totalPage > 1 &&
        <TableFakeFooter
          isLoading={Boolean(isDataLoading)}
          prefix={tablePrefix}
          tableDataRef={tableDataRef}
          totalEntries={totalEntries}
          page={page}
          pageIndex={pageIndex}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          totalPage={totalPage}
          recordsPerPage={recordsPerPage}
          minimalControls={tableFooter.minimalControls}
          setRecordsPerPage={!tableFooter.minimalControls ? setRecordsPerPage : undefined}
          allowedRezultsPerPageArray={!tableFooter.minimalControls ? tableFooter.allowedRezultsPerPageArray : undefined}
        />
      }
      {
        insertAfterTable ?
          insertAfterTable({
            isDataLoading,
            toggleAllRowsSelected,
            selectedFlatRows,
            justAlteredRowsRef,
          })
          :
          null
      }
    </div>
  );
}

export default Table;
