import React, { useState, useRef, useContext } from 'react';
import { matchSorter } from "match-sorter";
import Select, { createFilter } from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { handleDirectusError, isDirectusError } from "../../services/DirectusServices/directusErrors";
import { MainContext } from "../MainContext";
import { createDeposit } from '../../services/DataProviders/deposits';
import useSelectOptions from "../../services/useSelectOptions"
import LoadingIcon from "../commonUI/LoadingIcon";


const  categoryOptions = [
    { value: "Легковой", label: "Легковой" },
    { value: "До 3.5 тонн", label: "До 3.5 тонн" },
    { value: "Более 3.5 тонн", label: "Более 3.5 тонн" },
    { value: "Длинномеры > 8 м", label: "Длинномеры > 8 м" },
    { value: "8 кубов конт.", label: "8 кубов конт." },
    { value: "20 кубов конт.", label: "20 кубов конт." },
    { value: "27 кубов конт.", label: "27 кубов конт." },
    { value: "32 кубов конт.", label: "32 кубов конт." },
    { value: "Транзит стр. техники", label: "Транзит стр. техники" },
]

const reasonableFilterConfig = {
    ignoreCase: true,
    ignoreAccents: true,
    matchFrom: 'any',
    stringify: option => `${option.label}`,
    trim: true,
}

const AddDepositForm = () => {
    const { siteSettings, userData } = useContext(MainContext);
    const [formState, setFormState] = useState('readyForInput');
    // ! formState can be:
    // readyForInput
    // sendingData
    // success
    // errorOnSubmit
    const errorData = useRef();

    const [
        areOptionsLoading,
        allDestinationOptions,
        destinationOptions,
        setDestinationOptions,
    ] = useSelectOptions({
        siteSettings,
        optionName: "destinationOptions",
    })

    const {
        control,
        register,
        handleSubmit,
        reset,
        // getValues,
        // setValue,
        formState: { errors },
    } = useForm({
        criteriaMode: "all",
    });

    const onDestinationsInputChange = (value, {action}) => {

        if (action === 'input-change') {
            let filteredOptions = matchSorter(destinationOptions, value, { keys: ['label']});
            setDestinationOptions(filteredOptions);

            if (value === "" || value?.length === 0) {
                setDestinationOptions(allDestinationOptions.current);
            }

        } else if (action === 'menu-close') {
            setDestinationOptions(allDestinationOptions.current);   
        }

    }

    const onError = () => { 
        window.scrollTo(0, 0)
    }

    const saveFormData = async (data) => {
        window.scrollTo(0, 0);
        try {  
          await createDeposit({
            newDepositData: data,
          });
          let timeout_id = window.setTimeout(() => {
              setFormState("success")
              clearTimeout(timeout_id);
          }, 450)
          
        } catch (error) {
          if (isDirectusError(error)) {
            handleDirectusError({
              error,
              shouldThrow: true
            })
          }
          throw error;
        }

    }

    const onSubmit = async (data) => {

        try {
            setFormState("sendingData");
            await saveFormData(data);      
        } catch (e) {
            errorData.current = e;
            setFormState("errorOnSubmit");
        }

    }

    return (
        <div id="add-deposit-wrapper" className={`${formState === "sendingData" ? "loading" : ""}`}>
            <LoadingIcon addClassName={`${formState === "sendingData" ? "" : "invisible"}`} />
            {   formState === "success" &&
                <div id="deposit-success-container">
                    <p>Депозит успешно доставлен</p>
                    <button 
                        className='button repeat' 
                        onClick={() => {reset(); setFormState("readyForInput");} }>
                        Отправить ещё
                    </button>
                </div>
            }
            <form 
                onSubmit={handleSubmit(onSubmit, onError)} 
                id="add-deposit-form" 
                className={`${formState === "readyForInput" ? "visible" : ""}`} 
            >
                <div className="boxProperty">    
                    <div className="propertyContainer">
                            <label htmlFor={`destination`}>Пункт назначения</label> 
                            <Controller
                                control={control}
                                name={`dep_destination`}
                                defaultValue=""
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Select
                                        key={`destination`}
                                        id={`destination`}
                                        ref={ref}
                                        onChange={(e) => { onChange(e) } }
                                        onBlur={ (e) => { onBlur(e) } }
                                        options={destinationOptions}
                                        isLoading={areOptionsLoading}
                                        loadingMessage={() => {return "Идёт загрузка..."}}
                                        noOptionsMessage={() => {return "Нет опций"}}
                                        placeholder=""
                                        isSearchable={true}
                                        filterOption={createFilter(reasonableFilterConfig)}
                                        onInputChange={onDestinationsInputChange}
                                        value={value}
                                        classNamePrefix="select"
                                        defaultValue={undefined}
                                    />
                                )}
                                rules={{ required: "Выберите из списка" }}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`dep_destination`}
                                render={({message}) => <span className="errorMessage">{message}</span>}
                            />
                    </div>
                </div>
                <div className="boxProperty">
                    <div className="propertyContainer">
                        <label htmlFor={`dep_category`}>Категория транспорта</label> 
                        <Controller
                            control={control}
                            name={`dep_category`}
                            defaultValue=""
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <Select
                                    id={`dep_category`}
                                    ref={ref}
                                    onChange={(e) => { onChange(e); }}
                                    onBlur={onBlur}
                                    options={categoryOptions}
                                    noOptionsMessage={() => {return "Нет опций"}}
                                    filterOption={createFilter(reasonableFilterConfig)}
                                    placeholder=""
                                    isSearchable={true}
                                    value={value}
                                    classNamePrefix="select"
                                />
                            )}
                            rules={{ required: "Выберите из списка" }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name={`dep_category`}
                            render={({message}) => <span className="errorMessage">{message}</span>}
                        />
                    </div>
                </div>
                <div className="boxProperty">
                    <div className="propertyContainer">
                        <label htmlFor={`dep_comment`}>Комментарий для внимания охраны</label> 
                        <textarea
                            autoComplete="off"
                            className="classyInput"
                            id={`dep_comment`}
                            {...register(`dep_comment`)}
                        />
                    </div>
                </div>
                <div className="boxProperty">
                    <div className="propertyContainer">
                        <label htmlFor={`dep_limit`}>Сколько машин допустить</label> 
                        <Controller
                            control={control}
                            name={`dep_limit`}
                            defaultValue=""
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                <input
                                    ref={ref}
                                    onBlur={onBlur}
                                    onChange={(e) => { onChange(e); }}
                                    value={value}
                                    id={`dep_limit`}
                                    autoComplete="off"
                                    className="classyInput"
                                    type={"number"}
                                />
                            )}
                            rules={{ required: "Необходимо число" }}
                        />
                        <ErrorMessage
                            errors={errors}
                            name={`dep_limit`}
                            render={({message}) => <span className="errorMessage">{message}</span>}
                        />
                    </div>
                </div>
                <div id="submit-form-container" className={`${formState === "success" || formState === "readyForInput" ? "" : "invisible"}`}>
                    <button 
                        className="button submit" 
                        id="main-submit" 
                        type="submit"
                    >
                        Отправить
                    </button>
                </div>
            </form>
            <div id="error-on-submit-container" className={`${formState === "errorOnSubmit" ? "visible" : ""}`}>
                <h1>При отправке произошла ошибка</h1>
                <div className="appeal">
                    <p>Пожалуйста, свяжитесь с <a href="https://t.me/RomanSergeev" target="_blank" rel="noreferrer">@Романом</a> и сообщите ему информацию об ошибке:</p>
                    { errorData.current && 'status' in errorData.current && 'statusText' in errorData.current ?
                      <>
                        <p>Код ошибки: <code>{errorData.current?.status}</code></p>
                        <p>Текст ошибки: <code>{errorData.current?.statusText}</code></p>
                      </>
                      :
                      <>
                        <p>Текст ошибки: <code>{errorData.current?.name || 'Unknown'} | {errorData.current?.message || 'No message'}</code></p>
                      </>
                    } 
                    <button className="button retry" onClick={() => setFormState("readyForInput")}>Попробовать ещё раз</button>
                </div>
            </div>
        </div>

    );
}

export default AddDepositForm;