import { aggregate, createItem, QueryFields, QueryFilter, QuerySort, readItems } from "@directus/sdk";
import { Collections, Schema, Types } from "../../models/DirectusSchema";
import { formatDateTime } from "../dateTimeHelpers";
import { getDirectusClient } from "../DirectusSDKClient";
import { handleDirectusError, isDirectusError } from "../DirectusServices/directusErrors";
import { getDirectusFilterFromTableFilter } from "../DirectusServices/directusHelpers";
import { LandObject } from "./journal";


interface ProvideDepositsOptions {
  currentViewFilterCondition?: QueryFilter<Schema, Collections.Deposits>;
  pageNo?: number;
  recordsLimit?: number;
  filters?: Array<{ id: string, value: string | number | boolean }>;
  shallowQuery?: boolean;
  sortString?: Array<QuerySort<Schema, Collections.Deposits>>;
  controller?: AbortController;
  sourceURL?: string;
}

interface DepositDataShallow {
  origin_ID: number;
  expiration_date: string;
  object: LandObject;
  category: string;
  limit: number;
  available: number;
  status: any;
  live_long: boolean;
  life_span_in_days: number;
  expiration_date_utc_ts: number;
  comment: string;
  created_at: string;
  updated_at: string;
  updated_at_full: Types.Date;
}

interface DepositDataFull extends DepositDataShallow {
  entered_vehicles_count: number;
  entered_vehicles: any;
  last_related_update: string;
}

type DepositData<T extends boolean> = T extends true ? DepositDataShallow : DepositDataFull;

const shallowFields: QueryFields<Schema, Collections.Deposits> = [
  "id",
  "status",
  "limit",
  "life_span_in_days",
  { "lands_n_objects": ["area_name", "id", "status"] },
  "category",
  "comment",
  "created_at",
  "updated_at",
  "last_entered_UTC_date",
  "live_long",
  "journal"
]

const extendedFields = shallowFields.concat({
  "journal": [
    "number",
    "brands",
    "id",
    "entered_at",
    "entry_point"
  ]
});

export const provideDeposits = async<T extends boolean>({
  currentViewFilterCondition,
  pageNo = 0,
  recordsLimit = 100,
  filters = [],
  shallowQuery = true as T,
  sortString = ["-created_at"],
  controller = undefined,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: ProvideDepositsOptions) => {
  const client = getDirectusClient(sourceURL);
  const deposits: DepositData<T>[] = [];

  const filter = getDirectusFilterFromTableFilter<Collections.Deposits>({
    filters
  })

  if (currentViewFilterCondition) {
    filter._and.push(currentViewFilterCondition);
  }

  const totalCountRequest = await client.request(aggregate("deposits", {
    aggregate: {
      count: "*"
    },
    query: {
      filter: filter
    }
  }));

  const totalCount = (totalCountRequest?.[0]?.count && parseInt(totalCountRequest?.[0]?.count, 10)) || 0;

  const response = await client.request(readItems("deposits", {
    limit: recordsLimit,
    offset: pageNo * recordsLimit,
    sort: sortString,
    filter,
    fields: shallowQuery ? shallowFields : extendedFields,
    signal: controller.signal,
  }));

  response.forEach(rawData => {
    let expiration_date_utc_ts: number;

    if (typeof rawData.created_at === "string") {
      expiration_date_utc_ts = Date.parse(rawData.created_at) + rawData.life_span_in_days * 86400000;
    } else {
      expiration_date_utc_ts = rawData.created_at.getTime() + rawData.life_span_in_days * 86400000;
    }

    interface RawLandsNObjects {
      id: number;
      area_name: string;
      status: string[];
    }

    const object: LandObject = {
      Id: (rawData.lands_n_objects as RawLandsNObjects)?.id,
      area_name: (rawData.lands_n_objects as RawLandsNObjects)?.area_name,
      status: (rawData.lands_n_objects as RawLandsNObjects)?.status?.join(", ")
    };

    const updated_at = formatDateTime(rawData.updated_at);

    const baseDeposit: DepositDataShallow = {
      origin_ID: rawData.id,
      category: rawData.category,
      available: rawData.limit - rawData.journal?.length || 0,
      status: rawData.status,
      limit: rawData.limit,
      life_span_in_days: rawData.life_span_in_days,
      expiration_date_utc_ts,
      expiration_date: formatDateTime(new Date(expiration_date_utc_ts)),
      live_long: rawData.live_long,
      comment: rawData.comment,
      created_at: formatDateTime(rawData.created_at),
      updated_at,
      updated_at_full: rawData.updated_at,
      object
    };

    if (!shallowQuery) {
      const last_related_update = rawData.last_entered_UTC_date ? formatDateTime(rawData.last_entered_UTC_date) : updated_at;

      const fullDeposit: DepositDataFull = {
        ...baseDeposit,
        last_related_update: last_related_update,
        entered_vehicles_count: rawData.journal?.length || 0,
        entered_vehicles: rawData.journal,
      };

      deposits.push(fullDeposit);
    } else {
      deposits.push(baseDeposit as DepositData<T>);
    }
  })

  return { count: totalCount, data: deposits };
}


interface CreateDepositOptions {
  newDepositData: {
    dep_category: {
      value: string;
      label: string;
    };
    dep_comment: string;
    dep_destination: {
      value: number;
      label: string;
    };
    dep_limit: number;
  };
  sourceURL?: string;
}

export const createDeposit = async ({
  newDepositData,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL,
}: CreateDepositOptions) => {
  const client = getDirectusClient(sourceURL);
  console.log(newDepositData)
  try {
    return await client.request(createItem("deposits", {
      lands_n_objects: newDepositData.dep_destination.value,
      limit: newDepositData.dep_limit,
      comment: newDepositData.dep_comment,
      category: newDepositData.dep_category.value,
    }))
  } catch (error) {
    if (isDirectusError(error)) {
      handleDirectusError({
        error,
        shouldThrow: true
      })
    }
    throw error;
  }

  // let depositToCreate = {
  //   "nc_xcf___lands_n_objects_id": newDepositData["dep-destination"]?.value,
  //   "limit": Number.parseInt(newDepositData["dep-limit"]),
  //   "comment": newDepositData["dep-comment"],
  //   "category": newDepositData["dep-category"]?.value,
  // }

  // try {
  //   let response = await fetchWithTimeout(
  //     `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${depositsEndpoint}`,
  //     makeRequest({
  //       auth_token,
  //       method: "POST",
  //       body: depositToCreate
  //     })
  //   ).catch((err) => {
  //     logAbortError(err, `createDeposit`)
  //     throw err
  //   });

  //   // const responseBody = await responseStreamReader(response)
  //   // const responseBodyObject = await JSON.parse(responseBody);
  //   return response;

  // } catch (error) {
  //   return error;
  // }

}
