import React, { useRef, useState } from 'react';

import ModalDialog from '../../modals/ModalDialog';
import LoadingIcon from '../../commonUI/LoadingIcon';

import { executeBrandsMerge, FullBrandFormatted, FullBrandFormattedWithNumbers } from '../../../services/DataProviders/brands';
import { Row } from 'react-table';
import { ErrorData, handleDirectusError, isDirectusError, ServiceErrorType } from '../../../services/DirectusServices/directusErrors';


interface MergeBrandsProps {
  disabled?: boolean;
  selectedFlatRows: Row<FullBrandFormatted>[];
  toggleAllRowsSelected: (set?: boolean) => void;
  forceReloadRef: React.MutableRefObject<boolean>;
}

type MergingStatus = "idle" | "ok" | "fail";

const MergeBrands = ({
  disabled = true,
  selectedFlatRows,
  toggleAllRowsSelected,
  forceReloadRef
}: MergeBrandsProps) => {
  const [mergingStatus, setMergingStatus] = useState<MergingStatus>('idle');
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationContent, setConfirmationContent] = useState(<></>)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const errorData = useRef<ServiceErrorType[] | ErrorData | Error | null>(null);

  const handleClickOnConfirmation = async (
    { brandToDelete, brandToEnrich }:
      {
        brandToDelete: FullBrandFormattedWithNumbers; brandToEnrich: FullBrandFormattedWithNumbers;
      }
  ) => {
    setIsLoading(true);

    try {
      await executeBrandsMerge({
        brandToDelete,
        brandToEnrich
      })

      setIsLoading(false)
      setMergingStatus('ok')

      toggleAllRowsSelected(false);
      forceReloadRef.current = true;

      window.setTimeout(() => {
        setMergingStatus('idle')
        setIsModalOpen(false)
      }, 2681)

    } catch (error) {
      setMergingStatus('fail')
      setIsLoading(false)
      if (isDirectusError(error) || error instanceof Error) {
        errorData.current = handleDirectusError({
          error,
          shouldThrow: false
        })
      } else {
        errorData.current = error;
      }
    }
  }

  const confirmMerge = () => {
    // format inner brand data:
    const formatedSelectedRows: FullBrandFormattedWithNumbers[] = selectedFlatRows.reduce((prev, current) => {
      const blacklist_count = parseInt(current.values.blacklist_count, 10)
      const local_vehicles_count = parseInt(current.values.local_vehicles_count, 10)
      const journal_count = parseInt(current.values.journal_count, 10)
      
      if (Number.isNaN(journal_count) || Number.isNaN(local_vehicles_count) || Number.isNaN(blacklist_count)) {
        throw new Error("journal_count or local_vehicles_count or blacklist_count is NaN")
      }
      
      return [...prev, {
        brand: current.values.common_brand,
        origin_ID: current.values.origin_ID,
        blacklist_count,
        local_vehicles_count,
        journal_count,
        totalRelatedVehicles: blacklist_count + local_vehicles_count + journal_count,
      }]
    }, [])

    // sort by ascending totalRelatedVehicles:
    formatedSelectedRows.sort((a, b) => a.totalRelatedVehicles - b.totalRelatedVehicles);

    const brandToDelete = formatedSelectedRows[0]
    const brandToEnrich = formatedSelectedRows[1]

    setConfirmationContent(
      <div id={'brands-merge-confirmation'}>
        <h2>Требуется подтверждение</h2>
        <p>Марка «{brandToDelete.brand}» будет удалена,
          а связанные с ней транспорные средства
          ({brandToDelete.totalRelatedVehicles} ед.)
          будут прикреплены к «{brandToEnrich.brand}»</p>
        <button
          className='button'
          onClick={() => handleClickOnConfirmation({ brandToDelete, brandToEnrich })}
        >
          Да, объединяем
        </button>
      </div>
    )

    setIsModalOpen(true);

  }

  const closeModal = () => {
    setMergingStatus('idle');
    setIsLoading(false);
    setIsModalOpen(false);
  }

  return (
    <>
      <button
        className='button merge'
        disabled={disabled}
        onClick={confirmMerge}
      >
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" className="icon-merge" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx="7" cy="18" r="2" />
            <circle cx="7" cy="6" r="2" />
            <circle cx="17" cy="12" r="2" />
            <line x1="7" y1="8" x2="7" y2="16" />
            <path d="M7 8a4 4 0 0 0 4 4h4" />
          </svg>
        </i>
        Объединить
      </button>
      <ModalDialog
        isOpen={isModalOpen}
        exitFunction={closeModal}
      >
        {

          isLoading ?
            <LoadingIcon addClassName={""} addId={"merging-brands"} />
            :
            mergingStatus === "idle" ?
              confirmationContent
              :
              mergingStatus === "ok" ?
                <>
                  <h2>👍</h2>
                  <div className='boxProperty'>
                    <p><code>Марки успешно объединены</code></p>
                  </div>
                </>
                :
                mergingStatus === "fail" ?
                  <div className='error-response-container'>
                    <h2>Что-то пошло не так</h2>
                    <div className='boxProperty errCode'>
                    <p>Пожалуйста, свяжитесь с <a href="https://t.me/RomanSergeev" target="_blank" rel="noreferrer">@Романом</a> и сообщите ему информацию об ошибке:</p>
                    </div>
                    {
                      errorData.current &&
                        'status' in errorData.current &&
                        'statusText' in errorData.current ?
                        <div className='boxProperty errCode'>
                          <p>Код ошибки: <code>{errorData.current?.status}</code></p>
                          <p>Текст ошибки: <code>{errorData.current?.statusText}</code></p>
                        </div>
                        :
                        Array.isArray(errorData.current) &&
                          errorData.current[0]?.message ?
                          errorData.current.map(err => {
                            return (
                              <div className='boxProperty errCode'>
                                <p>Код ошибки: <code>{err.code}</code></p>
                                <p>Текст ошибки: <code>{err.message}</code></p>
                              </div>
                            )
                          })
                          :
                          !Array.isArray(errorData.current) && errorData.current?.name ?
                            <div className='boxProperty'>
                              <p>Текст ошибки: <code>{errorData.current?.name || 'Unknown'} | {errorData.current?.message || 'No message'}</code></p>
                            </div>
                            :
                            <div className='boxProperty'>
                              <p>Неизвестная ошибка</p>
                            </div>
                    }
                    <div className='boxProperty'>
                      <button
                        className='button'
                        onClick={closeModal}
                      >
                        Закрыть окно
                      </button>
                    </div>
                  </div>
                  :
                  null
        }
      </ModalDialog>
    </>
  );
}

export default MergeBrands
