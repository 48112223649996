import { QuerySort, readItems } from "@directus/sdk";
import { Schema } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";
import { CollectionKeys, getDirectusFilterFromTableFilter } from "./directusHelpers";
import { chooseEndpoint, StaleDataSourcesNames } from "../DataProviders/chooseEndpoint";


const logAbortError = (err, actionName = "") => {
  if (err.name === 'AbortError') {
    console.log(`%c${actionName} aborted`, "color:gray");
  } else {
    console.log(`%c${actionName} failed bacause of the following error:`, "color:gray");
    console.error(err)
  }
}

export type ConfirmDirectusDataWasUpdatedAgrs = {
  source: StaleDataSourcesNames;
  sortBy: Array<QuerySort<Schema, CollectionKeys>>; // Type is probably inaccurate, but I'll leave it like this for now
  fieldToCompare: string;
  lastKnownUpdateTimeString: string;
  knownTotalCourt?: number;
  filters?: Array<{ id: string, value: string }>;
  currentViewFilterCondition?: string;
  recordsLimit?: number;
  pageIndex?: number;
  controller: AbortController;
  timeout: number;
  sourceURL: string;
}



export const confirmDirectusDataWasUpdated = async ({
  source,
  // @ts-expect-error ignore
  sortBy = "-updated_at",
  // @ts-expect-error ignore
  fieldToCompareBy = "updated_at",
  lastKnownUpdateTimeString,
  // knownTotalCount,
  filters,
  currentViewFilterCondition,
  recordsLimit,
  pageIndex,
  controller,
  sourceURL
}: ConfirmDirectusDataWasUpdatedAgrs) => {

  const filter = getDirectusFilterFromTableFilter({
    filters
  })
  
  if (typeof currentViewFilterCondition === "string") {
    if (currentViewFilterCondition?.includes("archived,eq,false")) {
      filter["_and"].push({
        archived: {
          _eq: false
        }
      });
    }
  
    if (currentViewFilterCondition?.includes("is_active,eq,false")) {
      filter["_and"].push({
        is_active: {
          _eq: false
        }
      });
    } else if (currentViewFilterCondition?.includes("is_active,eq,true")) {
      filter["_and"].push({
        is_active: {
          _eq: true
        }
      });
    }
  } else if (currentViewFilterCondition) { 
    filter["_and"].push(currentViewFilterCondition);
  }


  const targetEndpoint = chooseEndpoint(source);
  const client = getDirectusClient(sourceURL);
  const response = await client.request(readItems(targetEndpoint, {
    limit: 3,
    offset: (recordsLimit && pageIndex) && (recordsLimit * pageIndex !== 0) ? recordsLimit * pageIndex : 0,
    // @ts-expect-error let it be errored, in case if incorrect field name was provided on funcion call
    sort: sortBy,
    filter,
    fields: [
      "id",
      // @ts-expect-error let it be errored, in case if incorrect field name was provided on funcion call
      `${fieldToCompareBy}`
    ],
    signal: controller?.signal,
  })).catch((err) => {
    logAbortError(err, `confirmDataWasUpdated call on ${source}`)
  });

  if (response) {
    try {
      const localForComparison = lastKnownUpdateTimeString;
      const remoteForComparison = response?.[0]?.[`${fieldToCompareBy}`];

      if (
        localForComparison === remoteForComparison
      ) {
        return {
          id: response?.[0]?.id,
          fresh: false
        }
      }

    } catch (err) {
      console.error(err)
      console.log("unrecognized error above got: response", response);
      return { fresh: false };
    }

  } else {
    return { fresh: true };
  }

  return { fresh: true };
}
