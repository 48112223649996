import { QuerySort, aggregate, readItems } from "@directus/sdk";
import { Collections, Schema, Types } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";
import { getDirectusFilterFromTableFilter, reduceDirectusFilesToStringArray } from "../DirectusServices/directusHelpers";
import { formatDateTime } from "../dateTimeHelpers";
import { CreatableSelectOption } from "../useSelectOptions";

type LandObject = {
  Id: number;
  area_name: string;
  status: string;
};

export type LocalVehicleRowData = {
  object?: LandObject,
  number: string,
  brand?: CreatableSelectOption<number>,
  comment: string,
  docs?: Array<Types.UUID>,
  photo?: Types.UUID,
  mark?: string,
  id_issue_2024?: string,
  updated_at: Types.DateTime,
  updated_at_full: Types.DateTime,
  created_at: Types.DateTime,
  archived: boolean,
  origin_ID: number,
}

export type ProvideLocalVehiclesOptions = {
  pageNo?: number,
  recordsLimit?: number,
  filters?: Array<{ id: string, value: string | number | boolean }>,
  currentViewFilterCondition?: string,
  sortString?: Array<QuerySort<Schema, Collections.LocalVehicles>>,
  controller?: AbortController,
  sourceURL: string
}

export async function provideLocalVehicles({
  pageNo = 0,
  recordsLimit = 50,
  filters = [],
  currentViewFilterCondition,
  sortString = ["-updated_at"],
  controller = undefined,
  sourceURL
}: ProvideLocalVehiclesOptions) {
  const client = getDirectusClient(sourceURL);
  const entries: Array<LocalVehicleRowData> = [];

  const filter = getDirectusFilterFromTableFilter({
    filters
  })

  if (currentViewFilterCondition?.includes("archived,eq,false")) {
    filter["_and"].push({
      archived: {
        _eq: false
      }
    });
  }

  const totalCountRequest = await client.request(aggregate("local_vehicles", {
    aggregate: {
      count: "*"
    },
    query: {
      filter: filter
    }
  }));

  const totalCount = (totalCountRequest?.[0]?.count && parseInt(totalCountRequest?.[0]?.count, 10)) || 0;

  const response = await client.request(readItems("local_vehicles", {
    limit: recordsLimit,
    offset: pageNo * recordsLimit,
    sort: sortString,
    filter,
    fields: [
      "id",
      "archived",
      "comment",
      "number",
      { "brands": ["brand", "id"] },
      "emoji_mark",
      {
        "document": ["directus_files_id"]
      },
      "photo",
      "id_issue_2024",
      "updated_at",
      "created_at",
      { "lands_n_objects": ["area_name", "id", "status"] }
    ],
    signal: controller.signal,
  }));

  // console.log("response", response);

  response.forEach(entry => {
    const vehicleFormatted: LocalVehicleRowData = {
      number: entry.number,
      comment: entry.comment,
      docs: reduceDirectusFilesToStringArray(entry.document),
      mark: entry.emoji_mark,
      photo: entry.photo as string | null,
      updated_at: formatDateTime(entry?.updated_at),
      updated_at_full: entry?.updated_at,
      created_at: formatDateTime(entry?.created_at),
      archived: entry.archived,
      origin_ID: entry.id,
      id_issue_2024: entry?.id_issue_2024,
    }

    if (entry.lands_n_objects) {
      const object = entry?.lands_n_objects ? {
        Id: entry.lands_n_objects.id,
        area_name: entry.lands_n_objects.area_name,
        status: entry.lands_n_objects.status?.join(", ")
      } : null;
      vehicleFormatted.object = object;
    }


    if (entry.brands) {
      const brandFormatted: CreatableSelectOption<number> = {
        label: entry.brands.brand,
        value: entry.brands.id,
      }
      vehicleFormatted.brand = brandFormatted;
    }

    entries.push(
      vehicleFormatted
    );

  });

  return { count: totalCount, data: entries };
}
