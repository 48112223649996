import { QueryFields, QueryFilter, QuerySort, aggregate, createItem, createItems, deleteItems, readItems, updateItems, updateItemsBatch } from "@directus/sdk";
import { FormValues } from "../../components/add-to-journal/AddToJournalForm";
import { ItemToMerge } from "../../components/add-to-journal/JournalEntryMergingConfirmation";
import { UserData } from "../../components/MainContext";
import { Collections, Schema, Types } from "../../models/DirectusSchema";
import { formatDateTime } from "../dateTimeHelpers";
import { getDirectusClient } from "../DirectusSDKClient";
import { handleDirectusError, isDirectusError } from "../DirectusServices/directusErrors";
import { getDirectusFilterFromTableFilter } from "../DirectusServices/directusHelpers";
import { CreatableSelectOption } from "../useSelectOptions";

export interface LandObject {
  Id: number;
  area_name: string;
  status: string;
}

export type JournalRowData = {
  is_active: boolean,
  dest: LandObject[],
  number: string,
  brand: CreatableSelectOption<number>,
  category: string,
  comment: string,
  entered: boolean,
  daily: boolean,
  credit: boolean,
  paid?: boolean,
  parking?: string,
  useful_photo?: Types.UUID,
  entry_point: string,
  entered_at: Types.DateTime,
  deposit_id: number,
  created_at: Types.DateTime,
  updated_at: Types.DateTime,
  updated_at_full: Types.DateTime,
  created_by: {
    "id": Types.UUID,
    "first_name": string,
    "last_name": string,
    "email": string
  },
  origin_ID: number,
}

export type ProvideJournalOptions = {
  pageNo?: number,
  recordsLimit?: number,
  filters?: Array<{ id: string, value: string | number | boolean }>,
  fields?: QueryFields<Schema, Collections.Journal>,
  currentViewFilterCondition?: string | QueryFilter<Schema, Collections.Journal>,
  sortString?: Array<QuerySort<Schema, Collections.Journal>>,
  controller?: AbortController,
  sourceURL?: string
}

const defaultJournalFields: QueryFields<Schema, Collections.Journal> = [
  "is_active",
  {
    "lands_n_objects": [{
      "lands_n_objects_id": ["area_name", "id", "status"]
    }]
  },
  "number",
  { "brands": ["brand", "id"] },
  "category",
  "comment",
  "entered",
  "daily",
  "credit",
  "paid",
  "parking",
  "photo",
  "entry_point",
  "entered_at",
  "deposits",
  "updated_at",
  "created_at",
  { "user_created": ["id", "first_name", "last_name", "email"] },
  "id",
];

export async function provideJournalEntries<T extends QueryFields<Schema, Collections.Journal> = typeof defaultJournalFields>({
  pageNo = 0,
  recordsLimit = 50,
  filters = [],
  fields = defaultJournalFields as T,
  currentViewFilterCondition = undefined,
  sortString = ["-updated_at"],
  controller = undefined,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: ProvideJournalOptions & { fields?: T }) {
  const client = getDirectusClient(sourceURL);
  const entries: Array<Partial<JournalRowData>> = [];
  // console.log("=-=-=-=-=-=-= called with fields: ", fields);

  const filter = getDirectusFilterFromTableFilter({
    filters
  })

  if (typeof currentViewFilterCondition === "string") {
    if (currentViewFilterCondition?.includes("is_active,eq,false")) {
      filter["_and"].push({
        is_active: {
          _eq: false
        }
      });
    } else if (currentViewFilterCondition?.includes("is_active,eq,true")) {
      filter["_and"].push({
        is_active: {
          _eq: true
        }
      });
    } else if (currentViewFilterCondition === "onlyWithDebt") {
      filter["_and"].push({
        _and: [
          {
            "lands_n_objects": {
              "lands_n_objects_id": {
                "status": {
                  "_ncontains": "Постоплата"
                }
              }
            }
          },
          {
            "entered": {
              _eq: true
            }
          },
          {
            _or: [
              {
                "parking": {
                  _eq: "Парковка в кредит"
                }
              },
              {
                _and: [
                  {
                    "credit": {
                      _eq: true
                    },
                    "paid": {
                      _eq: false
                    }
                  },
                ]
              }
            ]
          }
        ]
      });
    } else if (currentViewFilterCondition === "includePayed") {
      filter["_and"].push({
        _and: [
          {
            "lands_n_objects": {
              "lands_n_objects_id": {
                "status": {
                  "_ncontains": "Постоплата"
                }
              }
            }
          },
          {
            "entered": {
              _eq: true
            }
          },
          {
            _or: [
              {
                "credit": {
                  _eq: true
                }
              },
              {
                "parking": {
                  _eq: "Парковка в кредит"
                }
              },
              {
                "parking": {
                  _eq: "Парковка оплачена"
                }
              },
              {
                "paid": {
                  _eq: true
                }
              }
            ]
          }
        ]
      });
    }
  } else if (currentViewFilterCondition) {
    filter["_and"].push(currentViewFilterCondition);
  }

  const totalCountRequest = await client.request(aggregate("journal", {
    aggregate: {
      count: "*"
    },
    query: {
      filter: filter
    }
  }));

  const totalCount = (totalCountRequest?.[0]?.count && parseInt(totalCountRequest?.[0]?.count, 10)) || 0;

  const response = await client.request(readItems("journal", {
    limit: recordsLimit,
    offset: pageNo * recordsLimit,
    sort: sortString,
    filter,
    fields,
    signal: controller.signal,
  }));
  
  // console.log("just made request to journal with: ", filter, sortString, fields, recordsLimit, pageNo);

  // response.forEach(entry => {
  //   const areas: LandObject[] = entry.lands_n_objects.length > 0 ? entry.lands_n_objects?.map(el => {
  //     return {
  //       Id: el.lands_n_objects_id.id,
  //       area_name: el.lands_n_objects_id.area_name,
  //       status: el.lands_n_objects_id.status?.join(", ")
  //     }
  //   }) : [];

  //   const brandFormatted: BrandFormatted = {
  //     label: entry?.brands?.brand,
  //     value: entry?.brands?.id,
  //   }

  //   entries.push(
  //     {
  //       is_active: entry.is_active,
  //       dest: areas,
  //       number: entry.number,
  //       brand: brandFormatted,
  //       category: entry.category,
  //       comment: entry.comment,
  //       entered: entry.entered,
  //       daily: entry.daily,
  //       credit: entry.credit,
  //       paid: entry.paid,
  //       parking: entry.parking,
  //       useful_photo: entry.photo as Types.UUID | null,
  //       entry_point: entry.entry_point,
  //       entered_at: formatDateTime(entry?.entered_at),
  //       deposit_id: entry.deposits as number,
  //       created_at: formatDateTime(entry?.created_at),
  //       updated_at: formatDateTime(entry?.updated_at),
  //       updated_at_full: entry?.updated_at,
  //       created_by: entry.user_created,
  //       origin_ID: entry.id
  //     }
  //   );
  // });
  response.forEach(entry => {
    const formattedEntry: Partial<JournalRowData> = {};

    for (const field of fields) {
      if (typeof field === 'string') {
        switch (field) {
          case 'is_active':
          case 'number':
          case 'category':
          case 'comment':
          case 'entered':
          case 'daily':
          case 'credit':
          case 'paid':
          case 'parking':
          case 'entry_point':
            // @ts-expect-error I don't know how to make typescript get it right
            formattedEntry[field] = entry[field];
            break;
          case 'photo':
            // @ts-expect-error I don't know why typescript can't get it right.
            formattedEntry.useful_photo = entry.photo as Types.UUID;
            break;
          case 'entered_at':
          case 'created_at':
            formattedEntry[field] = formatDateTime(entry[field]);
            break;
          case 'updated_at':
            formattedEntry[field] = formatDateTime(entry[field]);
            formattedEntry.updated_at_full = entry[field];
            break;
          case 'id':
            formattedEntry.origin_ID = entry[field];
            break;
        }
      } else if (typeof field === 'object') {

        if ('lands_n_objects' in field) {
          // @ts-expect-error I don't know why typescript can't get it right.
          formattedEntry.dest = entry.lands_n_objects?.map(el => ({
            Id: el.lands_n_objects_id.id,
            area_name: el.lands_n_objects_id.area_name,
            status: el.lands_n_objects_id.status?.join(", ")
          })) || [];
        }

        if ('brands' in field) {
          // @ts-expect-error I don't know why typescript can't get it right.
          const brand = entry?.brands;

          if (brand) {
            formattedEntry.brand = {
              label: brand?.brand,
              value: brand?.id
            };
          }

        }

        if ('user_created' in field) {
          // @ts-expect-error I don't know why typescript can't get it right.
          formattedEntry.created_by = entry.user_created;
        }
      }
    }

    entries.push(formattedEntry);
  });

  return { count: totalCount, data: entries };
}

export const provideJournalEntriesCategories = () => {
  const categoryOptions = [
    { value: "Легковой", label: "Легковой" },
    { value: "До 3.5 тонн", label: "До 3.5 тонн" },
    { value: "Более 3.5 тонн", label: "Более 3.5 тонн" },
    { value: "Длинномеры > 8 м", label: "Длинномеры > 8 м" },
    { value: "8 кубов конт.", label: "8 кубов конт." },
    { value: "20 кубов конт.", label: "20 кубов конт." },
    { value: "27 кубов конт.", label: "27 кубов конт." },
    { value: "32 кубов конт.", label: "32 кубов конт." },
    { value: "Транзит стр. техники", label: "Транзит стр. техники" },
    { value: "Пешеход", label: "Пешеход" },
  ]

  return categoryOptions;
}

type CreateJournalRowsProps = {
  userData: UserData;
  entriesData: FormValues;
  createdBrandIdRef: React.MutableRefObject<number>;
  sourceURL?: string;
}

export const createJournalRows = async ({
  userData,
  entriesData,
  createdBrandIdRef,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: CreateJournalRowsProps) => {
  // console.log(
  //   "entriesData", entriesData
  // )
  const destinations = entriesData.destination.map(d => {
    return {
      lands_n_objects_id: d.value
    }
  });
  const created_by = userData.id;
  const entriesToCreate = [];
  const client = getDirectusClient(sourceURL);

  for (let i = 0; i < entriesData.vehicles.length; i++) {
    const vehicle = entriesData.vehicles[i];

    const obj: Partial<Collections.Journal> = {
      // "lands_n_objects": [vehicle?.targetAreaIDs] || destinations,
      // @ts-expect-error I just can't get this
      "lands_n_objects": vehicle?.targetAreasIDs || destinations,
      "number": vehicle.number,
      "category": vehicle.category.value,
      "credit": vehicle.debt,
      "daily": vehicle.daily,
      "entered": vehicle.entered,
      "is_active": vehicle.is_active,
      "comment": vehicle.comment,
      "created_by": created_by,
    }

    if (vehicle.entered === true) {
      obj.entered_at = new Date().toISOString();
      obj.entry_point = !!userData.roles.includes('kpp2') ? "КПП-2" : !!userData.roles.includes('kpp3') ? "КПП-3" : "КПП-1";
    }

    if (!vehicle.brand.__isNew__) {
      obj.brands = vehicle.brand.value;
    } else {
      const responseOnCreate = await client.request(createItem(
        "brands",
        { brand: vehicle.brand.label },
        { fields: ["*"] }
      )).catch((err) => {
        console.error(err)
        return null;
      });
      console.log("new brand created: ", responseOnCreate);

      window.localStorage.removeItem('brandsOptions=all');
      createdBrandIdRef.current = responseOnCreate?.id;
      obj.brands = responseOnCreate.id;
    }

    entriesToCreate.push(obj);
  }

  try {
    return await client.request(createItems(
      "journal",
      entriesToCreate
    ))
  } catch (error) {
    if (isDirectusError(error)) {
      handleDirectusError({
        error,
        shouldThrow: true
      })
    }
    throw error;
  }
}


type RemoveDuplicatesProps = {
  itemsToMerge: ItemToMerge[];
  dataToSend: FormValues;
  sourceURL?: string;
}

export const removeDuplicatesAndEnrichDataToSend = async ({
  itemsToMerge,
  dataToSend,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: RemoveDuplicatesProps) => {
  const client = getDirectusClient(sourceURL);

  const uniqueDuplicatesDestinations = new Set<number>();
  const itemsToDelete = new Set<number>();

  itemsToMerge.forEach(item => {
    if (item.dest_ID?.length > 0) {
      item.dest_ID.forEach(d => {
        uniqueDuplicatesDestinations.add(d)
      })
      itemsToDelete.add(item.origin_ID);
    }
  })

  const newEntryToEnrich = dataToSend.vehicles.find(guest => guest.mergeCandidate);

  // delete journal entries to replace
  await client.request(deleteItems("journal", Array.from(itemsToDelete)))

  // enrich new entry with destinations from just deleted entries
  dataToSend.destination.forEach(d => {
    uniqueDuplicatesDestinations.add(d.value);
  })

  const formattedTargetAreasIDs = Array.from(uniqueDuplicatesDestinations).map(d => { return { lands_n_objects_id: d } })

  newEntryToEnrich.targetAreasIDs = formattedTargetAreasIDs;

  return dataToSend;
}

interface PatchJournalEntriesProps {
  IDs: number[];
  data: Partial<Collections.Journal>;
  sourceURL?: string;
}

export const patchJournalEntries = async ({
  IDs,
  data,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: PatchJournalEntriesProps) => {
  const client = getDirectusClient(sourceURL);
  return await client.request(updateItems("journal", IDs, data));
}
