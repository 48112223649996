import React, { useRef, useState, useContext } from 'react';

import { MainContext } from "../MainContext";
import useCachedDataWithUpdates from "../../services/useCachedDataWithUpdates";

import LoadingIcon from "../commonUI/LoadingIcon";
import BlacklistItemMemoised from "./BlacklistItem"
import ModalDialog from "../modals/ModalDialog";
import AddToBlacklistDialog from "./AddToBlacklistDialog"
import { BlacklistItemData } from '../../services/DataProviders/blacklist';


const initialArrayDataObject: BlacklistItemData[] = [];
const blacklistOnViewSwitchFilterCondition = {
  "archived": {
    "_neq": true
  }
}

const BlacklistPage = () => {
    const { userData, siteSettings } = useContext(MainContext);
    const [confirmationRequired, setConfirmationRequired] = useState(false);
    const [blacklistData, setBlacklistData] = useState<BlacklistItemData[]>(initialArrayDataObject);
    const confirmationSubjectRef = useRef(<></>);

    const [
        isBlacklistLoading,
    ] = useCachedDataWithUpdates({
        location: "/blacklist",
        dataSource: "blacklist",
        updateFrequency: 3000,
        // @ts-expect-error temporary solution till useCachedDataWithUpdates will be moved to TS
        onViewSwitchFilterCondition: blacklistOnViewSwitchFilterCondition,
        sortString: "-updated_at",
        offViewSwitchFilterCondition: undefined,
        altSortString: "-updated_at",
        localFieldToCompareBy: "updated_at_full",
        remoteFieldToCompareBy: "updated_at",
        nocodb_auth: userData.nocodb_auth,
        setTableData: setBlacklistData,
        initialStateObject: initialArrayDataObject,
        loggingEnabled: !!siteSettings?.blacklist_loggingEnabled,
    });
    
    const handleAddingToBlacklist = () => {
        confirmationSubjectRef.current = 
            <AddToBlacklistDialog
                siteSettings={siteSettings}
                closeDialogFunction={setConfirmationRequired}
            />
        setConfirmationRequired(true);
    }


    return (
        <>
        <div id="blacklist-page">
            <h2>Чёрный список</h2>
            {   
                !isBlacklistLoading && blacklistData ?
                <div id="blacklist-container">
                    <div id="add-to-blacklist-container">
                        <button 
                            className='button add-to-blacklist'
                            onClick={handleAddingToBlacklist}
                        >
                            +
                        </button>
                    </div>
                    {
                        blacklistData.map((car) => (
                            <BlacklistItemMemoised 
                                key={`BlacklistItem-${car.origin_ID}`}
                                item={car}
                                canBeReleased={true}
                                setConfirmationRequiered={setConfirmationRequired}
                                confirmationSubjectRef={confirmationSubjectRef}
                                detailsExpanded={true}
                            />
                        ))
                    }
                </div>
                :
                <LoadingIcon addClassName={""} />
            }
        </div>
        <ModalDialog 
            isOpen={confirmationRequired} 
            exitFunction={setConfirmationRequired}
        >
            {confirmationSubjectRef.current}
        </ModalDialog>
        </>
    );

}

export default BlacklistPage;