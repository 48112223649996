import React, { useContext, useMemo, useEffect, useRef, useCallback, useState } from 'react';

import { MainContext } from "../../MainContext";
import Table, { ColumnWithFilter } from '../../commonUI/Table';

import { AllRowsSelection } from "../../inputElements";
import SelectAreaForReport, { useAreaToFilterBy } from "./SelectAreaForReport";
import useSelectOptions from '../../../services/useSelectOptions';
import VehicleForReportRowMemoised from "./VehicleForReportRow";
import GetReportMemoized from './GetReport';

import { provideJournalEntries } from '../../../services/DataProviders/journal';

const initialArrayDataObject = [];

export interface DebtorsMap {
  [key: number]: number; // key is destination ID, value is number of debtors
}

const useDebtorsMap = ({ recentReportAreaId }) => {
  const [debtors, setDebtors] = useState<DebtorsMap>({});
  const [isLoading, setIsLoading] = useState(true);
  const abortControllerRef = useRef<AbortController>(undefined);
  const isFirstRender = useRef(true);

  const provideJournalDebtsCallback = useCallback(async () => {
    const temp = await provideJournalEntries({
      currentViewFilterCondition: {
        _and: [
          {
            "entered_at": {
              // @ts-expect-error this is a valid filter, dunno where error is comming from
              "_gte": '$NOW(-3 months)'
            }
          },
          {
            "lands_n_objects": {
              "lands_n_objects_id": {
                "status": {
                  "_ncontains": "Постоплата"
                }
              }
            }
          },
          {
            "entered": {
              _eq: true
            }
          },
          {
            _or: [
              {
                "parking": {
                  _eq: "Парковка в кредит"
                }
              },
              {
                _and: [
                  {
                    "credit": {
                      _eq: true
                    },
                    "paid": {
                      _eq: false
                    }
                  },
                ]
              }
            ]
          }
        ]
      },
      fields: [
        "id",
        "entered_at",
        {
          "lands_n_objects": [{
            "lands_n_objects_id": ["area_name", "id", "status"]
          }]
        },
        "entered",
        "credit",
        "paid",
        "parking"
      ],
      recordsLimit: 10000,
      controller: abortControllerRef.current
    })
    return temp;
  }, []);

  const recentReportAreaIdString = useMemo(() => JSON.stringify(recentReportAreaId) || "none", [recentReportAreaId]);

  useEffect(() => {
    if (isFirstRender.current) {
      abortControllerRef.current = new AbortController();

      (async () => {
        setIsLoading(true);
        const debtorsEntries = await provideJournalDebtsCallback();
        const uniqueIds = debtorsEntries.data.reduce((prev, next) => {

          for (const dest of next.dest) {
            if (!prev) {
              prev = {
                [`${dest.Id}`]: 1
              }
            } else if (prev.hasOwnProperty(dest.Id)) {
              prev[`${dest.Id}`] += 1;
            } else {
              prev[`${dest.Id}`] = 1;
            }

          }

          return prev;
        }, {})
        setDebtors(uniqueIds);
        setIsLoading(false);
      })();

      isFirstRender.current = false;

    }

    return () => {
      abortControllerRef.current?.abort();
      abortControllerRef.current = undefined;
      isFirstRender.current = true;
    }
  }, [provideJournalDebtsCallback, recentReportAreaIdString])

  return useMemo(() => {
    return {
      isLoading,
      debtors
    }
  }, [isLoading, debtors]);
}

const PostpaidReports = () => {
  const { siteSettings } = useContext(MainContext);
  const [recentReportAreaId, setRecentReportAreaId] = useState<number>();

  const { isLoading, debtors } = useDebtorsMap({ recentReportAreaId });

  const [
    areOptionsLoading,
    allDestinationOptionsRef,
    destinationOptions,
  ] = useSelectOptions({
    siteSettings,
    optionName: "destinationOptions",
    currentViewFilterCondition: {
      _or: [
        {
          _and: [
            {
              status: {
                // @ts-expect-error By some reason, Directus returns string[] instead of coma separeted string, as stored in DB
                _contains: "Постоплата",
              },
            },
            {
              status: {
                // @ts-expect-error By some reason, Directus returns string[] instead of coma separeted string, as stored in DB
                _ncontains: "Только по депозитам",
              }
            }
          ]
        },
        {
          id: {
            // @ts-expect-error Directus is smart enough to cast strings to numbers as IDs
            _in: Object.keys(debtors)?.length > 0 ? Object.keys(debtors) : ""
          }
        }
      ]
    },
    valueToTriggerRecall: debtors,
    enrichOptionsWithDeptorsData: debtors
  })

  const [
    areaToFilterBy,
    setAreaToFilterBy,
  ] = useAreaToFilterBy({ destinationOptions });

  const initialHiddenColumns = {
    onlyActiveHidden: ['dest', 'useful_photo', 'entry_point', 'entered_at', 'paid', 'deposit_id', 'parking', 'origin_ID'],
    allHidden: ['dest', 'useful_photo', 'origin_ID']
  };

  const cols = useMemo(() => {
    return [
      {
        Header: 'Объект',
        accessor: 'dest',
      },
      {
        Header: 'Номер',
        accessor: 'number',
      },
      {
        Header: 'Марка',
        accessor: 'brand',
      },
      {
        Header: 'Категория',
        accessor: 'category',
      },
      {
        Header: 'Комментарий',
        accessor: 'comment',
      },
      {
        Header: 'Статус парковки',
        accessor: 'parking',
      },
      {
        Header: 'Фото',
        accessor: 'useful_photo',
        disableFilters: true,
      },
      {
        Header: 'Доступ оплачен',
        accessor: 'paid',
        disableFilters: true,
      },
      {
        Header: 'Доступ в кредит',
        accessor: 'credit',
        disableFilters: true,
      },
      {
        Header: 'По депозиту c ID:',
        accessor: 'deposit_id',
      },
      {
        Header: 'Время заявки',
        accessor: 'created_at',
      },
      {
        Header: 'Время доступа',
        accessor: 'entered_at',
      },
      {
        Header: 'Точка доступа',
        accessor: 'entry_point',
      },
      {
        Header: 'origin_ID',
        accessor: 'origin_ID',
      }
    ];
  }, [])

  const columns = useMemo(() => {
    const tempArr = [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <AllRowsSelection
            {...getToggleAllRowsSelectedProps()}
          />
        ),
        disableFilters: true,
      }
    ]

    // @ts-expect-error temp fix
    const fin = tempArr.concat(cols)

    return fin;
  }, [cols]);

  const altColumns = useMemo(() => {
    return cols;
  }, [cols])

  const getVehicleForReportRowMemoised = useCallback(({
    row,
    hiddenColumnsLength,
    justAlteredRowsRef,
    selectedFlatRows,
  }) => {
    return <VehicleForReportRowMemoised
      key={`row-entry-${row.original.origin_ID}__${row.original.updated_at.replace(/\s/g, '_')}__${hiddenColumnsLength}__${row.isSelected}`}
      preparedRow={row}
      justAlteredRowsRef={justAlteredRowsRef}
      selectedFlatRows={selectedFlatRows}
    />
  }, [])

  const GetMemoizedReportCallback = useCallback(({
    selectedFlatRows,
    justAlteredRowsRef,
    toggleAllRowsSelected,
  }) => {
    return (
      <>
        {
          areaToFilterBy && selectedFlatRows?.length > 0 &&
          <GetReportMemoized
            areaToFilterBy={areaToFilterBy}
            markedEntries={selectedFlatRows}
            justAlteredRowsRef={justAlteredRowsRef}
            toggleAllRowsSelected={toggleAllRowsSelected}
            setRecentReportAreaId={setRecentReportAreaId}
          />
        }
      </>
    );
  }, [areaToFilterBy])

  const getDirectusFilterCallback = useCallback(({ showOnlyForReport = true }) => {
    const filter = {
      "_and": []
    }
    
    filter._and.push(
      {
        "entered": {
          _eq: true
        }
      },
      {
        "category": {
          "_neq": "Пешеход"
        }
      },
      {
        "lands_n_objects": {
          "lands_n_objects_id": {
            "id": {
              "_eq": areaToFilterBy?.value || null
            }
          }
        }
      }
    )

    if (areaToFilterBy?.status_changed_at) {
      filter._and.push({
        "entered_at": {
          "_gte": areaToFilterBy?.status_changed_at
        }
      })
    }

    if (!areaToFilterBy?.area_status.includes('Весь транспорт платный')) {
      filter._and.push({
        "category": {
          "_neq": "Легковой"
        }
      })
    }

    if (showOnlyForReport) {
      if (!areaToFilterBy?.area_status.includes('Постоплата')) {
        filter._and.push(
          {
            _or: [
              {
                "parking": {
                  _eq: "Парковка в кредит"
                }
              },
              {
                _and: [
                  {
                    "credit": {
                      _eq: true
                    },
                  },
                  {
                    "paid": {
                      _eq: false
                    }
                  }
                ]
              }
            ]
          }
        )
      } else {
        filter._and.push(
          {
            _or: [
              {
                "parking": {
                  _eq: "Парковка в кредит"
                }
              },
              {
                "paid": {
                  _eq: false
                }
              }
            ]
          }
        )
      }
    }
    if (!showOnlyForReport) {
      if (!areaToFilterBy?.area_status.includes('Постоплата')) {
        filter._and.push(
          {
            _or: [
              {
                "parking": {
                  _nnull: true
                }
              },
              {
                _and: [
                  {
                    "credit": {
                      _eq: true
                    },
                  }
                ]
              }
            ]
          }
        )
      } else {
        filter._and.push(
          {
            "parking": {
              _nnull: true
            }
          }
        )
      }
    }
    return filter;
  }, [areaToFilterBy])

  return (
    <Table
      location='/accounting'
      tablePrefix="reports"
      initialArrayDataObject={initialArrayDataObject}
      initialShowOnlyActiveState={true}
      columns={columns as unknown as ColumnWithFilter<any>[]}
      altColumns={altColumns as unknown as ColumnWithFilter<any>[]}
      initialHiddenColumns={initialHiddenColumns}
      toggle={
        {
          enabled: true,
          showOnlyActiveLabel: "К оплате:",
          showAllLabel: "Все записи:",
          addClassName: "reports-toggle",
          toggleContainerAddClassName: "boxProperty",
          mutateId: true,
          isToggleDisabled: false,
          onlyHat: false,
          setId: "setShowOnlyForReportSwitch",
          insertBefore: (
            <SelectAreaForReport
              isLoading={isLoading}
              optionsRef={allDestinationOptionsRef}
              options={destinationOptions}
              setAreaToFilterBy={setAreaToFilterBy}
              value={areaToFilterBy}
              label={"Целевой объект"}
            />
          )
        }
      }
      returnMemoizedTableRowComponent={getVehicleForReportRowMemoised}
      noEntriesMessage={'Подходящие записи не найдены 🙃'}
      dataSettings={{
        blockFetching: areOptionsLoading,
        dependency: areaToFilterBy,
        initialPageSize: 1000,
        altInitialPageSize: 10,
        updateFrequency: 5000,
        cachedDataLifeSpan: 60000,
        onViewSwitchFilterCondition: getDirectusFilterCallback({ showOnlyForReport: true }),
        offViewSwitchFilterCondition: getDirectusFilterCallback({ showOnlyForReport: false }),
        sortString: "-updated_at",
        altSortString: "-updated_at",
        localFieldToCompareBy: "updated_at_full",
        remoteFieldToCompareBy: "updated_at",
        keepTableSettings: false,
        syncRecordsPerPage: false,
        shallowQuery: false,
      }}
      insertAfterTable={GetMemoizedReportCallback}
      // forceReloadRef={justAlteredRowsRef}
    />
  );
}

export default React.memo(PostpaidReports);
