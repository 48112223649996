// import UserService from "./UserService";
const UserService = await import('./DirectusUserService').then(module => module.default);

const RenderOnAnonymous = ({ children }) => {
    if (UserService.isLoggedIn() === false) {
        return children
    } 
    
    return null;
}

export default RenderOnAnonymous;