import { QueryFilter, QuerySort, aggregate, deleteItem, readItems, updateItems } from "@directus/sdk";
import { Collections, Schema, Types } from "../../models/DirectusSchema";
import { formatDateTime } from "../dateTimeHelpers";
import { getDirectusClient } from "../DirectusSDKClient";
import { getDirectusFilterFromTableFilter } from "../DirectusServices/directusHelpers";
import { CreatableSelectOption } from "../useSelectOptions";


export interface FullBrandFormatted {
  common_brand: string,
  journal_count: string,
  local_vehicles_count: string,
  blacklist_count: string,
  updated_at_full: Types.DateTime,
  updated_at: string,
  created_at: string,
  origin_ID: number,
}

export interface ProvideBrandsOptions {
  shallowQuery?: boolean,
  pageNo?: number,
  recordsLimit?: number,
  filters?: Array<{ id: string, value: string | number | boolean }>,
  currentViewFilterCondition?: QueryFilter<Schema, Collections.Brands>,
  sortString?: Array<QuerySort<Schema, Collections.Brands>>,
  controller: AbortController,
  sourceURL?: string
}

interface ProvidedBrandsResponse {
  count: number,
  data: FullBrandFormatted[]
}

export async function provideBrands(
  { shallowQuery }:
    Pick<ProvideBrandsOptions, "shallowQuery">):
  Promise<CreatableSelectOption<number>[]>;
export async function provideBrands(
  { currentViewFilterCondition, pageNo, sortString, shallowQuery,
    recordsLimit, filters, controller, sourceURL
  }:
    ProvideBrandsOptions):
  Promise<Array<CreatableSelectOption<number>>>;
export async function provideBrands({
  currentViewFilterCondition,
  pageNo = 0,
  sortString,
  recordsLimit = 10000,
  filters = [],
  shallowQuery = false,
  controller,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: ProvideBrandsOptions): Promise<CreatableSelectOption<number>[] | ProvidedBrandsResponse> {
  const client = getDirectusClient(sourceURL);

  if (shallowQuery) {
    const entries: Array<CreatableSelectOption<number>> = [];

    const response = await client.request(readItems("brands", {
      limit: 10000,
      sort: "brand",
      fields: [
        "brand",
        "id"
      ],
      signal: controller.signal,
    }));

    response.forEach(option => {
      entries.push({ value: option.id, label: option.brand })
    })

    return entries;
  }

  const entries: Array<FullBrandFormatted> = [];
  const filter = getDirectusFilterFromTableFilter({
    filters
  })

  if (currentViewFilterCondition) {
    filter._and.push(currentViewFilterCondition);
  }

  const totalCountRequest = await client.request(aggregate("brands", {
    aggregate: {
      count: "*"
    },
    query: {
      filter: filter
    },
  }));

  const totalCount = (totalCountRequest?.[0]?.count && parseInt(totalCountRequest?.[0]?.count, 10)) || 0;

  const response = await client.request(readItems("brands", {
    limit: recordsLimit,
    offset: pageNo * recordsLimit,
    sort: sortString,
    filter,
    fields: [
      "brand",
      "id",
      "created_at",
      "updated_at",
      { "journal": ["id"] },
      { "blacklist": ["id"] },
      { "local_vehicles": ["id"] }
    ],
    deep: {
      "journal": {
        "_limit": 100
      },
      "blacklist": {
        "_limit": 10000
      },
      "local_vehicles": {
        "_limit": 10000
      },
    },
    signal: controller.signal,
  }));

  response.forEach(entry => {
    let createdAt: string, updatedAt: string;

    if (typeof (entry?.created_at) === "string") {
      createdAt = formatDateTime(entry.created_at);
    } else {
      createdAt = "неизвестно";
    }

    if (typeof (entry?.updated_at) === "string") {
      updatedAt = formatDateTime(entry.updated_at);
    } else {
      updatedAt = "неизвестно";
    }

    const formatLimitedNumber = (number: number) => {
      if (number >= 100) {
        return "100+"
      }
      return number.toString();
    }

    entries.push(
      {
        common_brand: entry.brand,
        journal_count: formatLimitedNumber(entry.journal.length),
        local_vehicles_count: formatLimitedNumber(entry.local_vehicles.length),
        blacklist_count: formatLimitedNumber(entry.blacklist.length),
        updated_at_full: entry.updated_at,
        updated_at: updatedAt,
        created_at: createdAt,
        origin_ID: entry.id,
      }
    )

  })

  return { count: totalCount, data: entries };
}


export interface FullBrandFormattedWithNumbers {
  brand: string,
  origin_ID: number,
  blacklist_count: number,
  local_vehicles_count: number,
  journal_count: number,
  totalRelatedVehicles: number
}

interface ExecuteBrandsMerge {
  brandToDelete: FullBrandFormattedWithNumbers,
  brandToEnrich: FullBrandFormattedWithNumbers,
  sourceURL?: string;
}

export const executeBrandsMerge = async ({
  brandToDelete,
  brandToEnrich,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: ExecuteBrandsMerge) => {
  const client = getDirectusClient(sourceURL);

  const report = {
    replacedBrand: brandToDelete,
    targetBrand: brandToEnrich,
  };

  const patchEntriesWithStaleBrand = async (endpoint: "journal" | "local_vehicles" | "blacklist") => {
    const itemsToGetPropperBrand = await client.request(readItems(
      endpoint,
      {
        limit: 10000,
        sort: "-id",
        filter: {
          "brands": {
            "_eq": brandToDelete.origin_ID
          }
        },
        fields: [
          "id"
        ]
      }
    ))
    // console.log(`Found ${itemsToGetPropperBrand?.length} items on "${endpoint}" to patch with brandToEnrich: `, brandToEnrich)

    const idsToPatch = itemsToGetPropperBrand?.map(item => item.id);
    // console.log("idsToPatch.length", idsToPatch.length)
    
    if (idsToPatch.length >= 50) {
      throw new Error(`Запрещено объёдинять марки с большим числом связанного транспорта. В таблице "${endpoint}" ${idsToPatch.length} автомобилей связано с ${brandToDelete.brand}`);
    } else {
      await client.request(updateItems(
        endpoint,
        idsToPatch,
        {
          "brands": brandToEnrich.origin_ID
        }
      ));
    }

    report[`${endpoint}-IDsUpdated`] = idsToPatch;
  }

  if (brandToDelete.journal_count > 0) {
    await patchEntriesWithStaleBrand('journal');
  }

  if (brandToDelete.local_vehicles_count > 0) {
    await patchEntriesWithStaleBrand("local_vehicles");
  }

  if (brandToDelete.blacklist_count > 0) {
    await patchEntriesWithStaleBrand("blacklist");
  }

  // notifyAboutDangerousAction(report);
  console.log("report on brands changes: ", report);

  await client.request(deleteItem("brands", brandToDelete.origin_ID));
}
