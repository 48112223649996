import React, { useState, useEffect, useRef, useContext } from 'react';

import { MainContext } from "../MainContext";
import { useAsyncDebounce } from "react-table";

import { provideLocalVehicles } from "../../services/DataProviders/provideLocalVehicles";
import { provideLocalPedestrians } from "../../services/DataProviders/providePedestrians";
import { provideContacts } from "../../services/DataProviders/provideContacts";
import { provideJournalEntries } from "../../services/DataProviders/journal";
import { provideBlacklist } from "../../services/DataProviders/blacklist";

import { 
    // provideJournalEntries,
    // provideBlacklist,
    // provideLocalVehicles,
    // provideLocalPedestrians,
    // provideContacts,
 } from "../../services/ProvideDataObjects"

import RenderOnRole from '../../services/RenderOnRole';

const initialSearchFinished = {
    journal: false,
    blacklist: false,
    localVehicles: false,
    localPedestrians: false,
    contacts: false,
}

const SearchField = ({ 
    setBlacklistRes,
    setLocalVehiclesRes,
    setLocalPedestriansRes,
    setContactsRes,
    setJournalRes,
    initialQuery = "", 
    initialResult, 
    setSearchParams, 
    resultsPerSource 
}) => {
    const { userData, sourceURL } = useContext(MainContext);
    const [isLoading, setIsLoading] = useState(false);
    const searchFinished = useRef(initialSearchFinished);
    const searchQuery = useRef(false);
    const firstRender = useRef(true);
    const abortControllerRef = useRef(undefined);
    const intervalID = useRef(undefined);
    const queryStart = useRef(undefined);
    const queryEnd = useRef(undefined);

    const setInitialResults = () => {
        setBlacklistRes(initialResult)
        setLocalVehiclesRes(initialResult)
        setLocalPedestriansRes(initialResult)
        setJournalRes(initialResult)
        setContactsRes(initialResult)
    }

    const cleanUp = () => {
        // setSearchParams({ q: "" })
        queryStart.current = undefined;
        queryEnd.current = undefined;
        abortControllerRef.current?.abort();
        abortControllerRef.current = undefined;
        setInitialResults();
        searchFinished.current = initialSearchFinished;
    }

    const onMeaningQueryChange = useAsyncDebounce((value) => {

        cleanUp();
        abortControllerRef.current = new AbortController();
        searchQuery.current = value;
        executeSearch(value || undefined, abortControllerRef.current);

    }, 350);

    useEffect(() => {
    
        abortControllerRef.current = undefined;
        abortControllerRef.current = new AbortController();

        if (initialQuery && initialQuery !== "" && firstRender.current) {
            
            searchQuery.current = initialQuery;
            executeSearch(initialQuery, abortControllerRef.current);
            firstRender.current = false;

        }

        return () => {
            cleanUp();
            firstRender.current = true;

            if (intervalID.current) {
                clearInterval(intervalID.current);
                intervalID.current = undefined;
            } 

        }

    }, []);


    const searchLocalVehicles = async(value, searchFinished, controller) => {
        
        searchFinished.current.localVehicles = false;
        let localVehicles = await provideLocalVehicles({
            auth_token: userData.nocodb_auth, 
            recordsLimit: resultsPerSource, 
            filters: [
                {
                  id: "number",
                  value: value
                },
                {
                  id: "archived",
                  value: false
                }
            ],
            sourceURL: sourceURL,
            controller,
        });

        if (controller?.signal?.aborted === true) {
            return
        }
        
        if (localVehicles && localVehicles?.count > 0) {
            setLocalVehiclesRes(localVehicles)
        }
        
        searchFinished.current.localVehicles = true;
        // console.log("localVehicles search end", localVehicles);    
        

    }

    const searchBlacklistItems = async(value, searchFinished, controller) => {
        // console.log("blacklistItems search start")
        searchFinished.current.blacklist = false;
        let blacklistItems = await provideBlacklist({
            recordsLimit: resultsPerSource,
            currentViewFilterCondition: {
              _and:[
                {
                archived: {
                  _eq: false
                }
                },
                {
                  number: {
                    _icontains: value
                  }
                }
              ]
            },
            sourceURL: sourceURL,
            controller,    
        });

        
        if (controller?.signal?.aborted === true) {
            return
        }
         
        if (blacklistItems && blacklistItems?.count > 0) {
            setBlacklistRes(blacklistItems)
        }
        
        searchFinished.current.blacklist = true;
        // console.log("blacklistItems search end", blacklistItems)  

    }
    
    const searchLocalPedestrians = async(value, searchFinished, controller) => {
        // console.log("localPedestrians search start")
        searchFinished.current.localPedestrians = false;
        let localPedestrians = await provideLocalPedestrians({
            auth_token: userData.nocodb_auth, 
            recordsLimit: resultsPerSource,
            filters: [
              {
                id: "name",
                value: value,
              },
              {
                id: "archived",
                value: false
              }
            ],
            sourceURL: sourceURL,
            controller,    
        });

        if (controller?.signal?.aborted === true) {
            return
        }
        
        if (localPedestrians && localPedestrians?.count > 0) {
            setLocalPedestriansRes(localPedestrians)
        }
        
        searchFinished.current.localPedestrians = true;
        // console.log("localPedestrians search end",localPedestrians )               
         
    }

    const searchContacts = async(value, searchFinished, controller) => {

        searchFinished.current.contacts = false;
        let contacts = await provideContacts({
            auth_token: userData.nocodb_auth, 
            recordsLimit: resultsPerSource,
            filters: [
              {
                id: "name",
                value: value,
              },
              {
                id: "archived",
                value: false
              }
            ],
            sourceURL: sourceURL,
            controller,    
        });

        if (controller?.signal?.aborted === true) {
            return
        }
        
        if (contacts && contacts?.count > 0) {
          console.log("contacts", contacts)
          setContactsRes(contacts)
        }
        
        searchFinished.current.contacts = true;           
         
    }

    

    const searchJournalEntries = async(value, searchFinished, controller) => {
        // console.log("journalEntries search start")
        searchFinished.current.journal = false;
        let journalEntries = await provideJournalEntries({
            auth_token: userData.nocodb_auth,
            currentViewFilterCondition: {
              _and: [
                {
                  number: {
                    _icontains: value
                  },
                }
              ]
            },
            recordsLimit: resultsPerSource,
            sortString: "-updated_at",
            fields: [
              "id",
              "number",
              { "brands": ["brand", "id"] },
              "category",
              {
                "lands_n_objects": [{
                  "lands_n_objects_id": ["area_name", "id", "status"]
                }]
              },
              "entered",
              "entered_at",
              "entry_point",
              "updated_at"
            ],
            sourceURL: sourceURL,
            controller,
        });

        if (controller?.signal?.aborted === true) {
            return
        }

        if (journalEntries && journalEntries?.count > 0) {
            setJournalRes(journalEntries);
        }
        
        searchFinished.current.journal = true;
    }


    const executeSearch = async (value, controller) => {

        if (controller?.signal?.aborted === true) {
            setInitialResults()
            cleanUp()
            return;
        }
        
        queryStart.current = performance.now();
        setIsLoading(true)
        searchQuery.current = value;

        /**
         * TODO: cyrillic to eng and vice versa in query
         * TODO: if possible, ignore gaps between numbers and letters
         */

        searchBlacklistItems(value, searchFinished, controller);
        searchLocalVehicles(value, searchFinished, controller);
        searchJournalEntries(value, searchFinished, controller);
        searchLocalPedestrians(value, searchFinished, controller);
        searchContacts(value, searchFinished, controller);
        
        if (intervalID.current) {
            clearInterval(intervalID.current);
            intervalID.current = undefined;
        }

        intervalID.current = window.setInterval(() => {
            let finishedSources = 
                Object.values(searchFinished.current).filter(v => {
                    if (v === true) {
                        return true
                    } else {
                        return false
                    }
                })
        
            // console.log("finishedSources.length", finishedSources.length, finishedSources.length === 0, "finishedSources.length === 0")
            
            if (finishedSources.length === Object.keys(initialSearchFinished).length ) {
                setIsLoading(false)
                clearInterval(intervalID.current);
                intervalID.current = undefined;
                queryEnd.current = performance.now();
            }

        }, 1250)

    }
   

    return (
        <>
        <div className={`boxProperty search-field-container ${isLoading ? " searching" : ""}`}>
            <label id="search-label">Номер или имя:</label>
            <input
                className={`classyInput`}
                id={`main-search-field`}
                autoFocus={true}
                defaultValue={initialQuery}
                type="search"
                autoComplete="off"
                onChange={(e) => {
                    
                    if (e.target.value && e.target.value !== "") {

                        setSearchParams({ q: e.target.value })
                        onMeaningQueryChange(e.target.value);

                    } else {

                        cleanUp();
                        setSearchParams({ q: "" })
                        
                        if (intervalID.current) {
                            clearInterval(intervalID.current);
                            intervalID.current = undefined;
                        } 

                        setIsLoading(false);

                    }

                }}
            />
            <div className={`progress-container`}></div>
            <RenderOnRole roles={["dev", "analyst"]}>
                {
                    queryEnd.current && queryStart.current &&

                    <div id={`search-time`}>
                        {queryEnd.current - queryStart.current + "ms"}
                    </div>
                }
            </RenderOnRole>
        </div>
        </>
    );
}

export default SearchField;