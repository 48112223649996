import React, { useState, useEffect, useContext, useRef } from 'react';
import CreatableSelect from 'react-select/creatable';
import { matchSorter } from 'match-sorter';
import { Cell } from 'react-table';
import { shouldBeDisabled } from '../../inputElements';
import { MainContext } from '../../MainContext';
import { CreatableSelectOption, SelectOption } from '../../../services/useSelectOptions';
import { setCreatableSelectOption } from '../../../services/TableDataMutations/setCreatableSelectOption';
import { reasonableFilterConfig } from './commonResources';
import { createFilter } from 'react-select';
import { RefCallBack } from 'react-hook-form';

interface CreatableBrandSelectProps {
  cell?: Cell<any>;
  allOptionsRef: React.MutableRefObject<any>;
  isLoading: boolean;
  localStorageName: string;
  isDisabled?: boolean;
  onChangeFunction?: (newValue: any) => void;
  forwardRef?: RefCallBack;
}

export const CreatableBrandSelect = ({
  cell,
  allOptionsRef,
  isLoading,
  localStorageName,
  isDisabled,
  onChangeFunction,
  forwardRef
}: CreatableBrandSelectProps) => {
  const { userData } = useContext(MainContext);
  const [brandsOptions, setBrandsOptions] = useState<CreatableSelectOption<number>[]>(allOptionsRef.current);
  const [menuCanBeOpened, setMenuCanBeOpened] = useState(undefined);
  const thisCreatableBrandSelect = useRef(forwardRef ? forwardRef : null);
  const newValueRef = useRef();

  let disabled = isDisabled;

  if (disabled === undefined || disabled === null) {
    disabled = shouldBeDisabled(userData, cell.row.original.updated_at);
  }

  useEffect(() => {
    const intervalID = window.setInterval(() => {

      if (allOptionsRef.current?.length > 0) {
        setBrandsOptions(allOptionsRef.current)
        clearInterval(intervalID);
      }
    }, 1500)

    return () => {
      clearInterval(intervalID)
    }

  }, [])

  function onInputChange(value: string, { action }) {
    if (action === 'input-change') {
      const filteredOptions = matchSorter(brandsOptions, value, { keys: ['label'] });
      setBrandsOptions(filteredOptions);

      if (value === "") {
        setBrandsOptions(allOptionsRef.current);
      }
    } else if (action === 'menu-close') {
      setBrandsOptions(allOptionsRef.current);
    }
  }

  const makeDefaultValue = (desiredValue, brandsOptions) => {
    if (brandsOptions !== undefined) {
      return brandsOptions.filter(opt => opt?.label === desiredValue?.label)
    } else {
      return undefined;
    }

  }


  const onChange = (newValue) => {
    if (!onChangeFunction) {

      setCreatableSelectOption({
        targetTable: 'brands',
        options: brandsOptions,
        setOptions: setBrandsOptions,
        allOptionsRef,
        cell,
        newValue,
        newValueRef,
        localStorageName
      })
    } else {

      onChangeFunction(newValue);
    }
  }

  const handleKeyDown = (e) => {
    /**
     * keycode 40 == arrowDown
     * keycode 38 == arrowUp
     * ---
     * Function block menu opening on arrow keys
     */

    if (e.keyCode === 40 || e.keyCode === 38) {
      console.log("handleKeyDown", e.keyCode)
      console.log("thisCreatableBrandSelect.current", thisCreatableBrandSelect.current)
      // @ts-expect-error ffs
      if (thisCreatableBrandSelect.current?.inputRef.getAttribute("aria-expanded") !== "true") {
        setMenuCanBeOpened(false)
      }

    } else {
      setMenuCanBeOpened(undefined)
    }

  }


  return (
    <CreatableSelect
      //  @ts-expect-error ffs
      ref={thisCreatableBrandSelect}
      isClearable={false}
      key={`creatable-select-${cell?.value?.id}-${allOptionsRef.current?.length}`}
      onChange={(e) => { onChange(e); }}
      onKeyDown={handleKeyDown}
      menuIsOpen={menuCanBeOpened}
      captureMenuScroll={true}
      //  @ts-expect-error ffs
      options={brandsOptions}
      isSearchable={true}
      filterOption={createFilter(reasonableFilterConfig)}
      onInputChange={onInputChange}
      isDisabled={disabled}
      isLoading={isLoading}
      loadingMessage={() => { return "Идёт загрузка..." }}
      noOptionsMessage={() => { return "Нет опций" }}
      placeholder=""
      formatCreateLabel={(value) => `Добавить: "${value}"`}
      classNamePrefix="creatable-select"
      defaultValue={() => {
        if (newValueRef.current) {
          console.log("setting newValueRef.current as default", newValueRef.current)
          return newValueRef.current
        } else {
          return makeDefaultValue(cell?.value, allOptionsRef.current)
        }
      }
      }
    />
  );
}
