import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select, { createFilter } from 'react-select';


import LoadingIcon from "../commonUI/LoadingIcon";
import { reasonableFilterConfig } from "../commonUI/inputs/commonResources";
import { CreatableBrandSelect } from "../commonUI/inputs/CreatableBrandSelect";
import { FileInput, ProceedStatus } from '../commonUI/inputs/FileInput'
import useSelectOptions, { CreatableSelectOption, onSelectInputChange, SelectOption } from '../../services/useSelectOptions';
import { createEntryInBlacklist } from "../../services/DataProviders/blacklist";
import { getRidOfAliases } from "../../services/littleHelpers";
import { notifyTelegramGroup } from "../../services/LandshaftRobot/notifications";
import { SiteSettings } from '../MainContext';
import { FormState } from '../add-to-journal/AddToJournalForm';
import { DirectusRequestError, ErrorData } from '../../services/DirectusServices/directusErrors';
import { DirectusFilesToLink } from '../../services/DirectusServices/directusHelpers';
import { Types } from '../../models/DirectusSchema';

const sanctionsOptions = [
  { label: "Запрет допуска", value: "Запрет допуска" },
  { label: "Изъять пропуск", value: "Изъять пропуск" },
] as const;

const SelectSanction = ({ setValue, control, errors }) => {
  const firstRender = useRef(true);

  useEffect(() => {
    if (firstRender.current === true) {
      setValue('sanction', sanctionsOptions[0]);
      firstRender.current = false;
    }
  }, [setValue])


  return (
    <>
      <label htmlFor={`verify-adding-to-bl-sanction`}>Санкция</label>
      <Controller
        control={control}
        name={`sanction`}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            ref={ref}
            onChange={(e) => { onChange(e); }}
            onBlur={() => { onBlur() }}
            options={sanctionsOptions}
            isSearchable={false}
            classNamePrefix="select"
            id={'verify-adding-to-bl-sanction'}
            value={value}
            captureMenuScroll={true}
            closeMenuOnScroll={true}
          />
        )}
        rules={{ required: "Выберите из списка" }}
      />
      <ErrorMessage
        errors={errors}
        name={`brand`}
        render={({ message }) => <span className="errorMessage">{message}</span>}
      />
    </>
  );
}

interface AddToBlacklistDialogProps {
  siteSettings: SiteSettings;
  closeDialogFunction: (any?: any) => any;
}

export interface NewBlacklistEntryFieldValues {
  files: DirectusFilesToLink;
  relatedArea: SelectOption<number>;
  number: string;
  brand: CreatableSelectOption<number>;
  sanction: SelectOption<string>;
  comment: string;
}

const AddToBlacklistDialog = (
  { siteSettings, closeDialogFunction }: AddToBlacklistDialogProps
) => {
  const [proceed, setProceed] = useState<ProceedStatus>("idle");
  const [formState, setFormState] = useState<FormState>('readyForInput');
  const [uploadedFilesUUIDs, setUploadedFilesUUIDs] = useState<Types.UUID[] | null>(undefined);
  const [isUploading, setIsUploading] = useState(false);
  const errorData = useRef<ErrorData | null>(null);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<NewBlacklistEntryFieldValues>({
    criteriaMode: "all",
  });

  const [
    areDestinationOptionsLoading,
    allDestinationOptionsRef,
    destinationOptions,
    setDestinationOptions,
  ] = useSelectOptions({
    siteSettings,
    optionName: "destinationOptions",
  })

  const [
    areBrandsLoading,
    allBrandsOptionsRef,
    _brandsOptions,
    _setBrandsOptions,
  ] = useSelectOptions({
    siteSettings,
    optionName: "brandsOptions",
  })

  const saveFormData = useCallback(async () => {
    window.scrollTo(0, 0);
    const data = getValues();

    if (uploadedFilesUUIDs?.length > 0) {
      data.files = uploadedFilesUUIDs.map(uuid => ({ directus_files_id: uuid }));
    }

    try {
      await createEntryInBlacklist({
        entryData: data
      });

      try {
        const notificationData = {
          brand_name: getRidOfAliases(data.brand.label),
          plate_number: data.number,
          area_name: data.relatedArea?.label,
          sanction: data.sanction.label,
          comment: data?.comment,
          files: data.files,
        }
        
        const res = await notifyTelegramGroup({
          target: "security-chat",
          event: "blacklist-added",
          data: notificationData 
        });
        
        if (!res.ok) {
          throw new Error("Уведомление в чат доставлено");
        }
      } catch (err) {
        window.alert("Машина добавлена в ЧС, но уведомление в чат не доставлено!")
      }

      closeDialogFunction(false);
      setFormState('success');
    } catch (err) {
      console.log("error in saveFormData", err)
      errorData.current = err;
      setFormState('errorOnSubmit');
    }
  }, [uploadedFilesUUIDs, getValues, closeDialogFunction]);

  useEffect(() => {
    if (isUploading) {
      if (proceed === "success") {
        saveFormData();
        setIsUploading(false);
      } else if (proceed === "failed") {
        setFormState("errorOnSubmit");
        setIsUploading(false);
      }
    }
  }, [proceed, isUploading, saveFormData]);

  const onDestinationsInputChange = (value: string, { action }) => {
    onSelectInputChange({
      value,
      currentState: destinationOptions,
      setCurrentState: setDestinationOptions,
      allOptionsRef: allDestinationOptionsRef,
      action
    })
  }

  const onSubmit = async () => {
    try {
      setFormState("sendingData");
      setIsUploading(true);
      setProceed("start") // if files chosen - upload them;
    } catch (e) {
      console.log("error in onSubmit", e)
      errorData.current = e;
      setFormState("errorOnSubmit");
    }
  }

  const handleFilesUpload = (_e: React.ChangeEvent<HTMLInputElement>) => {
    // if any files chosen — form value should be changed;
    if (_e) {
      setValue('files', []);
    }
  }

  const onError = () => {
    window.scrollTo(0, 0)
  }


  return (
    <div id='blacklist-confirmation-container'>
      {
        formState !== "errorOnSubmit" &&
        <h1>Внести в <br />Чёрный список</h1>
      }
      <LoadingIcon addClassName={`${formState === "sendingData" ? "" : "invisible"}`} />
      <form
        id='addToBlacklistForm'
        onSubmit={handleSubmit(onSubmit, onError)}
        className={`${formState === "readyForInput" || formState === "success" ? "visible" : ""}`}
      >
        <div className='boxProperty'>
          <label htmlFor={`relatedArea`}>Связанный объект</label>
          <Controller
            control={control}
            name={`relatedArea`}
            render={({ field: { onBlur, value, ref, onChange } }) => (
              <Select
                key={`relatedArea`}
                id={`relatedArea`}
                ref={ref}
                isClearable
                onChange={(e) => { onChange(e); }}
                onBlur={() => { onBlur() }}
                options={destinationOptions}
                isLoading={areDestinationOptionsLoading}
                loadingMessage={() => { return "Идёт загрузка..." }}
                noOptionsMessage={() => { return "Нет опций" }}
                placeholder=""
                isSearchable={true}
                filterOption={createFilter(reasonableFilterConfig)}
                onInputChange={onDestinationsInputChange}
                value={value}
                classNamePrefix="select"
                defaultValue={undefined}
              />
            )}
          />
        </div>
        <div className='boxProperty'>
          <label htmlFor={`verify-adding-to-bl-number`}>Номер</label>
          <input
            id={`verify-adding-to-bl-number`}
            className='classyInput'
            defaultValue={null}
            autoComplete='off'
            spellCheck={false}
            {...register(`number`, { required: "Если номера нет, так и напишите" })}
          />
          <ErrorMessage
            errors={errors}
            name={`number`}
            render={({ message }) => <span className="errorMessage">{message}</span>}
          />
        </div>
        <div className='boxProperty'>
          <label htmlFor={`verify-adding-to-bl-brand`}>Марка</label>
          <Controller
            control={control}
            name={`brand`}
            render={({ field: { onChange, ref } }) => (
              <CreatableBrandSelect
                allOptionsRef={allBrandsOptionsRef}
                forwardRef={ref}
                onChangeFunction={onChange}
                isLoading={areBrandsLoading}
                localStorageName={"brandsOptions=all"}
                isDisabled={false}
              />
            )}
            rules={{ required: "Выберите из списка" }}
          />
          <ErrorMessage
            errors={errors}
            name={`brand`}
            render={({ message }) => <span className="errorMessage">{message}</span>}
          />
        </div>
        <div className='boxProperty'>
          <SelectSanction
            setValue={setValue}
            control={control}
            errors={errors}
          />
        </div>
        <div className='boxProperty'>
          <label
            htmlFor={`verify-adding-to-bl-comment`}
          >
            Комментарий
          </label>
          <textarea
            id={`verify-adding-to-bl-comment`}
            className='classyInput'
            defaultValue={null}
            spellCheck={true}
            {...register(`comment`)}
          />
        </div>
        <div className='boxProperty'>
          <FileInput
            inputID={`verify-adding-to-bl-file`}
            ButtonAdditionalClassName={`file-upload-label`}
            targetFolder={'blacklist'}
            proceed={proceed}
            setProceed={setProceed}
            setUploadedFilesUUIDs={setUploadedFilesUUIDs}
            handleInput={handleFilesUpload}
          />
        </div>
        <button
          disabled={false}
          className='button submit-to-blacklist'
          type="submit"
        >
          Добавить
        </button>
      </form>
      {
        formState === "errorOnSubmit" &&
        <div id="error-on-submit-container" className='visible'>
          <p>Добавить не удалось. Пожалуйста, попробуйте ещё раз.</p>
          {errorData.current && 'status' in errorData.current && 'statusText' in errorData.current ?
            <>
              <p>Код ошибки: <code>{errorData.current?.status}</code></p>
              <p>Текст ошибки: <code>{errorData.current?.statusText}</code></p>
            </>
            :
            // @ts-expect-error untill no type guards are implemented
            errorData.current?.errors.length > 0  ?
            <>
            {
              // @ts-expect-error untill no type guards are implemented
              errorData.current?.errors.map((err, index) => (
                <p key={`error-on-submit-container-error-${index}`}><code>{err?.extensions?.code} | { err?.message }</code> </p>
              ))
            }
            </>
            :
            <>
              <p>Текст ошибки: <code>{errorData.current?.name || 'Unknown'} | {errorData.current?.message || 'No message'}</code></p>
            </>
          }
        </div>
      }
    </div>
  );
}

export default AddToBlacklistDialog;
