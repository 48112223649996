import { useContext, useEffect, useState } from "react";
import { applyTimezone, getRidOfAliases } from "../../services/littleHelpers";
import { updateValues, UpdateValuesProps } from "../../services/TableDataMutations/updateValues";
import { FileInput, ProceedStatus } from "../commonUI/inputs/FileInput";
import { MainContext } from "../MainContext";
import { SimpleInTableCheckbox, wasEditedInLast24hours } from "../inputElements";
import { Cell } from "react-table";
import { JournalRowData } from "../../services/DataProviders/journal";
import { Types } from "../../models/DirectusSchema";
import { notifyTelegramGroup } from "../../services/LandshaftRobot/notifications";
import ModalDialog from "../modals/ModalDialog";


interface FileInputWithParkingHandlingProps {
  targetRowId: number;
  setReadyToApply: React.Dispatch<React.SetStateAction<boolean>>;
  setParkingStartTime: React.Dispatch<React.SetStateAction<string>>;
  setUploadedFilesUUIDs: React.Dispatch<React.SetStateAction<Types.UUID[]>>;
  proceed: ProceedStatus;
  setProceed: React.Dispatch<React.SetStateAction<ProceedStatus>>;
}

export const FileInputWithParkingHandling = ({
  targetRowId,
  setReadyToApply,
  setParkingStartTime,
  setUploadedFilesUUIDs,
  proceed,
  setProceed
}: FileInputWithParkingHandlingProps) => {

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = new Date(e.target.files[0].lastModified)
    setParkingStartTime(applyTimezone(date.toISOString(), 3));
    setReadyToApply(true)
  }

  return (
    <>
      <FileInput
        ButtonAdditionalClassName={`file-upload-label`}
        inputID={`${targetRowId}-upload-photo`}
        handleInput={handleInput}
        targetFolder="journal"
        proceed={proceed}
        setProceed={setProceed}
        setUploadedFilesUUIDs={setUploadedFilesUUIDs}
      />
    </>
  );
}

export interface ParkingConfirmatonContentProps {
  cell: Cell<JournalRowData>;
  doConfirmation: (fileUUID: Types.UUID[], setProceed: React.Dispatch<React.SetStateAction<ProceedStatus>>) => Promise<void>;
  cancelParking: () => void;
}

const ParkingConfirmatonContent = ({ cell, doConfirmation, cancelParking }: ParkingConfirmatonContentProps) => {
  const [readyToApply, setReadyToApply] = useState(false);
  const [uploadedFilesUUIDs, setUploadedFilesUUIDs] = useState<Types.UUID[]>();
  const [proceed, setProceed] = useState<ProceedStatus>("idle");
  const [parkingStartTime, setParkingStartTime] = useState<string | undefined>();

  useEffect(() => {
    if (parkingStartTime) {
      const textArea = document.getElementById(`${cell.row.original.origin_ID}-verify-comment`) as HTMLInputElement;
      const cleanText = textArea.value.replace(/\s\[начало парковки:\s.+\]/, "");
      textArea.value = cleanText + ` [начало парковки: ${parkingStartTime}]`
    }
  }, [cell.row.original.origin_ID, parkingStartTime])

  useEffect(() => {
    if (proceed === "success") {
      window.setTimeout(() => {
        doConfirmation(uploadedFilesUUIDs, setProceed)
      }, 1500)
    } else if (proceed === "failed") {
      window.setTimeout(() => {
        cancelParking();
      }, 4000)
    }
  }, [cancelParking, doConfirmation, proceed, uploadedFilesUUIDs]);

  const handleProceedParking = async () => {
    setProceed("start");
    setReadyToApply(false);
  }

  const proceedStatusText: Record<ProceedStatus, string> = {
    "idle": "Сообщить о начале стоянки",
    "start": "Идёт загрузка",
    "success": "Парковка отмечена, отправляю уведомление в чат...",
    "reportDelivered": "Уведомление доставлено",
    "failed": "Что-то пошло не так, попробуйте ещё раз",
  }

  return (
    <>
      <p className='confiration-message'>
        Отметить платную парковку для {getRidOfAliases(cell.row.original?.brand?.label)} {cell.row.original?.number}?
      </p>
      <div className='boxProperty'>
        <label htmlFor={`${cell.row.original.origin_ID}-verify-comment`}>Комментарий</label>
        <textarea
          id={`${cell.row.original.origin_ID}-verify-comment`}
          className='classyInput'
          autoComplete="off"
          defaultValue={cell.row.original.comment}
          spellCheck={false}
        />
      </div>
      <div className='boxProperty'>
        <FileInputWithParkingHandling
          targetRowId={cell.row.original.origin_ID}
          setReadyToApply={setReadyToApply}
          proceed={proceed}
          setProceed={setProceed}
          setUploadedFilesUUIDs={setUploadedFilesUUIDs}
          setParkingStartTime={setParkingStartTime}
        />
      </div>
      <div className='boxProperty'>
        <button
          className='button'
          disabled={!readyToApply}
          onClick={handleProceedParking}
        >
          {proceedStatusText[proceed]}
        </button>
      </div>
    </>
  );
}

interface ParkingHandlerProps {
  cell: Cell<JournalRowData>;
  freezeRef: React.MutableRefObject<boolean>;
  justAlteredRowsRef: React.MutableRefObject<Array<string | number>>;
  SimpleInTableCheckbox: typeof SimpleInTableCheckbox;
}

export const ParkingHandler = ({ SimpleInTableCheckbox, cell, justAlteredRowsRef, freezeRef }: ParkingHandlerProps) => {
  const { userData } = useContext(MainContext);
  const [confirmationRequired, setConfirmationRequired] = useState(false);
  const [confirmationSubject, setСonfirmationSubject] = useState(<code>error 💩</code>);
  /**
   * Parking data is stored in two columns:
   * first with 'parking' select "Парковка в кредит" or "Парковка оплачена",
   * second with loose 'comment' containing duration
   */

  useEffect(() => {
    if (confirmationRequired) {
      freezeRef.current = true;
    } else {
      freezeRef.current = false;
    }
  }, [confirmationRequired]);

  const cancelParking = () => {
    const thisCheckbox = document.getElementById(`${cell.row.original.origin_ID}-checkbox-${cell?.column?.id}`) as HTMLInputElement;
    thisCheckbox.checked = false;
    setConfirmationRequired(false)
  }

  const isMarkingParkingAllowed = () => {
    if (cell.row.original.entered !== true) {
      return false
    }

    if (userData.roles.includes("patrol1") || userData.roles.includes('patrol2')) {
      return wasEditedInLast24hours(cell.row.original.updated_at, 168);
    } else if (userData.roles.includes("admin")) {
      return true;
    }

    return false
  }

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {

    if (!e.target.checked) {
      const thisRowElement = document.querySelector(`#journal-entry-id-${cell.row.original.origin_ID}`);

      const commentInputElement = thisRowElement.querySelector(".cell-in-comment textarea") as HTMLInputElement;
      const currentComment = commentInputElement ? commentInputElement?.value : cell.row.original.comment;

      let clearedComment: string;

      if (currentComment) {
        clearedComment = currentComment?.replace(/\s+?\[нач.+\]/g, "");
      } else {
        clearedComment = currentComment;
      }

      updateValues({
        values: [
          {
            cell,
            newValue: null,
            targetProperty: "parking",
          },
          {
            cell,
            newValue: null,
            targetProperty: "useful_photo",
          },
          {
            cell,
            newValue: clearedComment,
            targetProperty: "comment",
          }
        ],
      });

      justAlteredRowsRef.current.push(`${cell.row.original.origin_ID}`)

      const activeElement = document.activeElement as HTMLInputElement;
      activeElement?.blur();

      return;
    }

    const doConfirmation = async (fileUUIDs: Types.UUID[], setProceed: React.Dispatch<React.SetStateAction<ProceedStatus>>) => {
      const updatedComment = (document.getElementById(`${cell.row.original.origin_ID}-verify-comment`) as HTMLInputElement).value;

      const valuesToSet: UpdateValuesProps["values"] = [
        {
          cell,
          newValue: updatedComment,
          targetProperty: "comment",
        },
        {
          cell,
          newValue: "Парковка в кредит",
          targetProperty: "parking",
        }
      ]

      if (fileUUIDs) {
        valuesToSet.push(
          {
            cell,
            newValue: fileUUIDs[0],
            targetProperty: "photo",
          }
        )
      }

      await updateValues({
        values: valuesToSet,
      });

      justAlteredRowsRef.current.push(`${cell.row.original.origin_ID}`)

      const activeElement = document.activeElement as HTMLInputElement;
      activeElement?.blur();

      try {
        const res = await notifyTelegramGroup({
          event: "parking-start",
          target: "security-chat",
          data: {
            area_name: getRidOfAliases(cell.row.original.dest[0].area_name),
            brand_name: getRidOfAliases(cell.row.original.brand.label),
            plate_number: cell.row.original.number,
            file: fileUUIDs[0],
          }
        })

        if (res.ok) {
          setProceed("reportDelivered");
          window.setTimeout(() => {
            setConfirmationRequired(false)
          }, 2500);
        } else {
          throw new Error("Error sending notification to security chat");
        }

      } catch (err) {
        console.error(err);
        window.alert("Парковка отмечена, но уведомление в чат не доставлено!")
        setConfirmationRequired(false)
      }
    }

    setСonfirmationSubject(
      <ParkingConfirmatonContent
        cell={cell}
        doConfirmation={doConfirmation}
        cancelParking={cancelParking}
      />
    );
    setConfirmationRequired(true);
  }

  return (
    <>
      <ModalDialog
        isOpen={confirmationRequired}
        exitFunction={cancelParking}
      >
        <h1>Требуется подтверждение</h1>
        {confirmationSubject}
      </ModalDialog>
      <SimpleInTableCheckbox
        changeHandler={onCheckboxChange}
        cell={cell}
        justAlteredRowsRef={justAlteredRowsRef}
        disabled={!isMarkingParkingAllowed()}
        isChecked={!!cell.value}
        helperText={cell.value}
      />
    </>
  );
}
