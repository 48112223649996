import { 
    fetchWithTimeout, 
    responseStreamReader, 
} from "./littleHelpers"
// import { notifyAboutDangerousAction } from "./LandshaftRobot/notifications"

/**
 * since v0.90 NocoDB REST API path looks like
 * {domain}/api/v1/db/data/{orgs}/{projectName}/{tableName} 
 * To use bulk endpoints now this base url shoud be stored in
 * two .env variables:
    * {orgs} and {projectName} are stored in .env as
    * process.env.REACT_APP_ORG_N_PROJECT_PATH
    *                +
    * `{domain}/api/v1/db/data/` stored as 
    * process.env.REACT_APP_BASE_DB_URL and process.env.REACT_APP_BASE_DB_WEB_URL
 */

const APIrecordsPerQueryLimit = 100;

const journalViewEndpoint = 'journal' //'md_dijbwuf86a23w6'
const journalEndpoint = 'journal' // 'md_dijbwuf86a23w6'
const brandsEndpoint = 'brands'
const blacklistEndpoint =  'md_cyty6tsesdqfc4' //'blacklist'
const depositsEndpoint = 'deposits'
const pedestriansEndpoint = 'pedestrians'
const vehiclesEndpoint = "md_cghkq5wf91jn25" // 'local_vehicles'
const contactsEndpoint = 'contacts_on_objects'
// const journal_m2m_lands_Endpoint = 'm2m_journal_lands_n_objects';


export const chooseEndpoint = (source) => {
    let targetEndpoint;

    if (source === "journal" || source === "debt" || source === "reports" || source === 'dezhJournal') {
        targetEndpoint = journalViewEndpoint;
    } else if (source === "blacklist") {
        targetEndpoint = blacklistEndpoint;
    } else if (source === "deposits") {
        targetEndpoint = depositsEndpoint;
    } else if (source === "localVehicles") {
        targetEndpoint = vehiclesEndpoint;
    } else if (source === "localPedestrians") {
        targetEndpoint = pedestriansEndpoint;
    } else if (source === "allBrands") {
        targetEndpoint = brandsEndpoint;
    } else if (source === "contacts") {
        targetEndpoint = contactsEndpoint;
    }

    return targetEndpoint;

}

const logAbortError = (err, actionName = "") => {
                
    if (err.name === 'AbortError') {
        console.log(`%c${actionName} aborted`, "color:gray");
    } else {
        console.log(`%c${actionName} failed bacause of the following error:`, "color:gray");
        console.error(err)
    }

}

export const get_BASE_DB_URL = (queringData = true) => {

    if (window.location.origin === "https://service.landshaft.life"  || window.location.origin === "https://localhost:3000" ) {
        let base_url = process.env.REACT_APP_BASE_DB_URL;

        if (!queringData) {
            return base_url.replace(/\/api.+$/, "")
        } else {
            return base_url
        } 

    } else { // if (window.location.origin === "https://localhost:3000") {
      // !WARN: this is fairly depricated and should be removed on this file migration to TS
      let base_url = process.env.REACT_APP_RESERVE_DB_URL;
        
        if (!queringData) {
            return base_url.replace(/\/api.+$/, "")
        } else {
            return base_url;
        } 
       
    }
}

export const makeRequest = ({
    auth_token, 
    method = "GET", 
    body = null, 
    timeout,
}) => {
    let requestObj = {
        timeout: timeout,
        method: method,
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        //   "xc-gui": true,
        }, 
    }

    if (auth_token.includes("."))  {
        requestObj.headers["xc-auth"] = auth_token;
    } else {
        requestObj.headers["xc-token"] = auth_token;
    }

    if (body !== null) {
        requestObj.body = JSON.stringify(body);
    }
    // console.log("makeRequest requestObj", requestObj)
    return requestObj;
}

export const makeFilterString = (filters = [], currentViewFilterCondition) => {
    let filterString = "";
    let deepFiltersIDs = ["dest", "object", "brand", "common_brand", "origin_ID"]

    if (filters.length > 0) {
        filterString = 'where='
        filters.forEach((f, index) => {
            let comparisonOperator = "like";
            let encodedFilter;

            if (f?.comparisonOperator ) {
                comparisonOperator = f?.comparisonOperator;
                encodedFilter = `${f.value}`;
            } else {
                comparisonOperator = "like";
                // encodedFilter = encodeURI(`%${f.value}%`);
                encodedFilter = encodeURI(`${f.value}`);
            }

            if (deepFiltersIDs.find(item => item === f.id) === undefined) {
                filterString += `${index !== 0 ? "~and" : ""}(${f.id},${comparisonOperator},${encodedFilter})`
            } else {
                // let encodedFilter = encodeURI(`%${f.value}%`);
                let encodedFilter = encodeURI(`${f.value}`);
                let columnToFilterBy;
                
                if (f.id === "dest" || f.id === "object") {
                    columnToFilterBy = "area_name";
                } else if (f.id === "common_brand" ) {
                    columnToFilterBy = "brand";
                } else if (f.id === "brand" ) {
                    columnToFilterBy = "brand_name";
                } else if (f.id === "origin_ID" ) {
                    columnToFilterBy = "Id";
                    comparisonOperator = "eq";
                    encodedFilter = f.value;
                }

                filterString += `${index !== 0 ? "~and" : ""}(${columnToFilterBy},${comparisonOperator},${encodedFilter})`
            }
        })
        if (currentViewFilterCondition) {
            filterString += `~and${currentViewFilterCondition}`
        }
    } else if (currentViewFilterCondition) {
        filterString = `where=${currentViewFilterCondition}`
    }

    return filterString;
}

const makeQueryString = ({
    sortString,
    limit,
    offset, 
    filterString, 
    fields,
    relatedFields,
    }) => {

    let query; 
    let relatedFieldsQuery = "";

    if (!relatedFields[0]?.fieldName) {
        relatedFieldsQuery = ""

        relatedFields.forEach(f => {
            let targetRelationFieldName = Object.keys(f)[0];
            relatedFieldsQuery += `&nested[${targetRelationFieldName}][fields]=${f[targetRelationFieldName].join()}`
        })

    }

    query = `${sortString ? "?sort=" + sortString : "?sort=-created_at"}` +
    `&limit=${limit}${offset > 0 ? "&offset=" + offset : "" }` +
    `${filterString !== "" ? "&" + filterString : ""}` +
    `${fields !== "" ? "&fields=" + fields : ""}` +
    `${relatedFields ? relatedFieldsQuery : ""}`

    return query;
}

export const requestRowsData = async ({
    auth_token,
    endpoint,
    recordsLimit = APIrecordsPerQueryLimit,
    pageNo = 0,
    sortString = "",
    filterString = "",
    fields = "", 
    relatedFields = [ { fieldName: [] } ],
    controller,
 }) => {

    let responseData = [];
    let query = "";

    let makeQueryStringOptionsArgument = {
        sortString: sortString,
        limit: recordsLimit,
        offset: pageNo * recordsLimit,
        filterString: filterString,
        fields: fields,
        relatedFields: relatedFields,
    }
    
    query = makeQueryString(makeQueryStringOptionsArgument)

    // if( endpoint.includes('local') || true ) {
    //     console.log(
    //         "requestRowsData called with:",
    //         {
    //             auth_token,
    //             endpoint,
    //             recordsLimit,
    //             pageNo,
    //             sortString,
    //             filterString,
    //             fields, 
    //             relatedFields,
    //             controller,
    //          }
    //     )

    //     console.log("fetching %conce: ", "color:green", `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${endpoint}${query}` )
    // }

    const response = await fetchWithTimeout(
        `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${endpoint}${query}`,
        makeRequest({auth_token: auth_token, timeout: 160000}),
        controller
    ).catch((err) => {
        logAbortError(err, `requestRowsData call on ${endpoint}`)
    });


    if (response && controller?.signal?.aborted !== true) {
        let responseBody = await responseStreamReader(response);
        let tempResponseData = JSON.parse(responseBody);
        responseData = tempResponseData;
    } else {
        return
    }

    if (responseData.list.length > Math.min(responseData.pageInfo.pageSize, responseData.pageInfo.totalRows)) { 
        
        let requieredQueries = Math.ceil((responseData.pageInfo.totalRows - responseData.list.length) / Number(responseData.pageInfo.pageSize));

        for (let i = 0; i < requieredQueries; i++) {
            makeQueryStringOptionsArgument.limit = recordsLimit;
            makeQueryStringOptionsArgument.offset = (pageNo + i) * recordsLimit;
            query = makeQueryString(makeQueryStringOptionsArgument);
            
            // console.log("fetching %cin-iteration: ", "color:red", `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${endpoint}${query}`)
            
            const response = await fetchWithTimeout(
                `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${endpoint}${query}`,
                makeRequest({auth_token: auth_token}),
                controller
            ).catch((err) => {
                logAbortError(err, `requestRowsData call №${i} on ${endpoint}`)
                
            });

            if (response && controller?.signal?.aborted === false) {
                let responseBody = await responseStreamReader(response);
                let tempResponseData = JSON.parse(responseBody);
                // console.log('responseData', responseData)
                responseData.list = responseData.list.concat(tempResponseData.list);
            } else {
                return
            }

        }

    }

    return responseData;

}

export const confirmDataWasUpdated = async ({
    nocodb_auth, 
    source, 
    sortBy = "-updated_at",
    fieldToCompareBy = "updated_at",
    lastKnownUpdateTimeString, 
    knownTotalCount,
    filters,
    currentViewFilterCondition,
    recordsLimit,
    pageIndex,
    controller,
    timeout = 160000,
}) => {
    // console.log("confirmDataWasUpdated called with", 
    // {
    //     nocodb_auth, 
    //     source, 
    //     sortBy,
    //     fieldToCompareBy,
    //     lastKnownUpdateTimeString, 
    //     knownTotalCount,
    //     filters,
    //     currentViewFilterCondition,
    //     recordsLimit,
    //     pageIndex,
    //     controller,
    //     timeout,
    // }
    // )

    let targetEndpoint, responseBody, responseBodyObject;
    let filterString = makeFilterString(filters, currentViewFilterCondition ? currentViewFilterCondition : undefined);

    targetEndpoint = chooseEndpoint(source);
    
    let offset = 
        (recordsLimit && pageIndex) && (recordsLimit * pageIndex !== 0) ?
            `offset=${recordsLimit * pageIndex}&` : "";
    
    // console.log(
    //     `update call on %c${source}%c, fetching:`, `color:${source === "journal" ? "red" : source === "blacklist" ? "purple" : "green" }`, "color:initial",
    //     `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${targetEndpoint}?limit=1&${offset}sort=${sortBy}&fields=${fieldToCompareBy},Id&${filterString}`,
    // );

            
    const response = await fetchWithTimeout(
        `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${targetEndpoint}?limit=1&${offset}sort=${sortBy}&fields=Id,${fieldToCompareBy}&${filterString}`,
        makeRequest({auth_token: nocodb_auth, timeout: timeout}),
        controller
    ).catch((err) => {
        logAbortError(err, `confirmDataWasUpdated call on ${source}`)
    });


    if (response) {
        try {
            responseBody = await responseStreamReader(response);
            responseBodyObject = await JSON.parse(responseBody);
            let localForComparison = lastKnownUpdateTimeString;
            let remoteForComparison = responseBodyObject.list[0]?.[`${fieldToCompareBy}`];
            
            const roundUtcTimeString= (timeString) => {

                return timeString && timeString.replace(/\.\d{3}Z$/, "")
            }

            if (fieldToCompareBy === "updated_at") {
                localForComparison = roundUtcTimeString(localForComparison);
                remoteForComparison = roundUtcTimeString(remoteForComparison);
            }

            // if (source === "journal") {
            //     console.log("localForComparison remoteForComparison", localForComparison, remoteForComparison)
            // }


            if (
                localForComparison === remoteForComparison
                    &&
                `${responseBodyObject.pageInfo.totalRows}` === `${knownTotalCount}`
            ) {

                return {
                    id: responseBodyObject.list[0]?.Id,
                    fresh: false
                }

            } else {
                
                return {
                    id: responseBodyObject.list[0]?.Id,
                    fresh: true
                };
                
            }

        } catch (err) {
            console.error(err)
            console.log("unrecognized error above got: response, responseBody", response, responseBody)
            return { fresh: false };
        }
        
    } else {
        // return { fresh: false };
        return undefined;
    }

}

export const updateValues = async ({
    auth_token = "",
    targetEndpoint = journalViewEndpoint,
    values = [
        {
            cell: undefined, 
            newValue: undefined, // will replace existing data
            addNewValue: undefined, // will concat new to existing
            targetProperty: "",
            rowID: "",
        },
    ],
}
) => {
    console.log('updateValues called with targetEndpoint', targetEndpoint)

    /**
     * updateValues function can be used to set any value in
     * NocoDB backend, cell argument can be replaced with 
     * targetProperty + rowID. If invalid arguments are 
     * passed error is thrown by below lines.
     */
    
    if (auth_token === "") {
        throw new Error("updateValues function requires auth_token")
    } 

    let responseOnUpdate, targetColumn, targetRowID;
    const newData = {};

    for (let i = 0 ; i < values.length; i ++) {

        if (
            ( values[i].cell?.value === undefined && (values[i].newValue === undefined && values[i].addNewValue === undefined) ) 
                && 
            !( (!!values[i].newValue || !!values[i].addNewValue) && !!values[i].rowID && !!values[i].targetProperty )
        ) {
            return new Error("updateValues function requires passing `cell` object with newValue, or explicitly set 'currentValue && newValue && rowID && targetProperty'")
        }

        if ( 
            (`${values[i].cell?.value}` !== `${values[i].newValue}`) 
                || 
            (values[i].cell?.value === null && values[i].newValue !== "") // to avoid unnessesary updates on null values
        ) {

            targetColumn = values[i].targetProperty ? values[i].targetProperty : values[i].cell?.column?.id;
            targetRowID = values[i].cell?.row?.values?.origin_ID || values[i].rowID;

            if (values[i].newValue === undefined && values[i].addNewValue === undefined) {

                newData[`${targetColumn}`] = null;            
                
            } else if (values[i].newValue !== undefined) {

                newData[`${targetColumn}`] = values[i].newValue;

            } else if (values[i].addNewValue !== undefined) {

                let response = await fetchWithTimeout(
                    `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${targetEndpoint}/${targetRowID}?fields=Id,${targetColumn}`,
                    makeRequest({auth_token: auth_token}),
                ).catch((err) => {
                    logAbortError(err, `updateValues call to targetEndpoint: ${targetEndpoint}, targetRowID: ${targetRowID}, targetColumn: ${targetColumn}`)
                    return err;
                });
                
                let responseBody = await responseStreamReader(response);
                let responseBodyObject = JSON.parse(responseBody);
                // console.log(`responseBodyObject on geting ${targetColumn} at ID${targetRowID}`, responseBodyObject)
                // console.log(`values[i].addNewValue`, values[i].addNewValue)

                if (responseBodyObject[`${targetColumn}`] === null) {

                newData[`${targetColumn}`] = JSON.stringify(values[i].addNewValue);

                } else {

                    let existingData = JSON.parse(responseBodyObject[`${targetColumn}`]);
                    newData[`${targetColumn}`] = JSON.stringify(existingData.concat(values[i].addNewValue));

                }
            }


        } else {
            console.log('updateValues on targetEndpoint is unnecessary', targetEndpoint)
        }

    }

    // console.log(`updateValues going to set newData`, newData)
    
    responseOnUpdate = await fetchWithTimeout(
        `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${targetEndpoint}/${targetRowID}`,
        makeRequest({
            auth_token: auth_token, 
            method: "PATCH", 
            body: newData
        }),
    ).catch((err) => {
        logAbortError(err, `updateValues call to targetEndpoint: ${targetEndpoint}, targetRowID: ${targetRowID}, targetColumn: ${targetColumn}`)
        return err;
    });

    let responseOnUpdateBody = await responseStreamReader(responseOnUpdate)
    // let responseOnUpdateBodyObject = JSON.parse(responseOnUpdateBody);

    if (responseOnUpdateBody === "") {
        return new Error(`Updating targetRowID=${targetRowID} with newData=${JSON.stringify(newData)} failed. Probably cause of wrong input.`) 
    }
    
    if (responseOnUpdate?.status !== 200) {
        return new Error(`Updating targetRowID=${targetRowID} with newData=${JSON.stringify(newData)} failed ${responseOnUpdate?.status ? "with status=" + responseOnUpdate?.status : ""}`) 
    }
    
    console.log(`value(s) updated at targetEndpoint: ${targetEndpoint}, targetRowID: ${targetRowID}, targetColumn: ${targetColumn}`)
    return {status: 200}

}

export const uploadFiles = async (auth_token, path, files) => {
    let formData = new FormData();
    
    for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
    }

    formData.append('json', JSON.stringify({"api":"xcAttachmentUpload","project_id":"more_then_land_z6gg","dbAlias":"db","args":{}}));
    
    let requestObj = {
        timeout: 60000,
        method: "POST",
        headers: {}, 
        body: formData,
    }

    if (auth_token.includes("."))  {
        requestObj.headers["xc-auth"] = auth_token;
    } else {
        requestObj.headers["xc-token"] = auth_token;
    }

    const responseOnUpdate = await fetchWithTimeout(
        `${get_BASE_DB_URL().replace("data", "storage")}upload?path=${path}`,
        requestObj,
    ).catch((err) => {
        logAbortError(err, `uploadFiles to ${path}`)
        return 0;
    });

    if (responseOnUpdate.status !== 200) {
        return
    }

    let jsonResponse = await responseOnUpdate.json();

    return jsonResponse;

}

export const deleteJournalRow = async (nocodb_auth, cell, deletingRowsRef, justDeletedRowsRef, tableDataRef, setRowExists) => {
    const buttonElement = document.getElementById(`journal-delete-row-${cell.row.id}`);
    const row = cell.row;
    let origin_ID = row.values.origin_ID;

    const deleteRowForReal = async ( nocodb_auth ) => {
        let relationsToDelete = cell.row?.original?.dest;
        let responseOnDelete = {status: 200};

        if (relationsToDelete?.length > 0) {
            
            for (let rel of relationsToDelete) {

                responseOnDelete = await fetchWithTimeout(
                    `${get_BASE_DB_URL()}/${process.env.REACT_APP_ORG_N_PROJECT_PATH}${journalEndpoint}/${origin_ID}/mm/journal_m2m_lands_n_objects/${rel.id}`, 
                    makeRequest({
                        auth_token: nocodb_auth,
                        method: "DELETE", 
                    })
                )

            }

        }
    
        if (responseOnDelete.status === 200) {
            /**
             * only below code is required if relations were created 
             * to cascade after row deletion, as done in production,
             * but till local environment rework other code remains
             */
            const responceOnJournalEntryDelete =  await fetchWithTimeout (
                `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${journalViewEndpoint}/${origin_ID}`,
                makeRequest({
                    auth_token: nocodb_auth,
                    method: "DELETE", 
                })
            )

            if (responceOnJournalEntryDelete.status === 200) {
                tableDataRef.current = tableDataRef.current.filter(el => {

                    if ( deletingRowsRef.current.includes(el.origin_ID) ) {
                        deletingRowsRef.current = deletingRowsRef.current.filter(e => e !== el.origin_ID)
                        return false
                    } else {
                        return true
                    }

                })
                justDeletedRowsRef.current.push(origin_ID);
                setRowExists(false)

            } else {
                window.alert("Удалить запись не удалось, сообщите администратору")
            }
            /*  end-of-necessary-section */
        } else {
            window.alert("Удалить запись не удалось, сообщите администратору")
        }
    }

    if (!buttonElement.classList.contains("fallback")) {
        buttonElement.classList.add('fallback');
        deletingRowsRef.current.push(origin_ID);


        let timeout = window.setTimeout(() => {
    
            if(!buttonElement.classList.contains("fallback")) {
                clearTimeout(timeout);
                deletingRowsRef.current = deletingRowsRef.current.filter(e => {
                    if(e !== origin_ID) {
                        return true;
                    } else {
                        return false;
                    }
                });

            } else {
                deleteRowForReal(nocodb_auth);                
            }
        }, 3000)

        return null;
        
    } if (buttonElement.classList.contains("fallback")) {   
        buttonElement.classList.remove('fallback');

        deletingRowsRef.current = deletingRowsRef.current.filter(e => {
            if(e !== origin_ID) {
                return true;
            } else {
                return false;
            }
        });

    }
    
}

export const mergeJournalEntries = async (userData, itemsToMerge = {
    dest_ID: [1, 2, 3],
    origin_ID: 1,
}) => {

    let response;

    if (itemsToMerge?.length === 2) {

        let targetJournalEntry = itemsToMerge.find(el => el.origin_ID !== undefined)
        let destIdsToAdd = itemsToMerge.find(el => el.origin_ID === undefined).dest_ID;
        
        for (let id of destIdsToAdd) {
            
            if (!targetJournalEntry.dest_ID.includes(id)) {
                response = await fetchWithTimeout(
                    `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${journalEndpoint}/${targetJournalEntry.origin_ID}/mm/journal_m2m_lands_n_objects/${id}`, 
                    makeRequest({
                        auth_token: userData.nocodb_auth,
                        method: "POST",
                        timeout: 160000,
                    })
                )
            }

        }

        if (response === undefined) {

            return {
                status: 200,
                statusText: "Успешно обновлено"
            }

        }


    } else if (itemsToMerge?.length > 2) {
        let noNewItemsArray, itemToUpdate, newDestId, allDestinationsToSet; 
        let itemsToReplace = [];

        itemsToMerge.sort((a, b) => b.origin_ID - a.origin_ID); // 1) sort by desc Id

        noNewItemsArray = itemsToMerge.filter(item => {

            if (item.origin_ID !== undefined) {
                return true
            } else {
                newDestId = item.dest_ID[0];
                allDestinationsToSet = [].concat(newDestId)
                return false;
            }

        }) // 2) get new item data


        itemsToReplace = noNewItemsArray.reduce((previousValue, currentValue, currentIndex) => { 

            if (currentIndex === 0) {
                itemToUpdate = currentValue;
                return [];
            } else {
                return [...previousValue, currentValue]
            }

        }, []) // 3) get items to delete and which dest id's to set to remaining one

        // console.log("newDestId - ", newDestId, "itemsToReplace -", itemsToReplace, "itemToUpdate - ", itemToUpdate, "allDestinationsToSet - ", allDestinationsToSet)

        allDestinationsToSet = itemsToReplace.reduce((prev, current) => {

            let idsToPush = [];

            for (let id of current.dest_ID) {
                
                if (id !== newDestId && !prev.includes(id)) {
                    idsToPush.push(id)
                }

            }

            if (idsToPush.length > 0) {
                return [...prev, ...idsToPush]
            } else {
                return [...prev]
            }
            
        }, allDestinationsToSet) // 4) get rid of dest id's duplicates


        for (let dest of allDestinationsToSet.flat(Infinity)) {

            response = await fetchWithTimeout(
                `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${journalEndpoint}/${itemToUpdate.origin_ID}/mm/journal_m2m_lands_n_objects/${dest}`, 
                makeRequest({
                    auth_token: userData.nocodb_auth,
                    method: "POST",
                    timeout: 160000,
                })
            )

        } // 5) set all required dest id's to most recent journal entry from the lsit to merge

        for (let item of itemsToReplace) {

            response = await fetchWithTimeout (
                `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${journalEndpoint}/${item.origin_ID}`,
                makeRequest({
                    auth_token: userData.nocodb_auth,
                    method: "DELETE"
                })
            )

        } // 6) delete unnecessary entries.

    }


    if (response.status === 200) {

        return {
            status: 200,
            statusText: "Успешно обновлено"
        }

    } else {

        return {
            status: response?.status ? response.status : 500,
            statusText: response?.statusText ? response.statusText : "Неизвестная ошибка"
        }

    }

}

export const createJournalRows = async (userData, entriesData, createdBrandIdRef) => {
    const destination = entriesData.destination;
    const auth_token = userData.nocodb_auth;
    let entriesToCreate = [];

    for (let i = 0; i < entriesData.vehicles.length; i++) {
        let vehicle = entriesData.vehicles[i];

        let obj = {
            "number": vehicle.number,
            "category": vehicle.category.value,
            "credit": vehicle.debt,
            "daily": vehicle.daily,
            "entered": vehicle.entered,
            "comment": vehicle.comment,
            "created_by": vehicle?.created_by,
        }

        if (!!vehicle.entered) {
            obj.entered_at = new Date().toISOString(); 
            obj.entry_point = !!userData.roles.includes('kpp2') ? "КПП-2" : !!userData.roles.includes('kpp3') ? "КПП-3" : "КПП-1";
        }

        if (!vehicle.brand.__isNew__) {

            obj[`${process.env.REACT_APP_SYS_BRANDS_ID}`] = vehicle.brand.value;

        } else {

            const responseOnCreate = await fetchWithTimeout(
                `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${brandsEndpoint}`,
                makeRequest({
                    auth_token, 
                    method: "POST", 
                    body: {brand: vehicle.brand.label}
                })
            ).catch((err) => {
                console.error(err)
                return 0;
            });

            let responseOnCreateBody = await responseStreamReader(responseOnCreate);
            let responseOnUpdateBodyObject = JSON.parse(responseOnCreateBody);
            
            window.localStorage.removeItem('brandsOptions=all');
            createdBrandIdRef.current = responseOnUpdateBodyObject.Id;
            obj[`${process.env.REACT_APP_SYS_BRANDS_ID}`] = responseOnUpdateBodyObject.Id;

        }

        entriesToCreate.push(obj);
 
    }

    
    /**
     * since NocoDB's webhooks ignore bulk updates, vehicles have to be inserted
     * one by one. Should turn back to commented-out bulk code when following 
     * issue will be resolved: https://github.com/nocodb/nocodb/issues/2030
    
    // bulk create code:

    try {
        const responseOnUpdate = await fetchWithTimeout(
            `${get_BASE_DB_URL()}bulk/${process.env.REACT_APP_ORG_N_PROJECT_PATH}${journalEndpoint}`,
            makeRequest({
                auth_token: auth_token,
                method: "POST", 
                body: entriesToCreate,
                timeout: 60000
            })
        ).catch((err) => {
            logAbortError(err, `createJournalRows`)
            // return 0;
        });

        const responseOnUpdateBody = await responseStreamReader(responseOnUpdate)
        const responseBodyObject = await JSON.parse(responseOnUpdateBody);
 
        let responseOnRelationUpdate;
 
        for (let i = 0; i < responseBodyObject.length; i++) {

            for (let j = 0; j < destination.length; j++) {
                // console.log("responseBodyObject[i]", responseBodyObject[i])
                responseOnRelationUpdate = await fetchWithTimeout(
                    `${get_BASE_DB_URL()}/${process.env.REACT_APP_ORG_N_PROJECT_PATH}${journalEndpoint}/${responseBodyObject[i].id}/mm/journal_m2m_lands_n_objects/${destination[j].value}`, 
                    makeRequest({
                        auth_token: auth_token,
                        method: "POST", 
                        timeout: 45000,
                    })
                )

                if (responseOnRelationUpdate.status !== 200) {
                    return responseOnRelationUpdate;
                }

            }

        }
 
        return {
            status: 200,
            statusText: "Успешно доставлено"
        }
        // if (responseOnRelationUpdate.status === 200) {
        // } else {
        //     return responseOnRelationUpdate;
        // }
 
    } catch (error) {
        throw new Error("NocoDB is likely down", { cause: error });
    }

*/
    try {
        let response;
        // console.log('--- addToJournal form saving data')
        for (let i = 0; i < entriesToCreate.length; i++) {

            // if (entriesToCreate[i].entered === true) {

            //     // entriesToCreate[i].entered_at = new Date().toLocaleString("ru-RU"); 
            //     entriesToCreate[i].entered_at = new Date().toISOString(); 

            //     if (userData.roles.includes('kpp2')) {
            //         entriesToCreate[i].entry_point = "КПП-2";
            //     } else if (userData.roles.includes('kpp3')) {
            //         entriesToCreate[i].entry_point = "КПП-3";
            //     } else {
            //         entriesToCreate[i].entry_point = "КПП-1";
            //     }

            // }


            response = await fetchWithTimeout(
                `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${journalEndpoint}`,
                makeRequest({
                    auth_token: auth_token, 
                    method: "POST", 
                    body: entriesToCreate[i],
                    timeout: 160000,
                })
            ).catch((err) => {
                logAbortError(err, `createJournalRows`)
            });


            const responseBody = await responseStreamReader(response);
            const responseBodyObject = await JSON.parse(responseBody);

            for (let j = 0; j < destination.length; j++) {
                
                response = await fetchWithTimeout(
                    `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${journalEndpoint}/${responseBodyObject.Id}/mm/journal_m2m_lands_n_objects/${destination[j].value}`, 
                    makeRequest({
                        auth_token,
                        method: "POST",
                        timeout: 160000,
                    })
                )
             
            }

        }

        // console.log('--- addToJournal form iterated over all data, returning response')


        if (response.status === 200) {
            return {
                status: 200,
                statusText: "Успешно доставлено"
            }
        } else {
            return response;
        }

    } catch (error) {
        throw new Error("NocoDB is likely down", { cause: error });
    }


}

export const deleteRowWithTimeout = async ({
    target = "deposit",
    nocodb_auth,
    cell,
    deletingRowsRef,
    justDeletedRowsRef,
    tableDataRef,
    setRowExists}) => {

    const buttonElement = document.getElementById(`${target}-delete-row-${cell.row.id}`);
    const row = cell.row;
    let origin_ID = row.values.origin_ID;

    const deleteRowForReal = async ( nocodb_auth ) => {
        let endpoint;
        if (target === "deposit") {
            endpoint = depositsEndpoint;
        }

        if (target === "brand") {
            endpoint = brandsEndpoint;
        }
        
        const responceOnDepositDelete =  await fetchWithTimeout (
            `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${endpoint}/${origin_ID}`,
            makeRequest({
                auth_token: nocodb_auth,
                method: "DELETE"
            })
        )

        if (responceOnDepositDelete.status === 200) {
            tableDataRef.current = tableDataRef.current.filter(el => {

                if ( deletingRowsRef.current.includes(el.origin_ID) ) {
                    deletingRowsRef.current = deletingRowsRef.current.filter(e => e !== el.origin_ID)
                    return false
                } else {
                    return true
                }

            })
            justDeletedRowsRef.current.push(origin_ID);
            setRowExists(false)

        } else {
            window.alert("Удалить запись не удалось, сообщите администратору")
        }

    }

    if (!buttonElement.classList.contains("fallback")) {
        buttonElement.classList.add('fallback');
        deletingRowsRef.current.push(origin_ID);


        let timeout = window.setTimeout(() => {
    
            if(!buttonElement.classList.contains("fallback")) {
                clearTimeout(timeout);
                deletingRowsRef.current = deletingRowsRef.current.filter(e => {
                    if(e !== origin_ID) {
                        return true;
                    } else {
                        return false;
                    }
                });

            } else {
                deleteRowForReal(nocodb_auth);                
            }
        }, 3000)

        return null;
        
    } if (buttonElement.classList.contains("fallback")) {   
        buttonElement.classList.remove('fallback');

        deletingRowsRef.current = deletingRowsRef.current.filter(e => {
            if(e !== origin_ID) {
                return true;
            } else {
                return false;
            }
        });

    }
    
}

export const createDeposit = async (userData, newDepositData) => {
    const auth_token = userData.nocodb_auth;

    // console.log(userData, newDepositData)

    let depositToCreate = {
        "nc_xcf___lands_n_objects_id": newDepositData["dep-destination"]?.value,
        "limit": Number.parseInt(newDepositData["dep-limit"]),
        "comment": newDepositData["dep-comment"],
        "category": newDepositData["dep-category"]?.value,
    }

    try {
        let response = await fetchWithTimeout(
            `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${depositsEndpoint}`,
            makeRequest({
                auth_token, 
                method: "POST",
                body: depositToCreate
            })
        ).catch((err) => {
            logAbortError(err, `createDeposit`)
            throw err
        });
    
        // const responseBody = await responseStreamReader(response)
        // const responseBodyObject = await JSON.parse(responseBody);
        return response;

    } catch (error) {
        return error;
    }

}

export const prolongDeposit = async (userData, depositID) => {
    const auth_token = userData.nocodb_auth;
    const data = {
        live_long: true,
        archived: false,
    }

    try {
        let response = await fetchWithTimeout(
            `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${depositsEndpoint}/${depositID}`,
            makeRequest({
                auth_token: auth_token,
                method: "PATCH", 
                body: data,
            })
        ).catch((err) => {
            logAbortError(err, `createDeposit`)
            throw err
        });

        return response;

    } catch (error) {
        return error;
    }
}

export const createEntryInBlacklist = async (userData, entryData) => {
    const auth_token = userData.nocodb_auth;

    let blToCreate = {
        "nc_xcf___lands_n_objects_id": entryData["relatedArea"]?.value,
        "number": entryData["number"],
        "nc_xcf___brands_id": entryData["brand"]?.value,
        "comment": entryData["comment"],
        "sanction": entryData["sanction"]?.value,
        // "files": JSON.stringify(entryData?.["files"]),
        "archived": false,
    }

    if (entryData?.["files"]?.length > 0) {
        blToCreate.files = JSON.stringify(entryData?.["files"]);
    }


    try {
        console.log("trying add to blacklist", `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${blacklistEndpoint}`)
        let response = await fetchWithTimeout(
            `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${blacklistEndpoint}`,
            makeRequest({
                auth_token: auth_token,
                method: "POST", 
                body: blToCreate,
            })
        ).catch((err) => {
            logAbortError(err, `createEntryInBlacklist`)
            throw err
        });
    
        return response;

    } catch (error) {
        return error;
    }
}

export const setExistingOrNewOption = async ({
    targetEndpoint = 'brands',
    auth_token,
    options,
    setOptions,
    allOptionsRef,
    cell,
    newValue,
    newValueRef,
    localStorageName
}) => {
    // console.log("setExistingOrNewOption called with newValue", newValue)
    let brandToSet;
    
    if (newValue?.__isNew__ !== null && newValue?.__isNew__ !== undefined) {
        const responseOnCreate = await fetchWithTimeout(
            `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${targetEndpoint}`,
            makeRequest({
                auth_token, 
                method: "POST", 
                body: {brand: newValue.label}
            })
        ).catch((err) => {
            console.error(err)
            return 0;
        });
        let responseOnCreateBody = await responseStreamReader(responseOnCreate);
        let responseOnUpdateBodyObject = JSON.parse(responseOnCreateBody);

        updateValues({
            auth_token,
            values: [
                {
                    cell: cell,
                    targetProperty: process.env.REACT_APP_SYS_BRANDS_ID,
                    newValue: responseOnUpdateBodyObject.Id
                }
        ]
        }).then(() => {
            
            newValueRef.current = {
                value:responseOnUpdateBodyObject.Id, 
                label: newValue.label,
            }

            let tempOptions = allOptionsRef.current.concat([
                {
                    value:responseOnUpdateBodyObject.Id, 
                    label: newValue.label,
                }
            ])
    
            setOptions(tempOptions);
            allOptionsRef.current = tempOptions;
    
            window.localStorage.setItem(localStorageName, JSON.stringify({
                arrayOfOptions: tempOptions,
                dateOfInsertion: new Date()
            }))

        })


    } else {
        brandToSet = options.find(brand => brand.label === newValue?.label);
        updateValues({
            auth_token, 
            values: [
                {
                    cell: cell,
                    targetProperty: process.env.REACT_APP_SYS_BRANDS_ID,
                    newValue: brandToSet?.value
                }
            ]
        });
    }
}

export const executeBrandsMerge = async ({auth_token, brandToDelete, brandToEnrich, controller}) => {
    let response;

    let report = {
        replacedBrand: brandToDelete,
        targetBrand: brandToEnrich,
    };

    const patchEntriesWithStaleBrand = async (endpoint) => {


        /**
         * Below id perfomant way to do task, but bulk update
         * doesn't return updated ID's, so I can't send them within report

        response = await fetchWithTimeout(
            `${get_BASE_DB_URL()}bulk/${process.env.REACT_APP_ORG_N_PROJECT_PATH}${endpoint}/all?where=(${process.env.REACT_APP_SYS_BRANDS_ID},eq,${brandToDelete.origin_ID})`,
            makeRequest({
                method: "PATCH",
                timeout: "120000",
                auth_token: auth_token,
                body: {
                    [`${process.env.REACT_APP_SYS_BRANDS_ID}`]: brandToEnrich.origin_ID,
                }
            }),
            controller
        ).catch((err) => {
            logAbortError(err, `executeBrandsMerge call patching brands on ${endpoint}`)
            
            return {
                status: response.status ? response.status : 500,
                statusText: response.statusText ? response.statusText : 'Неизвестная ошибка',
            }
        });
        
        */

        response = await fetchWithTimeout(
            `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${endpoint}?where=(${process.env.REACT_APP_SYS_BRANDS_ID},eq,${brandToDelete.origin_ID})&fields=Id`,
            makeRequest({
                method: "GET",
                timeout: "35000",
                auth_token: auth_token,
            }),
            controller
        ).catch((err) => {
            logAbortError(err, `executeBrandsMerge call for getting ${endpoint} entries to update`)
            
            return {
                status: response.status ? response.status : 500,
                statusText: response.statusText ? response.statusText : 'Неизвестная ошибка',
            }

        });
        
        let responseBody = await responseStreamReader(response);
        let responseBodyObject = JSON.parse(responseBody);
        let idsToPatch = responseBodyObject?.list?.reduce((prev, current) => {
            return [...prev, current.Id]
        }, [])

        response = await fetchWithTimeout(
            `${get_BASE_DB_URL()}bulk/${process.env.REACT_APP_ORG_N_PROJECT_PATH}${endpoint}/all?where=(Id,in,${idsToPatch.join()})`,
            makeRequest({
                method: "PATCH",
                timeout: "120000",
                auth_token: auth_token,
                body: {
                    [`${process.env.REACT_APP_SYS_BRANDS_ID}`]: brandToEnrich.origin_ID,
                }
            }),
            controller
        ).catch((err) => {
            logAbortError(err, `executeBrandsMerge call patching brands on ${endpoint}`)
            
            return {
                status: response.status ? response.status : 500,
                statusText: response.statusText ? response.statusText : 'Неизвестная ошибка',
            }
        });
    
        if (response.status !== 200) {
    
            return {
                status: response.status ? response.status : 500,
                statusText: response.statusText ? response.statusText : 'Неизвестная ошибка',
            }
    
        }

        
        report[`${endpoint}-IDsUpdated`] = idsToPatch;
        return 'ok'

    }

    if (brandToDelete.journal_count > 0) {
        let result = await patchEntriesWithStaleBrand(journalEndpoint);

        if (result !== 'ok') {
            return result
        }

    }

    if (brandToDelete.local_vehicles_count > 0) {
        let result = await patchEntriesWithStaleBrand(vehiclesEndpoint);

        if (result !== 'ok') {
            return result
        }

    }
    
    if (brandToDelete.blacklist_count > 0) {
        let result = await patchEntriesWithStaleBrand(blacklistEndpoint);

        if (result !== 'ok') {
            return result
        }

    }

    // notifyAboutDangerousAction(report);

    const responceOnDepositDelete =  await fetchWithTimeout (
        `${get_BASE_DB_URL()}${process.env.REACT_APP_ORG_N_PROJECT_PATH}${brandsEndpoint}/${brandToDelete.origin_ID}`,
        makeRequest({
            auth_token,
            method: "DELETE"
        })
    )

    if (responceOnDepositDelete.status === 200) {

        return {
            status: 200,
            statusText: "Марки успешно объединены"
        }

    }


}

export const getColumnData = async ({userData, source, column_id}) => {
    const auth_token = userData.nocodb_auth;
    let targetEndpoint = chooseEndpoint(source);

    try {
        let response = await fetchWithTimeout(
            `${get_BASE_DB_URL(false)}/api/v1/db/meta/tables/${targetEndpoint}`,
            makeRequest({
                auth_token, 
                method: "GET",
            })
        ).catch((err) => {
            logAbortError(err, `getColumnData`)
            throw err
        });
    
        let resp = await response.json();
        let targetColumn = resp.columns.filter(c => c.title === column_id)
        
        return targetColumn;

    } catch (error) {
        return error;
    }

}