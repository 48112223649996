import { Query, QueryFields, QueryFilter, readItems } from "@directus/sdk";
import { Collections, Schema, Types } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";
import { SelectOption } from "../useSelectOptions";

export type ProvideAreasOptions = {
  fields?: QueryFields<Schema, Collections.LandsNObjects>,
  currentViewFilterCondition?: QueryFilter<Schema, Collections.LandsNObjects>,
  controller?: AbortController,
  sourceURL: string,
}

export interface AreaFormatted extends SelectOption<number> {
  area_status?: string,
  status_changed_at?: Types.DateTime,
}

export const provideAreas = async ({
  fields = ["id", "area_name", "status", "status_changed_at"],
  controller,
  currentViewFilterCondition,
  sourceURL,
}: ProvideAreasOptions) => {
  const areasFormatted: AreaFormatted[] = [];
  const client = getDirectusClient(sourceURL);
  
  // console.log("provideAreas called with, ", currentViewFilterCondition)
  
  const request: Query<Schema, Collections.LandsNObjects> = {
    limit: 1000,
    offset: 0,
    sort: "area_name" as const,
    fields,
    // @ts-expect-error directus SDK's type is missing abort signal
    signal: controller.signal,
  }

  if (currentViewFilterCondition) {
    request.filter = currentViewFilterCondition;
  }
  
  const response = await client.request(readItems("lands_n_objects", request));

  response.forEach(element => {
    const objectToPush: AreaFormatted = {
      value: element.id,
      label: element.area_name,
    }

    if (fields.includes("status")) {
      objectToPush.area_status = element.status.join(", ");
    }
    
    if (fields.includes("status_changed_at")) {
      objectToPush.status_changed_at = element.status_changed_at;
    }

    areasFormatted.push(
      objectToPush
    )

  });

  return areasFormatted;
}
