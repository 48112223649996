import React, { useState } from 'react';
import { getRidOfAliases, applyTimezone } from "../../../services/littleHelpers"
import { Row } from 'react-table';
import { JournalRowData, patchJournalEntries } from '../../../services/DataProviders/journal';

const markEntriesPaid = async ({
  chosenEntries, withParking
}: { chosenEntries: Row<JournalRowData>[], withParking: Row<JournalRowData>[] }) => {
  const alteredRowsIds = [];

  const entriesToUpdate = chosenEntries.map(entry => entry.original.origin_ID);
  await patchJournalEntries({
    IDs: entriesToUpdate,
    data: {
      paid: true
    }
  })
  alteredRowsIds.push(...entriesToUpdate);

  if (withParking.length > 1) {
    const withParkingToUpdate = withParking.map(entry => entry.original.origin_ID);
    await patchJournalEntries({
      IDs: withParkingToUpdate,
      data: {
        parking: "Парковка оплачена"
      }
    })
    alteredRowsIds.push(...withParkingToUpdate);
  }

  return alteredRowsIds;
}

interface AreaToFilterBy {
  value: number;
  label: string;
  area_status: string;
  credit_count: number;
}

interface GetReportProps {
  areaToFilterBy: AreaToFilterBy;
  markedEntries: Row<JournalRowData>[];
  justAlteredRowsRef: React.MutableRefObject<any>;
  toggleAllRowsSelected: (arg0: boolean) => void;
  setRecentReportAreaId: React.Dispatch<React.SetStateAction<number>>;
}

export const GetReport = ({ areaToFilterBy, markedEntries, justAlteredRowsRef, toggleAllRowsSelected, setRecentReportAreaId }: GetReportProps) => {
  const [isLoading, setIsLoading] = useState(false);

  /**
   * Component on report call provides csv with following columns:
   * Гос. Номер	/ Марка	/ Время получения / Категория / Комментарий
   *
   * On download chosen entries will be marked as paid.
   */

  const entriesWithParking = markedEntries.filter((entry) => {
    return entry.original.parking === "Парковка в кредит"
  })
  const parkingCount = entriesWithParking ? entriesWithParking.length : 0;

  const formatData = (entries: Row<JournalRowData>[]) => {
    let csv = 'Гос. Номер\tМарка\tВремя получения\tКатегория\tКомментарий\n';

    entries.forEach(entry => {
      if (entry.original.parking === "Парковка в кредит") {

        csv += [
          entry.original.number.toUpperCase(),
          getRidOfAliases(entry.original.brand.label),
          entry.original.created_at,
          entry.original.category,
          undefined
        ].join("\t")
        csv += `\n`

        csv += [
          entry.original.number.toUpperCase(),
          getRidOfAliases(entry.original.brand.label),
          entry.original.created_at,
          `Стоянка [${entry.original.comment}]`,
          undefined
        ].join("\t")
        csv += `\n`

      } else {
        csv += [
          entry.original.number.toUpperCase(),
          getRidOfAliases(entry.original.brand.label),
          entry.original.created_at,
          entry.original.category,
          entry.original?.comment
        ].join("\t")
        csv += `\n`

      }
    })

    return csv;
  }

  const downloadCSV = (csv: string, filename: string) => {

    const BOM = "\uFEFF";
    csv = BOM + csv;

    const csvFile = new Blob([csv], { type: 'text/csv;charset=utf-8;', endings: "native" });

    const downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";

    document.body.appendChild(downloadLink);

    downloadLink.click();
  }

  const handleReportRequest = async () => {
    setIsLoading(true);
    const formattedAreaName = getRidOfAliases(areaToFilterBy.label).replace(/участок/gi, "").replace(/\s/g, "").replace(/\/|\\|,|\*/, "-")
    
    const nowDate = new Date();
    const todayString = applyTimezone(nowDate.toISOString(), 3).replace(/\s.+$/, "");

    try {
      const result = await markEntriesPaid({
        chosenEntries: markedEntries,
        withParking: entriesWithParking
      });

      downloadCSV(
        formatData(markedEntries),
        `area--${formattedAreaName}--${todayString}--report.csv`
      );
      
      justAlteredRowsRef.current = justAlteredRowsRef.current.concat(result);
      toggleAllRowsSelected(false);
      setIsLoading(false);
      setRecentReportAreaId(areaToFilterBy?.value)
    } catch (err) {
      console.error(err)
      window.alert(`При попытке пометить выбранные записи оплаченными произошла ошибка, попробуйте ещё раз`)
      setIsLoading(false);
    }
  }

  return (
    <>
      <div id="get-report-container">
        <div id="report-info-block">
          <p>
            Для расчёта с «{areaToFilterBy.label}»
            выбрано {markedEntries.length} записей.<br />
            {parkingCount > 0 ?
              <>

                {`В отчётную ведомость будут включены ${parkingCount} дополнительные строчки для оплаты стоянки.`}
                <br />
              </>
              :
              null
            }
            При нажатии на кнопку «Выгрузить» выбранные записи будут помечены как оплаченные.
          </p>
        </div>
        <button
          key={`loading-${isLoading}`}
          className={`button makeReport ${isLoading ? "loading" : ""}`}
          disabled={isLoading}
          onClick={handleReportRequest}
        >
          Выгрузить
        </button>
      </div>
    </>
  );
}

const propsAreEqual = (prev: GetReportProps, next: GetReportProps) => {
  if (prev.areaToFilterBy.value === next.areaToFilterBy.value) {
    if (prev.markedEntries.length === next.markedEntries.length) {
      return true;
    }
  }
  return false;
}

const GetReportMemoized = React.memo(GetReport, propsAreEqual);

export default GetReportMemoized;
