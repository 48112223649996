import React, { useState, useContext } from 'react';
import { applyTimezone, getRidOfAliases } from '../../../services/littleHelpers';
import { 
    DeleteRowButton,
    ProlongDeposit,
    TextInput,
} from "../../inputElements";

import { MainContext } from '../../MainContext';


const DepositRow = ({ preparedRow, tableDataRef, justAlteredRowsRef, deletingRowsRef, setEditedEntryID }) => {
    const { userData, siteSettings } = useContext(MainContext);
    const [rowExists, setRowExists] = useState(true);

    // console.log("DepositRow has data -", tableDataRef.current)

    const cellRenderFunction = (cell) => {

        if (cell.column.id === "object") {

            return (
                <span
                    title={siteSettings.tooltips && cell.value.area_name + `${cell.value?.status ? " — " + cell.value?.status.replace(",", ", ") : ""}`}
                >
                    <p>{getRidOfAliases(cell.value.area_name)}</p>
                </span>
            );

        } else if (cell.column.id === "comment") {

            return (
                <TextInput
                    cell={cell}
                    textArea={true}
                    targetEndpoint={'deposits'}
                    allowedRoles={["admin", "accountant"]}
                    customDisablingFunction={ () => { return false } }
                />
            )

        } else if (cell.column.id === "limit") {

            const shouldLimitBeDisabled = () => {
                return false
            }

            return (
                <TextInput
                    cell={cell}
                    targetEndpoint={'deposits'}
                    onlyNumber={true}
                    customDisablingFunction={shouldLimitBeDisabled}
                    dependantValuesToSet={[{name: 'life_span_in_days', value: cell.row.original.life_span_in_days + 7}]}
                />
            );

        } else if (cell.column.id === "entered_vehicles") {
            let infoIcon = <svg xmlns="http://www.w3.org/2000/svg" className="info-circle" width="44" height="44" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="12" cy="12" r="9" />
                <line x1="12" y1="8" x2="12.01" y2="8" />
                <polyline points="11 12 12 12 12 16 13 16" />
            </svg>

            return (
                <>
                    { cell?.value?.length > 0 ?
                        <ul className='entered_vehicles-list'>
                            {cell.value.map(vehicle => (
                                <li key={`vehicle-id-${vehicle.id}`}>
                                    {getRidOfAliases(vehicle?.brands?.brand) + " " + vehicle.number}
                                    <i
                                        title={`прибыл${!!vehicle.entered_at && vehicle.entered_at !== "неизвестно" ? " в " + applyTimezone(vehicle.entered_at, 3) : ""}${vehicle.entry_point ? " на " + vehicle.entry_point : ""}`}
                                    >
                                        {infoIcon}
                                    </i>
                                </li>
                            ))}
                        </ul>
                        :
                        null
                    }
                </>
            );
        } else if (cell.column.id === "status") {
            
            return (
                <span>
                    <p>
                        {cell.row.original.status}
                        <span title={"число_въехавших/оплаченный_лимит"}>
                            {`${cell.row.original.entered_vehicles_count}/${cell.row.original.limit}`}
                        </span>
                    </p>
                </span>
            );

        } else if (cell.column.id === "expiration_date") {

            if (cell.row.original.live_long === true) {
                return "До исчерпания"
            } else {
                return <p>{cell.value}</p>
            }

        } else if (cell.column.id === "prolong") {
            let disabled = false;
            
            if (
                cell.row.original.available < 1 
                    || 
                cell.row.original.live_long
            ) {
                disabled = true;
            }

            return (
                <ProlongDeposit
                    cell={cell}
                    isDisabled={disabled}
                    userData={userData}
                    tooltips={siteSettings.tooltips}
                    setEditedDepositId={setEditedEntryID}
                    justAlteredRowsRef={justAlteredRowsRef}
                />
            );
            
        } else if (cell.column.id === "delete") {
            
            return <DeleteRowButton
                cell={cell}
                deletingRowsRef={deletingRowsRef}
                justAlteredRowsRef={justAlteredRowsRef}
                tableDataRef={tableDataRef}
                setRowExists={setRowExists}
                target={'deposits'}
            />

        }
        // return null;
        return cell.render('Cell');
    }

    const { key, ...rowProps } = preparedRow?.getRowProps() || {};
    
    return (
        <>
            <tr 
                key={`entry-id-${preparedRow.original.origin_ID}-row-updated-at-${preparedRow?.original?.updated_at_full}-${rowExists && "exist"}-${key}`}
                id={`deposit-entry-id-${preparedRow.original.origin_ID}`}
                className={`deposit-entry ${!rowExists ? "deleted" : ""}`}
                {...rowProps}
            >
                {
                    preparedRow.cells.map((cell, i) => {
                      const { key, ...cellProps } = cell.getCellProps();
                      
                      return (
                        <td 
                            className={`cell-in-${cell.column.id} boxProperty`} 
                            key={`${cell.row.original.origin_ID}-${cell.column.header}-${key}`} 
                            {...cellProps}
                        >
                            { cellRenderFunction(cell) }
                        </td>
                      );
                    })
                }
            </tr>
        </>
    );

}


function rowsPropsAreEqual(prevRow, nextRow) {
    return prevRow.preparedRow.original.origin_ID === nextRow?.preparedRow?.original?.origin_ID
}

const DepositRowMemoised = React.memo(DepositRow, rowsPropsAreEqual)

export default DepositRowMemoised;