import { DateTime } from "luxon";
import { Types } from "../models/DirectusSchema";


export const formatDateTime = (date: Types.DateTime | number | null) => {
  if (!date) return null;
  let luxonDate: DateTime;
  
  if (typeof date === "number") {
    luxonDate = DateTime.fromMillis(date);
  } else if (typeof date === "string") {
    luxonDate = DateTime.fromISO(date);
  } else {
    luxonDate = DateTime.fromJSDate(date);
  }
  
  return luxonDate.toFormat("yyyy-MM-dd HH:mm");
}

export const formatDate = (date: Types.Date | null) => {
  if (!date) return null;
  const luxonDate = DateTime.fromISO(date.toString());
  return luxonDate.toFormat("yyyy-MM-dd");
}

export const countDaysToNow = (date: Types.DateTime | null) => {
  if (!date) return null;
  const luxonDate = DateTime.fromISO(date.toString());
  const now = DateTime.now();
  return now.diff(luxonDate, "days").toObject().days;
}
