import { aggregate, createItem, QueryFields, QueryFilter, QuerySort, readItems, updateItem } from "@directus/sdk";
import { NewBlacklistEntryFieldValues } from "../../components/blacklist/AddToBlacklistDialog";
import { Collections, Schema, Types } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";
import { getDirectusFilterFromTableFilter } from "../DirectusServices/directusHelpers";
import { LandObject } from "./journal";
import { CreatableSelectOption } from "../useSelectOptions";

export type BlacklistItemData = {
  origin_ID: number,
  dest: LandObject,
  number: string,
  brand: CreatableSelectOption<number>,
  comment: string,
  sanction: string,
  files?: string[],
  updated_at_full: Types.DateTime
}

interface ProvideBlacklistProps {
  pageNo?: number,
  recordsLimit?: number,
  filters?: Array<{ id: string, value: string | number | boolean }>,
  fields?: QueryFields<Schema, Collections.Blacklist>,
  currentViewFilterCondition?: QueryFilter<Schema, Collections.Blacklist>,
  sortString?: Array<QuerySort<Schema, Collections.Blacklist>>,
  controller?: AbortController,
  sourceURL?: string
}

export async function provideBlacklist({
  pageNo = 0,
  recordsLimit = 50,
  filters = [],
  currentViewFilterCondition = undefined,
  sortString = ["-updated_at"],
  controller = undefined,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: ProvideBlacklistProps) {
  const client = getDirectusClient(sourceURL);
  const blacklistItems: BlacklistItemData[] = [];

  const filter = getDirectusFilterFromTableFilter({
    filters
  })

  if (currentViewFilterCondition) {
    filter._and.push(currentViewFilterCondition);
  }

  const totalCountRequest = await client.request(aggregate("blacklist", {
    aggregate: {
      count: "*"
    },
    query: {
      filter: filter
    }
  }));

  const totalCount = (totalCountRequest?.[0]?.count && parseInt(totalCountRequest?.[0]?.count, 10)) || 0;

  const response = await client.request(readItems("blacklist", {
    limit: recordsLimit,
    offset: pageNo * recordsLimit,
    sort: sortString,
    filter,
    fields: [
      "id",
      "number",
      {
        "brands": ["brand", "id"]
      },
      "comment",
      {
        "lands_n_objects": ["area_name", "id", "status"]
      },
      "sanction",
      {
        "files": [{"directus_files_id": ["id"]}]
      },
      "updated_at"
    ],
    signal: controller.signal,
  }));

  response.forEach(item => {
    let dest = null;

    if (item.lands_n_objects) {
      dest = {
        Id: item.lands_n_objects.id,
        area_name: item.lands_n_objects.area_name,
        status: item.lands_n_objects.status.join(", "),
      }
    }

    const tempObj: BlacklistItemData = {
      origin_ID: item.id,
      number: item?.number,
      brand: {
        label: item.brands.brand,
        value: item.brands.id,
      },
      dest,
      sanction: item?.sanction,
      updated_at_full: item?.updated_at,
      comment: item?.comment
    };

    if (item?.files) {
      tempObj.files = item?.files?.map(file => file.directus_files_id.id);
    }

    blacklistItems.push(tempObj);
  });

  return {count: totalCount, data: blacklistItems};
}

export interface ArchiveBlacklistItemProps {
  itemID: number;
  newComment: string;
  sourceURL?: string;
}

export const archiveBlacklistedItem = async ({
  itemID,
  newComment,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: ArchiveBlacklistItemProps) => {
  const client = getDirectusClient(sourceURL);
  return await client.request(updateItem("blacklist", itemID, {
    comment: newComment,
    archived: true
  }))
}

interface CreateEntryInBlacklistProps {
  entryData: NewBlacklistEntryFieldValues;
  sourceURL?: string;
}

export const createEntryInBlacklist = async ({
  entryData,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: CreateEntryInBlacklistProps) => {
  const client = getDirectusClient(sourceURL);

  const item: Partial<Collections.Blacklist> = {
    "lands_n_objects": entryData["relatedArea"]?.value,
    "number": entryData["number"].toUpperCase().replace(/\s+/g, ""),
    "comment": entryData["comment"],
    "sanction": entryData["sanction"]?.value,
    "archived": false,
  }
  
  if (!entryData.brand.__isNew__) {
    item.brands = entryData.brand.value; 
  } else {
    
    const responseOnCreate = await client.request(createItem(
      "brands",
      { brand: entryData.brand.label },
      { fields: ["*"] }
    ))
    console.log("new brand created: ", responseOnCreate);
    item.brands = responseOnCreate.id; 
    window.localStorage.removeItem('brandsOptions=all');
  }

  if (Array.isArray(entryData?.files)) {
    // @ts-expect-error I don't want to extend directus items types with inner sdk CRUD methods on relational fields
    item.files = entryData.files;
  }

  return await client.request(createItem("blacklist", item))
}
