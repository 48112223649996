import { FilterOptionOption } from "react-select/dist/declarations/src/filters";

interface Config<Option> {
  readonly ignoreCase?: boolean;
  readonly ignoreAccents?: boolean;
  readonly stringify?: (option: FilterOptionOption<Option>) => string;
  readonly trim?: boolean;
  readonly matchFrom?: 'any' | 'start';
}

export const reasonableFilterConfig: Config<unknown> = {
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  stringify: option => `${option.label}`,
  trim: true,
}
