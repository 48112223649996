import { createContext } from "react";
import { allRoles } from "../App";
import { Role as DirectusRole } from "../services/DirectusUserService";
import { Types } from "../models/DirectusSchema";

export type SiteSettings = {
  theme?: "light" | "dark";
  tooltips?: boolean;
  refreshFrequency: number;
  journal_loggingEnabled?: boolean;
  debt_loggingEnabled?: boolean;
  blacklist_loggingEnabled?: boolean;
  deposits_loggingEnabled?: boolean;
  localVehicles_loggingEnabled?: boolean;
  localPedestrians_loggingEnabled?: boolean;
  allBrands_loggingEnabled?: boolean;
  reports_loggingEnabled?: boolean;
}

export type UserData = {
  id: Types.UUID;
  username: string;
  email: string;
  nocodb_auth: string | null;
  roles: Array<typeof allRoles[number]> | null;
  first_name: string;
  last_name: string | null;
  directus_role: DirectusRole | null;
}

export type MainContextType = {
  sourceURL: string | null;
  siteSettings?: SiteSettings;
  setSiteSettings?: (settings: Partial<SiteSettings>) => void;
  userData?: UserData;
  changeUserData?: (data: any) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
}

export const defaultSiteSettings: SiteSettings = {
  theme: "light",
  tooltips: true,
  refreshFrequency: 15 * 60 * 1000, // 15 minutes
  journal_loggingEnabled: false,
  debt_loggingEnabled: false,
  reports_loggingEnabled: false,
  blacklist_loggingEnabled: false,
  deposits_loggingEnabled: false,
  localVehicles_loggingEnabled: false,
  localPedestrians_loggingEnabled: false,
  allBrands_loggingEnabled: false,
};

const defaultContext: MainContextType = {
  sourceURL: null,
  siteSettings: defaultSiteSettings,
  setSiteSettings: () => {},
  userData: undefined,
  changeUserData: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {}
};

export const MainContext = createContext<MainContextType>(defaultContext);
