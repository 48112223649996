import React, { useRef, useState } from 'react';
import Spoiler from '../commonUI/Spoiler'
import BlacklistItemMemoised from '../blacklist/BlacklistItem'
import { BlacklistItemData } from '../../services/DataProviders/blacklist';
import ModalDialog from '../modals/ModalDialog';

interface ActiveBlacklistProps {
  usersRoles: string[];
  blacklistData: BlacklistItemData[];
  isLoading: boolean;
}

const ActiveBlacklist = ({ usersRoles = [], blacklistData, isLoading }: ActiveBlacklistProps) => {
  const [confirmationRequired, setConfirmationRequired] = useState(false);
  const confirmationSubjectRef = useRef(<></>);

  const canBeReleased = usersRoles.includes('admin') || usersRoles.includes('patrol1') || usersRoles.includes('patrol2');

  return (
    <>
      <Spoiler
        header={"Чёрный список"}
        contentID={"blacklist-container"}
        addClassName={!isLoading && (blacklistData?.length < 1 || !blacklistData) ? 'empty' : ""}
        isDataLoaded={!isLoading}
        defaultCollapsed={true}
        itemsCount={blacklistData?.length || 0}
      >
        {
          blacklistData?.map(car => (
            <BlacklistItemMemoised
              key={`BlacklistItem-${car.origin_ID}`}
              item={car}
              canBeReleased={canBeReleased}
              confirmationSubjectRef={confirmationSubjectRef}
              setConfirmationRequiered={setConfirmationRequired}
              detailsExpanded={false}
            />
          ))
        }
        {
          canBeReleased ?
            <ModalDialog
              isOpen={confirmationRequired}
              exitFunction={setConfirmationRequired}
            >
              {confirmationSubjectRef.current}
            </ModalDialog>
            : null
        }
      </Spoiler>
    </>
  );
}

function propsAreEqual(prev: ActiveBlacklistProps, next: ActiveBlacklistProps) {
  if (next?.blacklistData?.length > 0) {
    return prev?.blacklistData?.[0]?.updated_at_full === next?.blacklistData?.[0]?.updated_at_full;
  } else {
    return prev?.blacklistData === next?.blacklistData;
  }
}

const ActiveBlacklistMemoized = React.memo(ActiveBlacklist, propsAreEqual);

export default ActiveBlacklistMemoized;
