import { createItem } from "@directus/sdk";
import { getDirectusClient } from "../DirectusSDKClient";
import { CollectionKeys } from "../DirectusServices/directusHelpers";
import { updateValues } from "./updateValues";

type setCreatableSelectOptionProps = {
  targetTable?: CollectionKeys;
  options: any[];
  setOptions: any;
  allOptionsRef: any;
  cell: any;
  newValue: any;
  newValueRef: any;
  localStorageName: string;
  sourceURL?: string;
}

export const setCreatableSelectOption = async ({
  targetTable = 'brands',
  options,
  setOptions,
  allOptionsRef,
  cell,
  newValue,
  newValueRef,
  localStorageName,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: setCreatableSelectOptionProps) => {
  // console.log("setExistingOrNewOption called with newValue", newValue)
  const targetColumnId = cell.column?.id;

  const client = getDirectusClient(sourceURL);

  if (newValue?.__isNew__ !== null && newValue?.__isNew__ !== undefined) {
    const responseOnCreate = await client.request(createItem(targetTable, {
      [`${targetColumnId}`]: newValue.label
    }, {
      fields: ["id", targetColumnId]
    })).catch((err) => {
      console.error(err)
      return 0;
    });
    // @ts-expect-error this would be fine, directus sdk's types are wrong a bit
    const newSelectableItemId = responseOnCreate.id
    updateValues({
      values: [
        {
          cell: cell,
          targetProperty: targetColumnId,
          newValue: newSelectableItemId
        }
      ]
    }).then(() => {

      newValueRef.current = {
        value: newSelectableItemId,
        label: newValue.label,
      }

      const tempOptions = allOptionsRef.current.concat([
        {
          value: newSelectableItemId,
          label: newValue.label,
        }
      ])

      setOptions(tempOptions);
      allOptionsRef.current = tempOptions;

      window.localStorage.setItem(localStorageName, JSON.stringify({
        arrayOfOptions: tempOptions,
        dateOfInsertion: new Date()
      }))

    })


  } else {
    const valueToSet = options.find(option => option.label === newValue?.label);
    updateValues({
      values: [
        {
          cell: cell,
          targetProperty: targetColumnId,
          newValue: valueToSet?.value
        }
      ]
    });
  }
}
