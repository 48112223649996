import React, { ChangeEvent } from 'react';

interface ToggleProps {
  disabled?: boolean;
  checked?: boolean;
  id: string; // keeping id required as it's essential for accessibility
  name?: string;
  label?: React.ReactNode;
  changeFn?: (event: ChangeEvent<HTMLInputElement>) => void;
  iconsOnOf?: boolean;
  iconsTheme?: boolean;
  addClassName?: string;
}

const Toggle: React.FC<ToggleProps> = ({
  disabled = false,
  checked = false,
  id,
  name,
  label,
  changeFn,
  iconsOnOf,
  iconsTheme,
  addClassName
}) => {

  /**
   * Toggle implemented below based on article
   * about building accessible toggle, link below
   * https://kittygiraudel.com/2021/04/05/an-accessible-toggle/
   */

  return (
    <label
      className={`Toggle ${addClassName ?? ""}`}
      htmlFor={id}
      // TypeScript doesn't allow disabled on label, so we use data-attribute
      data-disabled={disabled}
    >
      <input disabled={disabled} defaultChecked={checked} type="checkbox" name={name} id={id} className="Toggle__input" onChange={changeFn} />
      {label}
      <span className="Toggle__display" hidden>
        {
          iconsOnOf === true && iconsTheme !== true &&
          <>
            <svg
              aria-hidden="true"
              focusable="false"
              className="Toggle__icon Toggle__icon--checkmark"
              width="18"
              height="14"
              viewBox="0 0 18 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.08471 10.6237L2.29164 6.83059L1 8.11313L6.08471 13.1978L17 2.28255L15.7175 1L6.08471 10.6237Z"
                fill="currentcolor"
                stroke="currentcolor"
              />
            </svg>
            <svg
              aria-hidden="true"
              focusable="false"
              className="Toggle__icon Toggle__icon--cross"
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.167 0L6.5 4.667L1.833 0L0 1.833L4.667 6.5L0 11.167L1.833 13L6.5 8.333L11.167 13L13 11.167L8.333 6.5L13 1.833L11.167 0Z"
                fill="currentcolor"
              />
            </svg>
          </>
        }
        {
          iconsTheme &&
          <>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="Toggle__icon Toggle__icon--dark"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentcolor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z" />
              <path d="M17 4a2 2 0 0 0 2 2a2 2 0 0 0 -2 2a2 2 0 0 0 -2 -2a2 2 0 0 0 2 -2" />
              <path d="M19 11h2m-1 -1v2" />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="Toggle__icon Toggle__icon--light"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentcolor"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="3" />
              <line x1="12" y1="5" x2="12" y2="5.01" />
              <line x1="17" y1="7" x2="17" y2="7.01" />
              <line x1="19" y1="12" x2="19" y2="12.01" />
              <line x1="17" y1="17" x2="17" y2="17.01" />
              <line x1="12" y1="19" x2="12" y2="19.01" />
              <line x1="7" y1="17" x2="7" y2="17.01" />
              <line x1="5" y1="12" x2="5" y2="12.01" />
              <line x1="7" y1="7" x2="7" y2="7.01" />
            </svg>
          </>

        }
      </span>
    </label>
  );
}

export default Toggle;
