import { useContext, useCallback, useMemo, useRef } from 'react';

import { MainContext } from "../../MainContext";
import Table from '../../commonUI/Table';
import BrandRowMemoised from './BrandRow';
import MergeBrands from './MergeBrands';

const initialArrayDataObject = [];
const initialHiddenColumns = ["created_at", "updated_at", "origin_ID"];

const VehicleBrands = () => {
  const { userData } = useContext(MainContext);
  const forceReloadRef = useRef(false);

  const columns = useMemo(() => {
    const cols = [
      {
        id: 'selection',
        Header: ({ selectedFlatRows, toggleAllRowsSelected }) => (
          <MergeBrands
            disabled={selectedFlatRows.length !== 2}
            selectedFlatRows={selectedFlatRows}
            forceReloadRef={forceReloadRef}
            toggleAllRowsSelected={toggleAllRowsSelected}
          />
        ),
        disableFilters: true,
      },
      {
        Header: 'Марка',
        accessor: 'common_brand',
      },
      {
        Header: 'Число записей журнала',
        accessor: 'journal_count',
        disableFilters: true,
      },
      {
        Header: 'Число штатного транспорта',
        accessor: 'local_vehicles_count',
        disableFilters: true,
      },
      {
        Header: 'Число машин черного списка',
        accessor: 'blacklist_count',
        disableFilters: true,
      },
      {
        Header: 'Создание',
        accessor: 'created_at',
        disableFilters: true,
      },
      {
        Header: 'Редакция',
        accessor: 'updated_at',
        disableFilters: true,
      },
      {
        Header: 'origin_ID',
        accessor: 'origin_ID',
        disableFilters: true,
      },
      {
        Header: 'Удалить',
        accessor: 'delete',
        disableFilters: true,
      }
    ]

    return cols;
  }, [userData]);


  const getBrandRowMemoised = useCallback(({
    row,
    hiddenColumnsLength,
    deletingRowsRef,
    justAlteredRowsRef,
    tableDataRef,
    selectedFlatRows,
    previouslySelectedRowRef,
  }) => {
    return (
      <BrandRowMemoised
        key={`row-entry-${row.original.origin_ID}__${row.original.updated_at && row.original.updated_at.replace(/\s/g, '_')}__${hiddenColumnsLength}__${row.isSelected}`}
        preparedRow={row}
        deletingRowsRef={deletingRowsRef}
        justAlteredRowsRef={justAlteredRowsRef}
        selectedFlatRows={selectedFlatRows}
        previouslySelectedRowRef={previouslySelectedRowRef}
        tableDataRef={tableDataRef}
      />
    );
  }, [])

  return (
    <Table
      location='/settings/vehicle-brands'
      tablePrefix="allBrands"
      initialArrayDataObject={initialArrayDataObject}
      initialShowOnlyActiveState={true}
      //@ts-expect-error ColumnWithFilter should be extended with selection properties
      columns={columns}
      initialHiddenColumns={{
        onlyActiveHidden: initialHiddenColumns,
        allHidden: initialHiddenColumns
      }}
      hatComponents={
        <div className="heading-container">
          <h2>
            Все марки транспорта
          </h2>
        </div>
      }
      toggle={
        {
          enabled: false,
        }
      }
      returnMemoizedTableRowComponent={getBrandRowMemoised}
      dataSettings={{
        shallowQuery: false,
        initialPageSize: 25,
        updateFrequency: 6000,
        cachedDataLifeSpan: 19000,
        onViewSwitchFilterCondition: "",
        offViewSwitchFilterCondition: "",
        sortString: "-updated_at",
        altSortString: "-updated_at",
        localFieldToCompareBy: "updated_at_full",
        remoteFieldToCompareBy: "updated_at",
        keepTableSettings: true,
        syncRecordsPerPage: true,
      }}
      noEntriesMessage={"Подходящая марка не найдена"}
      keepTableSettings={false}
      tableFooter={{
        allowedRezultsPerPageArray: [10, 25, 50],
        minimalControls: true,
      }}
      forceReloadRef={forceReloadRef}
    />
  );
}

export default VehicleBrands;