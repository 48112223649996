import { useState, useEffect } from "react";
// import { uploadFiles } from "@directus/sdk";
// import { getDirectusClient } from "../../../services/DirectusSDKClient";
import { uploadFilesToDirectus } from "../../../services/DirectusServices/directusHelpers";
import { Types } from "../../../models/DirectusSchema";

const fileFolders = {
  "journal": { "uuid": "5f98de2a-0cc8-4754-b29b-9f108bd3dba4" },
  "localVehiclesPhotos": { "uuid": "71fa28f6-5461-48c7-a641-2db1356d22ea" },
  "blacklist": { "uuid": "c7b18376-8d8d-424b-84c4-ccea43f51c3b" },
}

export type ProceedStatus = "idle" | "start" | "success" | "reportDelivered" | "failed";

interface FileInputProps {
  inputID: string;
  ButtonAdditionalClassName?: string;
  targetFolder: keyof typeof fileFolders;
  proceed: ProceedStatus;
  setProceed: React.Dispatch<React.SetStateAction<ProceedStatus>>
  setUploadedFilesUUIDs: React.Dispatch<React.SetStateAction<Types.UUID[] | null>>;
  handleInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sourceURL?: string;
}

export const FileInput = ({
  inputID,
  ButtonAdditionalClassName,
  targetFolder,
  proceed,
  setProceed,
  setUploadedFilesUUIDs,
  handleInput,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL,
}: FileInputProps) => {
  const [chosenFiles, setChosenFiles] = useState<FileList | undefined>(undefined);

  useEffect(() => {
    if (proceed === "start") {
      if (!chosenFiles || chosenFiles?.length === 0) {
        setUploadedFilesUUIDs(null);
        setProceed("success")
        return
      }

      try {
        uploadFilesToDirectus({
          files: chosenFiles,
          targetFolder,
          sourceURL,
        }).then(uploadedFiles => {
          const fileIds = uploadedFiles.map((f: { id: Types.UUID;[key: string]: any; }) => {
            return f.id
          });

          setUploadedFilesUUIDs(fileIds);
          setProceed("success");
        });
      } catch (err) {
        setProceed("failed")
        return;
      }

    }
  }, [chosenFiles, proceed, setProceed, targetFolder, sourceURL, setChosenFiles, setUploadedFilesUUIDs]);

  const innerHandleInput = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;

    setChosenFiles(files);

    if (handleInput) {
      handleInput(e);
    }
  }

  return (
    <>
      <label
        htmlFor={inputID}
        className={`button ${ButtonAdditionalClassName} ${proceed !== "idle" ? "disabled" : ""}`}
      >
        {
          !chosenFiles ?
            "Загрузить фото"
            :
            <>
              Заменить фото
              <ul className="uploaded-files-names">
                {
                  chosenFiles ? Array.from(chosenFiles).map((file, index) => (
                    <li key={`file-${index}`}>{file.name}</li>
                  )) : null
                }
              </ul>
            </>
        }
      </label>
      <input
        id={inputID}
        type="file"
        accept="image/*"
        multiple={true}
        className='file-upload'
        onChange={innerHandleInput}
        disabled={proceed !== "idle" ? true : false}
      />
    </>
  );
}
