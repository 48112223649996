import { readItem, updateItem } from "@directus/sdk";
import { Cell } from "react-table";
import { chooseEndpoint, StaleDataSourcesNames } from "../DataProviders/chooseEndpoint";
import { getDirectusClient } from "../DirectusSDKClient";



export const logAbortError = (err: Error, actionName = "") => {
  if (err.name === 'AbortError') {
    console.log(`%c${actionName} aborted`, "color:gray");
  } else {
    console.log(`%c${actionName} failed bacause of the following error:`, "color:gray");
    console.error(err)
  }
}

export interface UpdateValuesProps {
  targetEndpoint?: StaleDataSourcesNames;
  values?: Array<{
    cell?: Cell<any>;
    // cell?: {
    //   value?: string | number | boolean,
    //   column?: {
    //     id?: string,
    //   },
    //   row?: {
    //     values?: {
    //       origin_ID?: string,
    //     },
    //   },
    // },
    newValue?: string | number | boolean | Array<any>,
    addNewValue?: string | number | boolean | Array<any>,
    targetProperty?: string,
    rowID?: string | number,
  }>;
  sourceURL?: string;
}

export const updateValues = async ({
  targetEndpoint = "journal",
  values,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL,
}: UpdateValuesProps) => {
  const newData = {};
  const client = getDirectusClient(sourceURL);
  const targetTable = chooseEndpoint(targetEndpoint);
  const targetRowID = values[0].cell?.row?.values?.origin_ID || values[0].rowID;
  const targetColumns = [];

  const getTargetColumn = (value: UpdateValuesProps["values"][number]) => {
    const requestedColumn = value.targetProperty ? value.targetProperty : value.cell?.column?.id;

    if (requestedColumn === "brand") {
      return "brands"
    }

    if (requestedColumn === "common_brand") {
      return "brand"
    }

    return requestedColumn;
  }

  for (let i = 0; i < values.length; i++) {

    if (
      (values[i].cell?.value === undefined && (values[i].newValue === undefined && values[i].addNewValue === undefined))
      &&
      !((!!values[i].newValue || !!values[i].addNewValue) && !!values[i].rowID && !!values[i].targetProperty)
    ) {
      return new Error("updateValues function requires passing `cell` object with newValue, or explicitly set 'currentValue && newValue && rowID && targetProperty'")
    }

    if (
      (`${values[i].cell?.value}` !== `${values[i].newValue}`)
      ||
      (values[i].cell?.value === null && values[i].newValue !== "") // to avoid unnessesary updates on null values
    ) {


      const targetColumn = getTargetColumn(values[i]);
      targetColumns.push(targetColumn);


      if (values[i].newValue === undefined && values[i].addNewValue === undefined) {

        newData[`${targetColumn}`] = null;

      } else if (values[i].newValue !== undefined) {

        newData[`${targetColumn}`] = values[i].newValue;

      } else if (values[i].addNewValue !== undefined) {
        const response = await client.request(readItem(targetTable, targetRowID, {
          // @ts-expect-error can't find reasonable way to type targetColumn
          fields: ["id", targetColumn]
        })).catch((err) => {
          logAbortError(err, `updateValues call to targetEndpoint: ${targetEndpoint}, targetRowID: ${targetRowID}, targetColumn: ${targetColumn}`)
          return err;
        });


        if (response[`${targetColumn}`] === null) {

          newData[`${targetColumn}`] = JSON.stringify(values[i].addNewValue);

        } else {

          const existingData = JSON.parse(response[`${targetColumn}`]);
          newData[`${targetColumn}`] = JSON.stringify(existingData.concat(values[i].addNewValue));

        }
      }


    } else {
      console.log('updateValues on targetEndpoint is unnecessary', targetEndpoint)
    }

  }

  try {
    const responseOnUpdate = await client.request(updateItem(targetTable, targetRowID, newData))

    console.log(`value(s) updated at targetEndpoint: ${targetEndpoint}, targetRowID: ${targetRowID}, targetColumns: ${targetColumns.join(', ')}`)

    return responseOnUpdate;
  } catch (e) {
    if (e?.errors?.[0]?.message?.includes(`field "0" in collection "journal"`)) {
      console.log("Temporary fix for Directus bug")
      return {
        status: 200,
        statusText: "Успешно обновлено, не смотря на баг в Directus"
      };
    }

    logAbortError(e, `updateValues call to targetEndpoint: ${targetEndpoint}, targetRowID: ${targetRowID}, targetColumns: ${targetColumns.join(", ")}, newData=${JSON.stringify(newData)}`)

    window.alert(
      `Произошла ошибка при обновлении записи с ID=${targetRowID} в таблице ${targetEndpoint}.
      Попробуйте ещё раз или свяжитесь с администратором.

      Изменения, которые не удалость применить:
      ${JSON.stringify(newData)}`
    )

  }

}
