import { createDirectus, rest, authentication } from "@directus/sdk";
import { Schema } from "../models/DirectusSchema";

export function getDirectusClient(sourceURL: string) {
  const client = createDirectus<Schema>(sourceURL)
    .with(authentication("session", {
      credentials: "include",
      autoRefresh: true,
    }))
    .with(rest({
      credentials: 'include',
      onRequest: (options) => ({
        ...options,
        cache: 'no-store',
        'X-Requested-With': 'XMLHttpRequest',
      }),
    }));

  return client;
}
