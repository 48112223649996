
import { QueryFilter, readField, uploadFiles } from "@directus/sdk";
import { DateTime } from "luxon";
import { Collections, Schema, System, Types } from "../../models/DirectusSchema";
import { getDirectusClient } from "../DirectusSDKClient";

export const getDirectusDateFilter = (
  { dateField, dateString }:
    { dateString: string, dateField: string }
) => {
  // dateString could be any part of date string formatted like below:
  // yyyy-MM-dd HH:mm
  // e.g: yyyy, yyyy-MM, yyyy-MM-dd, MM-dd, yyyy-MM-dd HH:mm, HH:mm, dd HH, dd HH:mm ...
  const filter: Array<QueryFilter<Schema, Collections.ContactsOnObjects>> = [];

  if (/^\d{2}-\d{2}$/.test(dateString)) {
    // MM-dd format
    const [month, day] = dateString.split('-').map(Number);
    filter.push(
      { [`month(${dateField})`]: { _eq: month } }
    );
    filter.push(
      { [`day(${dateField})`]: { _eq: day } }
    );
    return filter;
  }

  if (/^\d{2}:\d{2}$/.test(dateString)) {
    // HH:mm format
    const [hour, minute] = dateString.split(':').map(Number);
    filter.push({
      [`hour(${dateField})`]: { _eq: hour > 3 ? hour - 3 : hour } // Quite agly solution to avoid timezone issues
    });
    filter.push({
      [`minute(${dateField})`]: { _eq: minute }
    });
    return filter;
  }

  const formats = [
    "yyyy",
    "yyyy-MM",
    "yyyy-MM-dd",
    "yyyy-MM-dd HH:mm",
    "dd HH",
    "dd HH:mm"
  ];

  for (const format of formats) {
    const parsed = DateTime.fromFormat(dateString.replace(/\s+/, " ").trim(), format);
    if (parsed.isValid) {
      const components = parsed.toObject();

      if (format === "yyyy") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
      } else if (format === "yyyy-MM") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
      } else if (format === "yyyy-MM-dd") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
      } else if (format === "yyyy-MM-dd HH:mm") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
        filter.push({ [`minute(${dateField})`]: { _eq: components.minute } });
      } else if (format === "MM-dd HH:mm") {
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
        filter.push({ [`minute(${dateField})`]: { _eq: components.minute } });
      } else if (format === "dd HH") {
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
      } else if (format === "dd HH:mm") {
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
        filter.push({ [`minute(${dateField})`]: { _eq: components.minute } });
      }

      return filter;
    }
  }

  return filter;
}


export function reduceDirectusFilesToStringArray(files: Array<{
  directus_files_id: Types.UUID,
  [key: string]: any
} | any>): string[] {
  return files.reduce<string[]>((acc, current) => {
    if (current?.directus_files_id) {
      acc.push(current.directus_files_id);
    }
    return acc;
  }, []);
}

export type SupportedTransformations = "thumbnail" | "fullhd";

export async function getDirectusSourceUrl() {
  const webURL = process.env.REACT_APP_DIRECTUS_URL.replace(/\/$/, "");
  const localURL = process.env.REACT_APP_DIRECTUS_LOCAL_URL.replace(/\/$/, "")

  try {
    throw new Error("This is a temporary blocker");

    // Set a timeout for the fetch request
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 2000); // 4 second timeout

    const response = await fetch(`${localURL}/server/health`, {
      signal: controller.signal,
    });

    clearTimeout(timeoutId);

    if (response?.status === 200) {
      console.log("%cSource URL set to local", "color: green");
      return localURL;
    }
  } catch (error) {
    // console.log("%cLocal server not available, using web URL", "color: orange");
    // Ignore the error and fall back to webURL
  }

  return webURL;
}

export function getDirectusImageSourceUrl(
  { uuid, key, sourceURL }: { uuid: string, key?: SupportedTransformations, sourceURL: string }
) {
  return `${sourceURL}/assets/${uuid}${key ? `?key=${key}` : ""}`;
}

export type OptionForSelect = {
  value: string,
  label: string,
}

export type CollectionKeys = Exclude<keyof Schema, keyof System>;
export type CollectionItem<T extends CollectionKeys> = Schema[T] extends Array<infer U> ? U : never;
export type ColumnKeys<T extends CollectionKeys> = keyof CollectionItem<T>;

export type provideSelectOptionsArgs<T extends CollectionKeys> = {
  source: T;
  column_id: ColumnKeys<T>;
  sourceURL: string;
};

export async function provideSelectOptions<T extends CollectionKeys>({ source, column_id, sourceURL }: provideSelectOptionsArgs<T>) {
  const tempOptions: OptionForSelect[] = [];
  const localStorageName = `${String(source)}_${String(column_id)}_options`;

  const knownOptions = JSON.parse(window.localStorage.getItem(localStorageName));
  const client = getDirectusClient(sourceURL);

  if (Date.now() - knownOptions?.dateOfInsertion < 1000 * 60 * 60) {
    return knownOptions.arrayOfOptions;
  } else {
    console.log(`options for ${column_id as string} column in ${source} source are about to be queried`)
  }

  const columnData = await client.request(readField(source, column_id as string));

  if (columnData.meta?.options?.choices?.length > 0) {

    columnData.meta.options.choices.forEach(opt => {
      tempOptions.push({ value: opt?.value, label: opt?.text })
    });

  }

  window.localStorage.setItem(localStorageName, JSON.stringify({
    arrayOfOptions: tempOptions,
    dateOfInsertion: Date.now()
  }))

  return tempOptions;
}

export function getDirectusFilterFromTableFilter<T>(
  { filters = [] }:
    { filters: Array<{ id: string, value: string | number | boolean }> }
): { "_and": Array<QueryFilter<Schema, T>> } {
  const directusFilter = { "_and": [] };
  // console.log("Called with table filters:", filters);
  
  const filter = filters.reduce((acc, current) => {
    if (current?.value === undefined || current?.value === "") {
      return acc;
    }

    if (current.id === "origin_ID") {
      acc["_and"].push({
        "id": {
          "_eq": current.value
        }
      });
      return acc;
    }

    if (current.id === "dest") {
      acc["_and"].push({
        "lands_n_objects": {
          "lands_n_objects_id": {
            "area_name": {
              "_icontains": current.value
            }
          }
        }
      });
      return acc;
    }

    if (current.id === "object") {
      acc["_and"].push({
        "lands_n_objects": {
          "area_name": {
            "_icontains": current.value
          }
        }
      });
      return acc;
    }

    if (current.id === "common_brand") {
      acc["_and"].push({
        "brand": {
          "_icontains": current.value
        }
      });
      return acc;
    }

    if (current.id === "brand") {
      acc["_and"].push({
        "brands": {
          "brand": {
            "_icontains": current.value
          }
        }
      });
      return acc;
    }

    if (["power_of_attorney_expiration", "updated_at", "created_at", "entered_at"].includes(current.id)) {
      const dateConditions = getDirectusDateFilter({
        dateField: current.id,
        dateString: current.value as string,
      })
      acc["_and"] = acc["_and"].concat(dateConditions);
      return acc;
    }

    if (["is_active", "entered", "daily", "credit", "archived"].includes(current.id)) {

      const bool = current.value === "true" || current.value === "TRUE" || current.value === 1 || current.value === true ? true : false;

      acc["_and"].push({
        [current.id]: {
          "_eq": bool
        }
      });
      return acc;
    }

    if (current.id === "created_by") {
      acc["_and"].push({
        "_or": [
          {
            "user_created": {
              "first_name": {
                "_icontains": current.value
              }
            }
          },
          {
            "user_created": {
              "last_name": {
                "_icontains": current.value
              }
            }
          },
          {
            user_created: {
              email: {
                "_icontains": current.value
              }
            }
          }
        ]
      });
      return acc;
    }

    acc["_and"].push({
      [current.id]: {
        "_icontains": current.value
      }
    });
    return acc;
  }, directusFilter) || directusFilter;
  
  // console.log("returning filter", filter);
  
  return filter;
}

export const fileFoldersUUIDsMap = {
  "journal": { "uuid": "5f98de2a-0cc8-4754-b29b-9f108bd3dba4" },
  "localVehiclesPhotos": { "uuid": "71fa28f6-5461-48c7-a641-2db1356d22ea" },
  "blacklist": { "uuid": "c7b18376-8d8d-424b-84c4-ccea43f51c3b" },
}

export interface FileInputProps {
  files: FileList,
  targetFolder: keyof typeof fileFoldersUUIDsMap,
  sourceURL?: string
}

export async function uploadFilesToDirectus({
  files,
  targetFolder,
  sourceURL = process.env.REACT_APP_DIRECTUS_URL
}: FileInputProps) {
  const client = getDirectusClient(sourceURL);
  const formData = new FormData();

  for (const file of files) {
    formData.append('folder', fileFoldersUUIDsMap[targetFolder].uuid);
    formData.append('file', file);
  }

  const result = await client.request(uploadFiles(formData, {
    fields: ["*"]
  }));
  // return type of a uploadFiles method depends on number of uploaded files — if only one, it returns a single object, if more, an array of objects
  if (Array.isArray(result)) {
    return result;
  } else {
    return [result];
  }
}

export type DirectusFilesToLink = Array<{ "directus_files_id": Types.UUID }>;
// export type DirectusFilesToLink = Array<{ "directus_files_id": Types.UUID[] }>;
export type DirectusFileToLink = Array<Types.UUID>;
