import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { removeDuplicatesAndEnrichDataToSend } from '../../services/DataProviders/journal';
import { getRidOfAliases } from '../../services/littleHelpers';
import ModalDialog from '../modals/ModalDialog';
import LoadingIcon from "../commonUI/LoadingIcon";

import { SimpleInTableCheckbox } from '../inputElements';
import { FormState, FormValues } from './AddToJournalForm';
import { JournalRowData } from '../../services/DataProviders/journal';
import { Destination } from './AddToJournalForm';
import { handleDirectusError, isDirectusError, ServiceErrorType } from '../../services/DirectusServices/directusErrors';


export interface SearchForDuplicatesProps {
  processedFormValues: FormValues;
  activeJournalDataRef: React.MutableRefObject<JournalRowData[]>;
}

export const searchForDuplicates = async ({
  processedFormValues,
  activeJournalDataRef,
}: SearchForDuplicatesProps): Promise<JournalRowData[]> => {
  const possibleDuplicates: JournalRowData[] = [];

  activeJournalDataRef?.current?.forEach((je) => {
    if (je.entered === true) return;
    for (const vehicle of processedFormValues.vehicles) {
      if (je.number.includes(vehicle.number) && je.brand.value === vehicle.brand.value) {
        vehicle.mergeCandidate = true;
        vehicle.mergeMarked = false;
        possibleDuplicates.push(je);
      }
    }
  })

  return possibleDuplicates;
}

interface JournalEntryMergingConfirmationProps {
  formState: FormState;
  chosenDestinations: Destination[];
  proceedFunction: (data: FormValues, isNested?: boolean) => Promise<any>;
  setFormState: Dispatch<SetStateAction<FormState>>;
  possibleDuplicates: JournalRowData[];
  dataToSend: FormValues;
  handleSuccessfulSubmission: () => void;
}

export interface ItemToMerge {
  origin_ID?: number;
  dest_ID?: number[];
}

type MergingStatus = 'idle' | 'ok' | 'fail';

export const JournalEntryMergingConfirmation = ({
  formState,
  chosenDestinations,
  proceedFunction,
  setFormState,
  possibleDuplicates,
  dataToSend,
  handleSuccessfulSubmission,
}: JournalEntryMergingConfirmationProps) => {
  const [mergingStatus, setMergingStatus] = useState<MergingStatus>('idle');
  const [errors, setErrors] = useState<ServiceErrorType[]>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [itemsToMerge, setItemsToMerge] = useState<ItemToMerge[]>([]);

  useEffect(() => {
    if (possibleDuplicates?.length > 0 && formState === "sendingData") {
      setIsModalOpen(true)
      setItemsToMerge([
        {
          origin_ID: possibleDuplicates[0].origin_ID,
          dest_ID: [possibleDuplicates[0].dest[0].Id]
        }
      ])
    }
    // console.log("dataToSend", dataToSend)
  }, [possibleDuplicates.length, formState]);

  const handleCheck = (
    v: React.ChangeEvent<HTMLInputElement>,
    duplicate: JournalRowData
  ) => {
    const origin_ID = duplicate.origin_ID;

    if (v.target.checked) {
      const targetDestIds = [];

      duplicate?.dest.forEach(d => {
        targetDestIds.push(d.Id)
      })

      setItemsToMerge(i => [{
        origin_ID: origin_ID,
        dest_ID: targetDestIds
      }, ...i])

      for (const v of dataToSend.vehicles) {

        if (v.number === duplicate.number && v?.brand?.value === duplicate?.brand?.value) {
          v.mergeMarked = true;
        }

      }

    } else {

      setItemsToMerge(i => i.filter(item => item.origin_ID !== origin_ID))

      for (const v of dataToSend.vehicles) {

        if (v.number === duplicate.number && v?.brand?.value === duplicate?.brand?.value) {
          v.mergeMarked = false;
        }

      }

    }
  }

  const onMergeClick = async () => {
    try {
      setIsLoading(true);
      const enrichedData = await removeDuplicatesAndEnrichDataToSend({
        itemsToMerge,
        dataToSend
      })

      await proceedFunction(enrichedData, true);
      setIsLoading(false);
      setMergingStatus('ok');

      window.setTimeout(() => {
        handleSuccessfulSubmission();
        setFormState("success")
        setIsModalOpen(false);
        setItemsToMerge([]);
        setMergingStatus('idle');
      }, 450)


    } catch (err) {
      console.error(err)

      if (isDirectusError(err)) {
        const errorsFormatted = handleDirectusError({
          error: err,
          shouldThrow: false
        })
        setErrors(errorsFormatted);
      } else {
        setErrors([{
          code: err?.code || '500',
          message: err?.message || 'An unexpected error occurred',
        }]);
      }

      setIsLoading(false);
      setMergingStatus('fail');
    }
  }

  const onProceedClick = async () => {
    setIsModalOpen(false)
    await proceedFunction(dataToSend)
  }

  const getExistingDestiantions = (possibleDuplicate: JournalRowData) => {
    let existingDestiantions = ""

    if (!possibleDuplicate?.dest) {
      return existingDestiantions;
    }

    for (const dest of possibleDuplicate.dest) {

      if (existingDestiantions === "") {
        existingDestiantions += "«" + getRidOfAliases(dest.area_name) + "»"
      } else {
        existingDestiantions += (", «" + getRidOfAliases(dest.area_name) + "»")
      }
    }

    return existingDestiantions;
  }

  const closeModal = () => {

    setMergingStatus('idle');
    setFormState("readyForInput");
    setIsModalOpen(false);
  }

  return (
    <ModalDialog isOpen={isModalOpen} exitFunction={closeModal}>
      <div id="duplicate-possible">
        {isLoading ? (
          <LoadingIcon addClassName={""} />
        ) : mergingStatus === 'idle' ? (
          <>
            <h2>Возможно, заявка на доставку</h2>
            {possibleDuplicates.map((d) => (
              <div className="boxProperty" key={`possible-duplicate-${d.origin_ID}`}>
                <p>
                  В журнале уже есть {getRidOfAliases(d.brand.label)} {d.number},
                  направляющаяся к {getExistingDestiantions(d)}.
                </p>
                {possibleDuplicates.length === 1 ? (
                  <p id="addToExistingEntry">
                    Добавить пункт назначения «{getRidOfAliases(chosenDestinations[0]?.label)}»
                    к существующей заявке?
                  </p>
                ) : (
                  <p>Отметить для объединения</p>
                )}
                <SimpleInTableCheckbox
                  changeHandler={(v: React.ChangeEvent<HTMLInputElement>) => handleCheck(v, d)}
                  isChecked={false}
                  passed_origin_ID={d.origin_ID}
                  helperText={'Выбрать к слиянию'}
                />
              </div>
            ))}
            <div className="boxProperty">
              <button
                disabled={itemsToMerge.length < 2}
                className="button"
                id="merge-with-existing"
                onClick={onMergeClick}
              >
                Объединить
              </button>
              <button
                disabled={itemsToMerge.length >= 2}
                className="button"
                id="just-send"
                onClick={onProceedClick}
              >
                Создать отдельную запись
              </button>
            </div>
            {dataToSend?.vehicles?.findIndex((v) => !v.mergeCandidate) !== -1 ? (
              <span id="entriesNotForMerging">
                Ещё будут доставлены заявки для:
                {dataToSend?.vehicles?.map((v) =>
                  !v.mergeCandidate ? (
                    <React.Fragment key={v.number}>
                      <br />
                      {getRidOfAliases(v.brand.label)} {v.number}{' '}
                    </React.Fragment>
                  ) : null
                )}
              </span>
            ) : null}
          </>
        ) : mergingStatus === 'ok' ? (
          <>
            <h2>👍</h2>
          </>
        ) : mergingStatus === 'fail' ? (
          <>
            <h2>Что-то пошло не так</h2>
            {
              errors.map(e =>
                <div
                  key={"error-" + e.code}
                  className="boxProperty"
                >
                  <p>
                    Код ошибки: <code>{e.code}</code>
                  </p>
                  <p>
                    Текст ошибки: <code>{e.message}</code>
                  </p>
                </div>
              )
            }
          </>
        ) : null}
      </div>
    </ModalDialog>
  );
}
