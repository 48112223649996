export type StaleDataSourcesNames = "journal" | "debt" | "reports" | "dezhJournal" | "blacklist" | "deposits" | "localVehicles" | "localPedestrians" | "allBrands" | "contacts";

export function chooseEndpoint(source: StaleDataSourcesNames) {
  if (source === "journal" || source === "debt" || source === "reports" || source === 'dezhJournal') {
    return "journal"
  } else if (source === "blacklist") {
    return "blacklist"
  } else if (source === "deposits") {
    return "deposits"
  } else if (source === "localVehicles") {
    return "local_vehicles"
  } else if (source === "localPedestrians") {
    return "pedestrians"
  } else if (source === "allBrands") {
    return "brands"
  } else if (source === "contacts") {
    return "contacts_on_objects"
  }
  return source;
  // throw new Error("Unexpected source provided to chooseEndpoint function: ", source);
}