import { useState, useEffect, useRef } from "react";
import Select, { createFilter } from "react-select";
import { ReceiptIcon } from "../../icons";
import { reasonableFilterConfig } from "../../commonUI/inputs/commonResources";
import { getRidOfAliases } from "../../../services/littleHelpers";

export const useAreaToFilterBy = ({ destinationOptions }) => {
    const [areaToFilterBy, setAreaToFilterBy] = useState(
        window.localStorage.getItem("lastPostpaidAreaReport") !== undefined
            ? JSON.parse(window.localStorage.getItem("lastPostpaidAreaReport"))
            : destinationOptions?.[0]
    );

    useEffect(() => {
        if (!areaToFilterBy && !!destinationOptions?.[0]) {
            setAreaToFilterBy(destinationOptions?.[0]);
        }

        let newAreaOfInterestString = JSON.stringify(areaToFilterBy);

        if (!newAreaOfInterestString) {
            return;
        }

        let lastAreaOfInterestString = window.localStorage.getItem(
            "lastPostpaidAreaReport"
        );

        if (lastAreaOfInterestString !== newAreaOfInterestString) {
            window.localStorage.setItem(
                "lastPostpaidAreaReport",
                JSON.stringify(areaToFilterBy)
            );
        }
    }, [areaToFilterBy, destinationOptions]);

    // console.log("returning",
    //     {
    //         areaToFilterBy,
    //         setAreaToFilterBy,
    //         onViewSwitchFilterCondition,
    //         offViewSwitchFilterCondition,
    //     }
    // )

    return [areaToFilterBy, setAreaToFilterBy];
};

const AreaOption = ({ innerProps, label, data }) => {
    return (
        <div className="">
            <div
                {...innerProps}
                className="area-option"
                title={
                    !!data.area_status
                        ? `Статус участка: ${data?.area_status?.replaceAll(
                              ",",
                              ", "
                          )}.`
                        : ""
                }
            >
                {data.credit_count > 0 && (
                    <ReceiptIcon
                        title={"Кредит"}
                        creditCount={data?.credit_count}
                    />
                )}
                {getRidOfAliases(label)}
            </div>
        </div>
    );
};

const SelectAreaForReport = ({
    isLoading,
    options,
    optionsRef,
    setAreaToFilterBy,
    value,
    label,
}) => {
    const thisSelectRef = useRef();

    // console.log(
    //     "renders with ",
    //     isLoading,
    //     options,
    //     optionsRef,
    //     setAreaToFilterBy,
    //     value,
    //     label
    // );
    const onChange = (e) => {
        setAreaToFilterBy(() => {
            return optionsRef.current.find((opt) => opt.value === e.value);
        });
    };

    return (
        <>
            <label
                htmlFor={"SelectAreaForReport"}
                id={"label-for-SelectAreaForReport"}
            >
                {label}
            </label>
            <Select
                ref={thisSelectRef}
                id={"SelectAreaForReport"}
                onChange={(e) => {
                    onChange(e);
                }}
                isLoading={isLoading}
                options={options}
                components={{
                    Option: AreaOption,
                }}
                placeholder=""
                isSearchable={true}
                filterOption={createFilter(reasonableFilterConfig)}
                classNamePrefix="select"
                value={value}
                captureMenuScroll={true}
                closeMenuOnScroll={true}
            />
        </>
    );
};

export default SelectAreaForReport;
