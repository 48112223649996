async function fetchWithTimeout(resource, options, specialController) {
  const { timeout = 30000 } = options;
  let controller, timeoutID;

  if (specialController) {
    controller = specialController;
    controller.signal.addEventListener('abort', () => {
      console.log("Fetching %caborted by user%c action", "color:Cyan", "color:initial");
      clearTimeout(timeoutID);
    });

  } else {
    controller = new AbortController();
  }

  timeoutID = setTimeout(() => {
    console.log(`%cfetch aborted on ${timeout}ms timeout!%c URL: ${resource}`, "color:FireBrick", "color:gray");
    controller?.abort();
  }, timeout);

  // if (true) {
  //   console.log({
  //     resource,
  //     params: {
  //       ...options,
  //       signal: controller.signal,
  //     }
  //   })
  // }
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal,
  });

  clearTimeout(timeoutID);

  return response;
}

const responseStreamReader = async (response) => {

  if (!response.body) {
    console.log("responseStreamReader got respone with no body:", response)
    return
  }

  const reader = response.body.getReader();
  let receivedLength = 0;
  let chunks = [];

  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      break;
    }

    chunks.push(value);
    receivedLength += value.length;

  }

  let chunksAll = new Uint8Array(receivedLength);
  let position = 0;

  for (let chunk of chunks) {
    chunksAll.set(chunk, position);
    position += chunk.length;
  }

  let result = new TextDecoder("utf-8").decode(chunksAll);

  return result;
};

const asyncForEach = async (array, callback) => {
  for (let i = 0; i < array.length; i++) {
    await callback(array[i], i, array);
  }
};

const throttle = (fn, delay) => {
  let lastCalled = 0;
  return (...args) => {
    let now = new Date().getTime();
    if (now - lastCalled < delay) {
      return;
    }
    lastCalled = now;
    return fn(...args);
  };
};

const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    // const context = this;
    const args = arguments;

    const later = function () {
      timeout = null;
      if (!immediate) func.apply(this, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(this, args);
  };
};

// const debounce = (func, wait) => {
//     let timeout
//     return (...params) => {
//       clearTimeout(timeout)
//       timeout = setTimeout(() => {
//         func(params)
//       }, wait)
//     }
//   }

const getRidOfAliases = (stringWithAliases) => {
  if (typeof(stringWithAliases) === "string") {
      let newString = stringWithAliases.replace(/\(.+\)/, "").replace(/\s+$/, "");
      return newString;
  } else {
      return "";
  }
}

const focusNextElementOnEvent = (event) => {
  if (event !== null) {
      const focusableElements = 'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), input[type=checkbox]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
      if (document.activeElement && document.activeElement.form) {
          const allPossiblyFocusable = document.activeElement.form.querySelectorAll(focusableElements);

          /*
           * To filter or map nodelists with JavaScript,
           * we can use the spread operator to convert 
           * the nodelist to an array.
           */

          const focusable = [...allPossiblyFocusable].filter(e => {
              return e.offsetWidth > 0 || e.offsetHeight > 0 || e === document.activeElement
          })
          const index = focusable.indexOf(document.activeElement);
          if (index > -1) {
              const nextElement = focusable[index + 1] || focusable[0];
              nextElement.focus();
          }
      }
  }
} // inspired by https://stackoverflow.com/questions/7208161/focus-next-element-in-tab-index

const focusMainSearch = (e, focusDelay = 350, targetColumnID = "number", pathname) => {

if (pathname === '/' || pathname === '/local-vehicles' || pathname === '/pedestrians' || pathname === '/contacts' ) {

    e?.preventDefault();
    let sElement = document.getElementById(`column-filter-id-${targetColumnID}`);
    let tableElement = document.querySelector('[id*="-table-entity"]');
    let navContainerElement = document.getElementById("main-navigation-container");
  
    if (sElement === null) {
      window.alert("Колонка для поиска по номеру или имени скрыта. Кнопка «Показать все колонки» показывается в правом верхнем углу таблицы когда хотя бы одно свойство скрыто.")
      window.scrollTo(tableElement.offsetWidth, tableElement.offsetTop + navContainerElement.clientHeight - 20)
  
      return;
    }
  
    window.scrollTo(0, tableElement.offsetTop + navContainerElement.clientHeight - 20);
    window.setTimeout(() => {
      sElement.focus();
      sElement.select();
    }, focusDelay)

  }

}

const applyTimezone = (timeStringAtUTC, hoursOffset) => {
  let originalTS = Date.parse(timeStringAtUTC);
  let resultTS = originalTS + hoursOffset * 60 * 60 * 1000;
  let resultDate = new Date(resultTS);
  let resultString = resultDate.toISOString().replace(/T/, " ").replace(/\.\d{3}Z$/,"");
  return resultString;
}




function getRemainingLifeSpan(dep) {
  let depositLifeSpan = dep?.life_span_in_days * 86400000;
  let msLeftToExpiration = dep.expiration_date_utc_ts - Date.now();
  let lifePercentRemaining = Math.floor(msLeftToExpiration / (depositLifeSpan / 100));

  return lifePercentRemaining;
}


export {
  debounce,
  throttle,
  asyncForEach,
  fetchWithTimeout,
  responseStreamReader,
  getRidOfAliases,
  focusNextElementOnEvent,
  focusMainSearch,
  applyTimezone,
  getRemainingLifeSpan,
};
