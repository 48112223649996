import React, { useState } from 'react';
import { useSearchParams } from "react-router-dom";

import SearchField from "./SearchField"
import { getRidOfAliases } from "../../services/littleHelpers"
import {
    LinkToBlacklistElement,
    LinkToJournalElement,
    LinkToLocalPedestriansElement,
    LinkToLocalVehiclesElement,
    LinkToContactsElement,
} from "../navigation/NavAnchors"

const initialResult = {
    data: [],
    count: 0,
}

const MainSearch = () => {
    const resultsPerSource = 5;
    const [blacklistRes, setBlacklistRes] = useState(initialResult)
    const [localVehiclesRes, setLocalVehiclesRes] = useState(initialResult)
    const [journalRes, setJournalRes] = useState(initialResult)
    const [localPedestriansRes, setLocalPedestriansRes] = useState(initialResult)
    const [contactsRes, setContactsRes] = useState(initialResult)
    const [searchParams, setSearchParams] = useSearchParams();

    // console.log("MainSearch renders with:",
    //  "blacklistRes -", blacklistRes,
    //  "localVehiclesRes -", localVehiclesRes,
    //  "journalRes -", journalRes,
    //  "localPedestriansRes -", localPedestriansRes)

    return (
        <>
            <div id="main-search-container">
                <SearchField 
                    setBlacklistRes={setBlacklistRes}
                    setLocalVehiclesRes={setLocalVehiclesRes}
                    setLocalPedestriansRes={setLocalPedestriansRes}
                    setContactsRes={setContactsRes}
                    setJournalRes={setJournalRes}
                    resultsPerSource={resultsPerSource}
                    initialResult={initialResult}
                    initialQuery={searchParams.get('q')}
                    setSearchParams={setSearchParams}
                />
                {
                    blacklistRes?.count > 0 &&
                    <div className='search-results-group blacklist'>
                        <ul>
                        {
                            blacklistRes?.data.map((v,index) => (
                                <li key={`blacklist-result-${index}`}>
                                    <div className='s-data-block'>
                                        <p className='s-number'>{v.number?.toUpperCase()}</p>
                                        <p className='s-brand'>{v.brand}</p>
                                        <p className='s-dest'>{v.relatedObject}</p>
                                        <p className='s-sanction'>{v.sanction}</p>
                                    </div>
                                    <div className='s-link-block' s-disabled='true' >
                                        <LinkToBlacklistElement 
                                            queryParameters={[{id: "origin_ID", value: v.origin_ID}]}
                                            className="s-link"
                                            passID={`s-link-blacklist-${index}`}
                                            title={"Подробнее о записи в Чёрном списке"}
                                        >
                                            
                                        </LinkToBlacklistElement>
                                    </div>
                                </li>
                            ))
                        }
                        </ul>
                        {
                            blacklistRes?.count > resultsPerSource &&
                            <span className='more-entries'>Ещё {blacklistRes.count - resultsPerSource > 999 ? "999+" : blacklistRes.count - resultsPerSource} в Чёрном списке</span>
                        }
                    </div>
                }
                {
                    localVehiclesRes?.count > 0 &&
                    <div className='search-results-group localVehicles'>
                        <ul>
                        {
                            localVehiclesRes?.data.map((v,index) => (
                                <li key={`localVehicles-result-${index}`}>
                                    <div className='s-data-block'>
                                        <p className='s-number'>
                                            {
                                                v?.mark &&
                                                <i className='vehicle-mark'>{v?.mark.slice(0,3)}</i>
                                            }
                                            {v.number.toUpperCase()}
                                        </p>
                                        <p className='s-brand'>{getRidOfAliases(v.brand.label)}</p>
                                        <p className='s-dest'>{getRidOfAliases(v.object?.area_name)}</p>
                                    </div>
                                    <div className='s-link-block' /*s-disabled='true'*/ >
                                        <LinkToLocalVehiclesElement 
                                            queryParameters={[{id: "origin_ID", value: v.origin_ID}]}
                                            className="s-link"
                                            passID={`s-link-vehicle-${index}`}
                                            title={"Подробнее о записи в Транспорте жителей"}
                                        >
                                            
                                        </LinkToLocalVehiclesElement>
                                    </div>
                                </li>
                            ))
                        }
                        </ul>
                        {
                            localVehiclesRes?.count > resultsPerSource &&
                            <span className='more-entries'>Ещё {localVehiclesRes.count - resultsPerSource > 999 ? "999+" : localVehiclesRes.count - resultsPerSource} среди ТС жителей</span>
                        }
                    </div>
                }
                {
                    journalRes?.count > 0 &&
                    <div className='search-results-group journal'>
                        <ul>
                        {
                            journalRes?.data.map((v,index) => (
                                <li key={`journal-result-${index}`}>
                                    <div className='s-data-block'>
                                        <p className='s-number'>{v?.number.toUpperCase()}</p>
                                        <p className='s-brand'>{getRidOfAliases(v?.brand?.label)}</p>
                                        <p className='s-dest'>{getRidOfAliases(v?.dest?.[0]?.area_name)}</p>
                                    </div>
                                    {
                                        v.entered_at &&
                                        <div className='s-data-block'>
                                            <p className='s-time-label'>время прибытия:</p>
                                            <p className='s-time'>{v.entered_at}</p>
                                        </div>
                                    }
                                    <div className='s-link-block'>
                                        <LinkToJournalElement 
                                            queryParameters={[{id: "origin_ID", value: v.origin_ID}]}
                                            className="s-link"
                                            passID={`s-link-journal-${index}`}
                                            title={"Подробнее о записи в Журнале"}
                                        >
                                            
                                        </LinkToJournalElement>
                                    </div>
                                </li>
                            ))
                        }
                        </ul>
                        {
                            journalRes?.count > resultsPerSource &&
                            <span className='more-entries'>Ещё {journalRes.count - resultsPerSource > 999 ? "999+" : journalRes.count - resultsPerSource} в Журнале</span>
                        }
                    </div>
                }
                {
                    localPedestriansRes?.count > 0 &&
                    <div className='search-results-group localPedestrians'>
                        <ul>
                        {
                            localPedestriansRes.data.map((v,index) => (
                                <li key={`localPedestrians-result-${index}`}>
                                    <div className='s-data-block'>
                                        <p className='s-name'>{v.name}</p>
                                        <p className='s-dest'>{getRidOfAliases(v.dest?.[0].area_name)}</p>
                                    </div>
                                    <div className='s-link-block' /*s-disabled='true'*/ >
                                        <LinkToLocalPedestriansElement
                                            queryParameters={[{id: "origin_ID", value: v.origin_ID}]}
                                            className="s-link"
                                            passID={`s-link-ped-${index}`}
                                            title={"Подробнее о записи в Пешеходах"}
                                        >
                                            
                                        </LinkToLocalPedestriansElement>
                                    </div>
                                </li>
                            ))
                        }
                        </ul>
                        {
                            localPedestriansRes?.count > resultsPerSource &&
                            <span className='more-entries'>Ещё {localPedestriansRes.count - resultsPerSource > 999 ? "999+" : localPedestriansRes.count - resultsPerSource} среди Пешеходов</span>
                        }
                    </div>
                }
                {
                    contactsRes?.count > 0 &&
                    <div className='search-results-group contacts'>
                        <ul>
                        {
                            contactsRes.data.map((v,index) => (
                                <li key={`contacts-result-${index}`}>
                                    <div className='s-data-block'>
                                        <p className='s-name'>{v.name}</p>
                                        <p className='s-dest'>{getRidOfAliases(v.object.area_name)}</p>
                                    </div>
                                    <div className='s-data-block'>
                                        <p className='s-position'>{v?.position}</p>
                                        <p className='s-powersStatus'>{v?.powersStatus}</p>
                                    </div>
                                    <div className='s-link-block' /*s-disabled='true'*/ >
                                        <LinkToContactsElement
                                            queryParameters={[{id: "origin_ID", value: v.origin_ID}]}
                                            className="s-link"
                                            passID={`s-link-contact-${index}`}
                                            title={"Подробнее о записи в Контактах"}
                                        />
                                    </div>
                                </li>
                            ))
                        }
                        </ul>
                        {
                            contactsRes?.count > resultsPerSource &&
                            <span className='more-entries'>Ещё {contactsRes.count - resultsPerSource > 999 ? "999+" : contactsRes.count - resultsPerSource} среди контактных лиц</span>
                        }
                    </div>
                }
            </div>
        </>
    );
}

export default MainSearch;