import { useState, useRef } from 'react';

import LoadingIcon from '../commonUI/LoadingIcon';
import { getRidOfAliases } from "../../services/littleHelpers"
import { notifyTelegramGroup } from "../../services/LandshaftRobot/notifications"
import { ArchiveBlacklistItemProps, BlacklistItemData } from '../../services/DataProviders/blacklist';

interface RemoveFromBlacklistDialogProps {
  car: BlacklistItemData;
  confirmFunction: (props: ArchiveBlacklistItemProps) => Promise<any>;
  setConfirmationRequiered: (value: boolean) => void;
}

const RemoveFromBlacklistDialog = ({ car, confirmFunction, setConfirmationRequiered }: RemoveFromBlacklistDialogProps) => {
  const [dialogStatus, setDialogStatus] = useState('idle')
  const [comment, setComment] = useState(car.comment); 
  
  const handleClickOnConfirm = async () => {
    setDialogStatus('loading')
    await confirmFunction({
      itemID: car.origin_ID,
      newComment: comment,
    })

    const notificationData = {
      brand_name: getRidOfAliases(car.brand.label),
      plate_number: car.number,
      comment: comment,
    }

    try {
      const res = await notifyTelegramGroup({
        target: "security-chat",
        event: "blacklist-released",
        data: notificationData
      });
      if (!res.ok) {
        throw new Error("Уведомление в чат не доставлено");
      }
    } catch (err) {
      window.alert("Машина убрана из ЧС, но уведомление в чат не доставлено!")
    }
  }


  return (
    <div className='blacklist-confirmation-container'>
      {
        dialogStatus === "idle" ?
          <>
            <h1>Требуется подтверждение</h1>
            <div className='boxProperty'>
              <p>Убрать из чёрного списка {getRidOfAliases(car.brand.label)} {car.number}?</p>
            </div>
            <div className='boxProperty'>
              <label htmlFor={`verify-comment`}>Ваш комментарий</label>
              <textarea
                id={`verify-comment`}
                className='classyInput'
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                spellCheck={true}
              />
            </div>
            <div className='boxProperty'>
              <button className='button'
                onClick={handleClickOnConfirm}
              >
                Да
              </button>
              <button className='button'
                onClick={() => setConfirmationRequiered(false)}
              >
                Отмена
              </button>
            </div>
          </>
          :
          <>
            <LoadingIcon addClassName={''} />
          </>
      }
    </div>
  );
}

export default RemoveFromBlacklistDialog;
