import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// import UserService from '../../services/DirectusUserService';
import { MainContext } from '../MainContext';
import { useForm, Controller } from 'react-hook-form';
import LoadingIcon from '../commonUI/LoadingIcon';
import { ErrorMessage } from '@hookform/error-message';

interface FormInputs {
  login: string
  password: string
  otp?: number
}

type FormState = 'readyForInput' | 'sendingData' | 'success' | 'errorOnSubmit'

const allowedPasswordlessLogins = ["kpp1@landshaft.life", "kpp2@landshaft.life", "kpp3@landshaft.life", "kpp4@landshaft.life", "patrol1@landshaft.life", "patrol2@landshaft.life"]

const LoginPage: React.FC = () => {
  const [formState, setFormState] = useState<FormState>('readyForInput');
  const [isOTPEnabled, setIsOTPEnabled] = useState(false);
  const [isPasswordless, setIsPasswordless] = useState(null);
  const errorData = useRef({} as any);
  const { setIsAuthenticated, isAuthenticated, changeUserData } = useContext(MainContext);
  const navigate = useNavigate();
  const location = useLocation();

  React.useEffect(() => {
    if (isAuthenticated) {
      navigate("/settings", { replace: true });
    }
  }, [isAuthenticated, navigate]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setFocus,
    formState: { errors },
  } = useForm({
    criteriaMode: "all",
  })

  React.useEffect(() => {
    const login = watch("login");
    if (allowedPasswordlessLogins.includes(login.toLowerCase())) {
      setIsPasswordless(true);
      setIsOTPEnabled(true);
    } else {
      setIsPasswordless(false);
      setIsOTPEnabled(false);
    }
  }, [watch("login")])

  const handleLogin = async (data: FormInputs) => {
    window.scrollTo(0, 0);
    const UserService = await import('../../services/DirectusUserService').then(module => module.default);
    const loginResult = await UserService.doLogin(data.login, data.password, data?.otp?.toString());
    console.log(loginResult);
    setIsAuthenticated(true);
    const userData = await UserService.getCurrentUser();
    changeUserData(userData);
    const from = location.state?.from?.pathname || '/';
    navigate(from, { replace: true });
  };

  const onError = () => {
    window.scrollTo(0, 0)
  }

  const onSubmit = async (data: FormInputs) => {
    try {
      setFormState("sendingData");
      if (isPasswordless) {
        await handleLogin({
          login: data.login,
          password: "Terr1tory",
          otp: data.otp
        })
      } else {
        await handleLogin(data);
      }
    } catch (e) {
      console.error(e);
      if (e?.response?.status === 401) {

        if (!isOTPEnabled && e?.errors?.[0]?.extensions?.code === "INVALID_OTP") {
          setIsOTPEnabled(true);
          setFormState("readyForInput");
          window.setTimeout(() => {
            setFocus("otp");
          }, 100);
          return;
        }
      }
      errorData.current = e;
      setFormState("errorOnSubmit");
    }

  }

  return (
    <div className='page-container' >
      <LoadingIcon addClassName={`${formState === "sendingData" ? "" : "invisible"}`} />
      {
        formState === "readyForInput" &&
        <form
          id="login-form"
          className={`${formState === "readyForInput" ? "visible" : ""}`}
          onSubmit={handleSubmit(onSubmit, onError)}
        >
          <div className="boxProperty">
            <div className="propertyContainer">
              <label htmlFor={"login"}>Электронная почта</label>
              <Controller
                control={control}
                name={"login"}
                defaultValue=""
                rules={{
                  required: "Пожалуйста, введите ваш email",
                  pattern: {
                    value: /.+@.+\..+/,
                    message: "Пожалуйста, введите правильный email",
                  }
                }}

                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <input
                    type="text"
                    name="login-to-service.landshaft.life"
                    ref={ref}
                    className="classyInput"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
              <ErrorMessage
                errors={errors}
                name={"login"}
                render={({ messages }) =>
                  messages &&
                  Object.entries(messages).map(([type, message]) => (
                    <span key={type} className="errorMessage">
                      {message}
                    </span>
                  ))
                }
              />
            </div>
          </div>
          {
            isPasswordless !== true &&
            <div className="boxProperty">
              <div className="propertyContainer">
                <label htmlFor={`password`}>Пароль</label>
                <Controller
                  control={control}
                  name={`password`}
                  defaultValue=""
                  rules={{ required: "Пожалуйста, введите ваш пароль" }}
                  render={({ field: { onChange, onBlur, value, ref } }) => {
                    return (
                      <input
                        type="password"
                        ref={ref}
                        className="classyInput"
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                      />
                    )
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name={`password`}
                  render={({ message }) => <span className="errorMessage">{message}</span>}
                />
              </div>
            </div>
          }
          {
            isOTPEnabled &&
            <div className="boxProperty">
              <div className="propertyContainer">
                <label htmlFor={`otp`}>Одноразовый пароль</label>
                <Controller
                  control={control}
                  name={`otp`}
                  defaultValue={""}
                  rules={{ 
                    required: "Пожалуйста, введите  временный код",
                    pattern: {
                      value: /\d+/,
                      message: "Код должен состоять только из цифр",
                    }
                  }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <input
                      type="text"
                      name="OTP-to-service.landshaft.life"
                      ref={ref}
                      className="classyInput"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      autoComplete="off"
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name={`otp`}
                  render={({ message }) => <span className="errorMessage">{message}</span>}
                />
              </div>
            </div>
          }
          <div id="submit-form-container" className={`${formState === "readyForInput" ? "" : "invisible"}`}>
            <button
              className="button submit"
              id="main-submit"
              type="submit"
            >
              Вход
            </button>
          </div>
        </form>
      }
      <div id="error-on-submit-container" className={`${!!errorData.current && formState === "errorOnSubmit" ? "visible" : ""}`}>
        <h2>Произошла ошибка</h2>
        <div className="appeal">
          <p>Пожалуйста, попробуйте ещё раз.</p>
          <>
            <p>Код ошибки: <code>{errorData.current?.response?.status}</code></p>
            {errorData.current?.errors?.map((e: any, index: number) =>
              <p key={index}>{e.extensions?.code || "Ошибка"}: <code>{e.message}</code></p>)
            }
          </>
          <button className="button retry" onClick={() => {
            reset();
            setFormState("readyForInput")
          }}>
            Попробовать ещё раз
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
