import { Types } from "../../models/DirectusSchema";
import { fetchWithTimeout } from "../littleHelpers";

export interface BaseNotification {
  event: "parking-start" | "blacklist-released" | "blacklist-added";
  target: "security-chat" | "dev";
  data: {
    [key: string]: any;
  };
}

export interface ParkingStartNotification extends BaseNotification {
  event: "parking-start";
  data: {
    plate_number: string;
    area_name: string;
    brand_name: string;
    file: Types.UUID;
  };
}

export interface BlacklistReleasedNotification extends BaseNotification {
  event: "blacklist-released";
  data: {
    plate_number: string;
    brand_name: string;
    comment: string;
  };
}

export interface BlacklistAddedNotification extends BaseNotification {
  event: "blacklist-added";
  data: {
    plate_number: string;
    brand_name: string;
    comment?: string;
    area_name?: string;
    sanction: string;
    files?: Array<Record<"directus_files_id", string>>;
  };
}

export type NotifySecurityChatProps =
  | ParkingStartNotification
  | BlacklistReleasedNotification
  | BlacklistAddedNotification;

export type Prettify<T> = {
  [K in keyof T]: T[K];
};

export const notifyTelegramGroup = async (
  { event, target, data }: NotifySecurityChatProps,
) => {
  try {
    const hostname = process.env.REACT_APP_IGOR_URL;
    if (!hostname) {
      throw new Error("Missing REACT_APP_IGOR_URL env variable");
    }
    console.log("notifyTelegramGroup data", data);
    const response = await fetchWithTimeout(
      `${hostname}/api/v1/notify/${target}/${event}`,
      {
        timeout: 100000,
        method: "POST",
        body: JSON.stringify({ data }),
      },
    );
    console.log("notifyTelegramGroup response", response);
    return response;
  } catch (err) {
    console.error("notifySecurityChat error", err);
    throw err;
  }
};

// export const notifyAboutDangerousAction = async (report) => {

//   try {

//     const response = await fetchWithTimeout(
//       `https://system.landshaft.life/webhook/notify-admin`,
//       {
//         timeout: 17000,
//         method: "POST",
//         body: JSON.stringify(report),
//       }

//     );

//     return response;

//   } catch (err) {

//     return { status: 500, message: "Failed to notify admin, problem on n8n service" }

//   }

// }
