// import UserService from "./UserService";
const UserService = await import('./DirectusUserService').then(module => module.default);

const RenderOnAuthenticated = ({ children }) => {
    if (UserService.isLoggedIn() === true ) {
        return children 
    } 

    return null;
}

export default RenderOnAuthenticated;