import React, { useCallback, useMemo } from 'react';
import { useSearchParams } from "react-router-dom";

import Table from '../../commonUI/Table';
import DepositRowMemoised from "./DepositRow";


const initialArrayDataObject = [];
const initialHiddenColumns = {
    onlyActiveHidden: ['origin_ID', "created_at"],
    allHidden: ['origin_ID', "created_at"],
};

const findOutInitialTableView = (idQuery) => {
    if (idQuery) {
        return false;
    } else {
        return true;
    }
}

const onViewSwitchFilterCondition = {
  "status": {
    "_eq": "Действителен"
  }
}

const AllDeposits = () => {
    let [searchParams] = useSearchParams();
    const idQuery = searchParams.get('origin_ID');


    const columns = useMemo(() => 
      [
        {
            Header: 'Объект',
            accessor: 'object',
        },
        {
            Header: 'Категория',
            accessor: 'category',
        },
        {
            Header: 'Комментарий',
            accessor: 'comment',
        },
        {
            Header: 'Въехавший транспорт',
            accessor: 'entered_vehicles',
            disableFilters: true,
        },
        {
            Header: 'Статус',
            accessor: 'status',
        },
        {
            Header: 'Оплаченный лимит',
            accessor: 'limit',
            disableFilters: true,
        },
        {
            Header: 'Истекает',
            accessor: 'expiration_date',
            disableFilters: true,
        },
        {
            Header: 'Продлить',
            accessor: 'prolong',
            disableFilters: true,
        },
        {
            Header: 'Создание',
            accessor: 'created_at',
            // disableFilters: true,
        },
        {
            Header: 'origin_ID',
            accessor: 'origin_ID',
            // disableFilters: true,
        },
        {
            Header: 'Удалить',
            accessor: 'delete',
            disableFilters: true,
        },
    ], []);

    const getDepositRowMemoised = useCallback(({
        row,
        tableDataRef,
        hiddenColumnsLength,
        deletingRowsRef,
        justAlteredRowsRef,
        setEditedEntryID,
    }) => {

        return (
            <DepositRowMemoised
                key={`row-entry-${row.original?.origin_ID}__${row.original?.updated_at && row.original?.updated_at?.replace(/\s/g, '_')}__${hiddenColumnsLength}`} 
                preparedRow={row}
                tableDataRef={tableDataRef}
                deletingRowsRef={deletingRowsRef}
                justAlteredRowsRef={justAlteredRowsRef}
                setEditedEntryID={setEditedEntryID}
            />
        );
        
    }, [])

    return (
        <Table
            tablePrefix="deposits"
            location='/accounting/deposits'
            initialArrayDataObject={initialArrayDataObject}
            initialShowOnlyActiveState={findOutInitialTableView(idQuery)}
            columns={columns}
            initialHiddenColumns={initialHiddenColumns}
            toggle={
                {
                    enabled: true,
                    showOnlyActiveLabel: "Действительные депозиты:",
                    showAllLabel: "Все депозиты:",
                    onlyHat: true,
                }
            } 
            returnMemoizedTableRowComponent={getDepositRowMemoised}
            dataSettings={{
                shallowQuery: false,
                initialPageSize: 10,
                altInitialPageSize: 10,
                updateFrequency: 15000,
                cachedDataLifeSpan: 180000,
                onViewSwitchFilterCondition,
                sortString: "-updated_at",
                offViewSwitchFilterCondition: undefined,
                initialShowOnlyActiveState: false,
                altSortString: "-updated_at",
                localFieldToCompareBy: "updated_at_full",
                remoteFieldToCompareBy: "updated_at",
                keepTableSettings: true,
                syncRecordsPerPage: true,
            }}
            idQuery={idQuery}
            noEntriesMessage={"Подходящие депозиты не найдены"}
            keepTableSettings={false}
        />
    );

}

export default AllDeposits;