import { provideBrands } from "./DataProviders/brands";
import { provideAreas } from "./DataProviders/provideAreas"
import { useState, useEffect, useRef, useCallback, useContext } from "react";
import { MainContext, SiteSettings } from "../components/MainContext";
import { QueryFilter } from "@directus/sdk";
import { Collections, Schema } from "../models/DirectusSchema";
import { DebtorsMap } from "../components/accounting/PostpaidReports/PostpaidReports";
import { matchSorter } from "match-sorter";

export interface SelectOption<T> {
  value: T;
  label: string;
  credit_count?: number;
  // area_status?: string;
}

export interface CreatableSelectOption<T> extends SelectOption<T> {
  __isNew__?: boolean;
}

interface OptionNameToValueType {
  destinationOptions: number;
  brandsOptions: number;
}

interface UseSelectOptionsProps<K extends keyof OptionNameToValueType> {
  optionName: K;
  siteSettings: SiteSettings;
  currentViewFilterCondition?: QueryFilter<Schema, Collections.LandsNObjects>;
  valueToTriggerRecall?: any;
  enrichOptionsWithDeptorsData?: DebtorsMap;
}

function useSelectOptions<V extends keyof OptionNameToValueType>({
  optionName,
  siteSettings,
  currentViewFilterCondition = undefined,
  valueToTriggerRecall = undefined,
  enrichOptionsWithDeptorsData = undefined
}: UseSelectOptionsProps<V>) {
  const { sourceURL } = useContext(MainContext);
  const [options, setOptions] = useState<SelectOption<OptionNameToValueType[V]>[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const allOptionsRef = useRef(options);
  const abortControllerRef = useRef<AbortController | null>(null);
  const inStorageName = `${optionName}=${currentViewFilterCondition ? JSON.stringify(currentViewFilterCondition) : "all"}`
  
  const fetchAndProcessOptions = useCallback(async (controller: AbortController, enrichOptions: DebtorsMap) => {
    setIsLoading(true)
    let tempOptions: SelectOption<OptionNameToValueType[V]>[] | undefined;

    if (optionName === "destinationOptions") {
      tempOptions = await provideAreas({
        currentViewFilterCondition,
        controller: controller,
        sourceURL
      })
    } if (optionName === "brandsOptions") {
      tempOptions = await provideBrands({
        shallowQuery: true,
        controller: controller,
        sourceURL
      })
    }

    if (!!enrichOptions && tempOptions !== undefined) {

      Object.keys(enrichOptions).forEach(debtor => {

        const d = tempOptions?.find(option => {
          return option.value.toString() === debtor
        });

        d.credit_count = enrichOptions[`${debtor}`];
      });

    }
    if (tempOptions) {
      setOptions(tempOptions);
      allOptionsRef.current = tempOptions;
      window.localStorage.setItem(
        inStorageName,
        JSON.stringify({
          arrayOfOptions: tempOptions,
          dateOfInsertion: new Date(),
        })
      );
    }
    setIsLoading(false)
    // currentViewFilterCondition removed from dependency array to avoid rerenders, as it is an object recreated on every render
    // At the same time, currentViewFilterCondition, as a string included in inStorageName so there is no need to include 
  }, [inStorageName, optionName])


  useEffect(() => {
    abortControllerRef.current = new AbortController();

    const fetchOptions = async () => {
      const storedData = window.localStorage.getItem(inStorageName);
      
      if (!storedData) {
        await fetchAndProcessOptions(abortControllerRef.current, enrichOptionsWithDeptorsData);
      } else {
        const tempData = JSON.parse(storedData);

        if (
          Date.now() - Date.parse(tempData.dateOfInsertion) < siteSettings.refreshFrequency &&
          Array.isArray(tempData.arrayOfOptions)
        ) {
          setOptions(tempData.arrayOfOptions);
          allOptionsRef.current = tempData.arrayOfOptions;
        } else {
          await fetchAndProcessOptions(abortControllerRef.current, enrichOptionsWithDeptorsData);
        }
      }
    };

    fetchOptions();
    
    return () => {
      setIsLoading(false);
      abortControllerRef.current.abort();
    }
  }, [enrichOptionsWithDeptorsData, valueToTriggerRecall, fetchAndProcessOptions, inStorageName, siteSettings.refreshFrequency]);
  
  return [
    isLoading,
    allOptionsRef,
    options,
    setOptions,
  ] as const;
}

interface OnSelectInputChangeProps {
  value: string;
  currentState: any;
  setCurrentState: React.Dispatch<React.SetStateAction<any>>;
  allOptionsRef: React.MutableRefObject<any>;
  action: "input-change" | "menu-close";
}

export const onSelectInputChange = ({
  value, currentState, setCurrentState, allOptionsRef, action
}: OnSelectInputChangeProps) => {
    if (action === 'input-change') {
        const filteredOptions = 
          matchSorter(
            currentState,
            value, 
            { keys: ['label'] } 
          );
        
        setCurrentState(filteredOptions);

        if (value === "" || value?.length === 0) {
            setCurrentState(allOptionsRef.current);
        }

    } else if (action === 'menu-close') {
        setCurrentState(allOptionsRef.current);   
    }
}
export default useSelectOptions;
