import { Cell } from "react-table";
import { BlacklistItemData } from "../../services/DataProviders/blacklist";
import { JournalRowData } from "../../services/DataProviders/journal";
import { getRidOfAliases } from "../../services/littleHelpers";

interface BlacklistReminderProps {
  cell: Cell<JournalRowData>;
  blacklistDataRef: React.MutableRefObject<BlacklistItemData[]>;
}

export function BlacklistReminder({ cell, blacklistDataRef }: BlacklistReminderProps) {
  const number = cell.value;
  
  if (!blacklistDataRef?.current || blacklistDataRef.current?.length === 0 || !number) {
    return null;
  }
  
  const blacklistMatch = blacklistDataRef.current.filter(bl => {
    return bl.number.replace(/\s+/g, "").toLowerCase() === (number || "").replace(/\s+/g, "").toLowerCase();
  });
  
  if (blacklistMatch.length > 0) {
    let title = "Совпадение номера в черном списке:\n";
    blacklistMatch.forEach(bl => { 
      title += `${getRidOfAliases(bl?.brand?.label || "")} ${bl.number} — ${bl.comment}\n`
    })
    
    return (
      <span 
        className="blacklist-in-journal-icon"
        title={ title }
      >
        🚨
      </span>
    );
  }
  
  const suspiciousMatches = blacklistDataRef.current.filter(bl => {
    const blBrandId = bl.brand?.value;
    const guestsBrandId = cell.row.values.brand?.value;
    
    if (!blBrandId || !guestsBrandId) {
      return false;
    }
    
    if (blBrandId === guestsBrandId) {
      const blNumber = (bl?.number || "").replace(/\s+/g, "").toLowerCase();
      const containsNotAtTheEnd = new RegExp(`${number?.replace(/\s+/g, "")}.+`);
      return containsNotAtTheEnd.test(blNumber);
    }
    
    return false;
  });
  
  if (suspiciousMatches.length > 0) {
    let title = "Номер и марка совпадают с черным списком:\n";
    suspiciousMatches.forEach(bl => { 
      title += `${getRidOfAliases(bl?.brand?.label || "")} ${bl.number} — ${bl.comment}\n`
    })
    
    return (
      <span 
        className="blacklist-in-journal-icon"
        title={ title }
      >
        ❗
      </span>
    );
  }
  
  const numberMatches = blacklistDataRef.current.filter(bl => {
    const blNumber = (bl?.number || "").replace(/\s+/g, "").toLowerCase();
    const containsNotAtTheEnd = new RegExp(`${number?.replace(/\s+/g, "")}.+`);
    return containsNotAtTheEnd.test(blNumber);
  });
  
  if (numberMatches.length > 0) {
    let title = "Номер похож на черный список:\n";
    numberMatches.forEach(bl => { 
      title += `${getRidOfAliases(bl?.brand?.label || "")} ${bl.number} — ${bl.comment}\n`
    })
    
    return (
      <span 
        className="blacklist-in-journal-icon"
        title={ title }
      >
        ❓
      </span>
    );
  }
  
  return null;
}
